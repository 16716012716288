import { DataService } from './../../../../../services/data.service';
import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { Router } from '@angular/router';

moment.updateLocale('es', {
  relativeTime: {
    future: 'en %s',
    past: 'hace %s',
    s: '1s',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1 mes',
    MM: '%d meses',
    y: '1 año',
    yy: '%d años',
  },
});

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent implements OnInit {
  @Input() notification;
  @Input() updateNotification;
  public moment = moment;

  constructor(private dataService: DataService, private router: Router) {}

  ngOnInit(): void {
    console.log();
  }

  markAsViewed() {
    if (
      this.notification.is_open &&
      this.notification.launch_url &&
      this.notification.launch_url !== ''
    ) {
      window.open(this.notification.launch_url, '_self');
    } else {
      this.dataService.setViewNotification(this.notification.id).subscribe({
        next: (res) => {
          this.notification.is_open = true;
        },
        complete: () => {
          if (
            this.notification.launch_url &&
            this.notification.launch_url !== ''
          ) {
            window.open(this.notification.launch_url, '_self');
          }
        },
      });
    }
  }
}
