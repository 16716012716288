export interface Ilogin {
  cookie: string;
  sis_id: string;
  token: string;
}
export interface IDirectorio {
  mostrar_datos_contacto: boolean;
  mostrar_datos_personales: boolean;
  usar_directorio: boolean;
}

export interface IEspecialidad{
  estudios_docencia_superior: string;
  area_especialidad: string;
}

export interface IDocente {
  contacto_emergencia: {
    movil: string;
    nombre: string;
    parentesco: string;
  };
  correo_academico: string;
  correo_personal: string;
  direccion: string;
  fecha_nacimiento: string;
  foto: string;
  foto64: string;
  genero: string;
  grupo_sanguineo: string;
  idioma: string;
  linkedin: string;
  movil: string;
  nacionalidad: string;
  nombre: string;
  nro_documento: string;
  tipo_direccion: string;
  tipo_documento: string;
  sis_id: string;
}

export interface IAlumno {
  apellido: string;
  cargo: string;
  carreras: CarreraPrincipal[];
  celular: string;
  direccion: string;
  directorio: IDirectorio;
  email: string;
  email_personal: string;
  escuela: string;
  escuela_graduacion: string;
  escuela_pais: string;
  facebook: string;
  fecha_nacimiento: string;
  foto: string;
  grupo_sanguineo: string;
  instagram: string;
  legajo: string;
  linkedin: string;
  movil: string;
  nombre: string;
  numero_documento: string;
  pais: string;
  profesion: string;
  sede: string;
  skype: string;
  telefono_laboral: string;
  telefono_particular: string;
  telefono_principal: string;
  tipo_documento: string;
  vencimiento_documento: string;
  token?: string;
}

// CONCURSO

export interface ImpulsoMember {
  id: number;
  name: string;
  mail: string;
  phone: string;
  study_level: string;
}

export interface ContestMember {
  id: number;
  name: string;
  mail: string;
  img: string;
}

export interface ContestTeam {
  _id?: string;
  name: string;
  creatorCaptain: ContestMember;
  equipament: ContestMember[];
  business_idea: string;
  tutors: ContestTutor[];
  files_folder?: string;
  assigned_tutor_id?: number;
  rubrica?: string;
  id_rubrica?: string;
}

export interface Categories {
  value: string;
  viewValue: string;
}


export interface ContestTutor {
  id: number;
  name: string;
  description: string;
  photo: string;
  isSelected?: boolean;
}

export interface Requisito {
  id?: number;
  img: string;
  title: string;
  description?: string[];
}

export interface Criterio {
  index: number;
  title: string;
  description: string;
}

export interface EmailContent {
  student: string;
  message: string;
  student_email: string;
  email_api?: string;
}

export interface Idioma {
  id: number;
  name: string;
  level: string;
  nationalCertif: boolean;
  internationalCertif: boolean;
}

// ADENU INTERNACIONAL

export interface CareerCourse {
  code: string;
  id: number;
  name: string;
  resolution?: string;
}

export interface EmailAvailable {
  avaliable: boolean;
}

// MATRICULACION - PLAN DE ESTUDIO

export interface Asignatura {
  subject_id: number;
  pensum_id: number;
  nombre: string;
  inscriptos: number;
  maxima: number;
  modelo: string;
  descripcion?: string;
  horarios?: string[];
  estado_asignatura: string;
  estado_alumno: string;
  isSelected?: boolean;
  elegir?: Asignatura[];
  secuencia?: number;
  tipo?: string;
  periodo?: string;
  periodo_id?: number;
  tipo_credito_id?: number;
}

export interface CarreraPrincipal {
  carrera: string;
  carrera_estudiante: number;
  costo_asignatura: number;
  costo_pract_social?: number;
  habilitada?: boolean;
  habilitado?: boolean;
  estado_bloqueo: string;
  id: number;
  modalidad?: string;
  nombre?: string;
  planes: PlanEstudio[];
  resolucion: string;
  aprobacion?: string;
}

export interface NotasCarreras{
  career_id: string;
  career_lines: [];
  career_name: string;
  career_status: EstadoCarrera;
}

export interface EstadoCarrera {
  aprobadas: number;
  conv: number;
  cursando: number;
  desaprobradas: number;
  pendientes: number;
  preconv: number;
  progreso: string;
  total: number;
}

export interface PlanEstudio {
  asignatura?: string;
  asinatura?: string;
  codigo: string;
  credito: string;
  periodo: string;
  Prerequisito: string;
}

export interface CarreraMatricula {
  asignaturas: Asignatura[];
  carrera: CarreraSimple;
}

export interface CarreraPeriodos {
  carrera: CarreraSimple;
  periodos: Asignatura[];
}

export interface CarreraSimple {
  id: number;
  nombre: string;
  habilitado: boolean;
}

export interface Periodo {
  periodo: string;
  periodo_id: number;
}

// OPORTUNIDADES

export interface Pago {
  cuotas: number;
  estado: string;
  fecha_creacion: string;
  forma_pago: string;
  importe: number;
  mooneda: string;
  observaciones: boolean;
  sede: string;
  valor_cuota: number;
  dia_pago_diferido: number;
  diferir_pago_a_mes_proximo: boolean;
  link_pago: string;
}

export interface StageId {
  id: number;
  name: string;
}

export interface Oportunidad {
  create_date: string;
  id: number;
  pagos: Pago[];
  productos: Producto[];
  stage_id: StageId;
}

export interface Producto {
  cantidad: number;
  precio_sin_impuesto: number;
  price_total: number;
  producto: string;
  educat_credit_type_id?: number;
}

// ESTADO FINANCIERO

export interface MoveLine {
  conciliado: string;
  cuota: number;
  deuda: number;
  fecha_pago: string;
  fecha_vencimiento: string;
  monto: number;
}

export interface Factura {
  Saldo: number;
  comprobante: string;
  cuotas: number;
  detalle: string;
  fecha: string;
  move_line: MoveLine[];
  total: number;
  vencimiento: string;
  opp?: number;
}

export interface EstadoFinanciero {
  factura?: Factura[];
  odoo_data?: {
    factura: Factura[];
    resultado: string;
    total_adeudado: number;
    total_facturado: number;
    total_vencido: number;
  };
  resultado?: string;
  total_adeudado?: number;
  total_facturado?: number;
  total_vencido?: number;
  token: string;
  educat_partner_id?: number;
}

export interface PostCredits {
  creditos?: number;
  productos?: PostProduct[];
  cuotas: number;
  monto: number;
  numero_pago: string;
  forma_pago: string;
  dia_pago_diferido: number;
  diferir_pago_a_mes_proximo: boolean;
}

export interface PostProduct {
  id: number;
  cantidad: number;
  monto?: number;
}

// EVENTOS

export interface Evento {
  category: { id: number; name: string };
  crm_id: number;
  date_begin: string;
  date_end: string;
  day: number;
  description: string;
  event_type: string;
  exhibitors: string;
  has_tariff: string;
  hour_begin: string;
  hour_end: string;
  image_url: string;
  is_registered: boolean;
  month: string;
  publication_name: string;
  publish_in_home: boolean;
  registration_url: string;
  repo_id: boolean;
  ticket_value: string;
  type: string;
  webinar_url: string;
}

// PQRS

export interface PQRSCategory {
  crm_id: number;
  name: string;
}

export interface PQRSCaseKind {
  categories: PQRSCategory[];
  crm_id: number;
  name: string;
}

export interface PQRSArea {
  crm_id: number;
  name: string;
}

// BLOQUEO

export interface DetalleBloqueo {
  carrera: string;
  estado_bloqueo: string;
}

// CARRITO COMPRA

export interface Product {
  id: number;
  type: string;
  name: string;
  price: number;
  limit: number;
  show: boolean;
  credit_type: string;
}

export interface CartProduct {
  id: number;
  type: string;
  name: string;
  price: number;
  limit: number;
  quantity: number;
  subtotal: number;
}

export interface Cart {
  products: CartProduct[];
  total: number;
  units: number;
}

export interface UserDoc {
  nombre: string;
  link: string;
  category: string;
  category_id: number;
  id: number;
  documentos_adjuntos: Array<IMultiDocs>;
  estado: any;
  motivo: string;
  carga_multiple: boolean;
  descripcion?: string;
  area?: string;
  url_plantilla?: string;
  modalidad?: string;
}

export interface IMultiDocs{
  estado: boolean;
  fecha_vencimiento:boolean;
  id:number;
  motivo:string;
  nombre:string;
}

export interface ICourse{
  courseList: IResource[];
}

export interface IResource extends ICourseRegister{
  areas: number;
  autor: boolean;
  codigo: string;
  descripcion: string;
  duracion: number;
  duracion_unidad_tiempo: string;
  eje_tematico_ids: any[];
  fecha_actualizacion: string;
  fecha_creacion: string;
  id: number;
  imagen: string;
  name: string;
  nombre_publicacion: string;
  published: boolean;
  recursos: number;
  tag_id: string[];
}

export interface ITag {
  id: number;
  name: string;
}

export interface IDetail {
  id: number;
  publication_name: string;
  tags: ITag[];
  project_100: boolean;
  progress: number;
  image_url: string;
  description: string;
}

export interface ICourseRegister {
  date: string;
  detail: IDetail;
  description: string;
  state: string;
  type: string;
}

