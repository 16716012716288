<button
  mat-icon-button
  [cdkMenuTriggerFor]="notificationMenu"
  class="example-standalone-trigger"
>
  <mat-icon
    [matBadge]="getUnseenNotifications()"
    [matBadgeHidden]="getUnseenNotifications() < 1"
    matBadgeColor="error"
    class="material-icons-round"
    >notifications</mat-icon
  >
</button>

<ng-template #notificationMenu xPosition="after">
  <div class="notification-menu" cdkMenu>
    <div class="header">
      <div class="title">Notificaciones</div>
      <div class="button-container">
        <button
          mat-icon-button
          matTooltip="Actualizar"
          aria-label="Actualizar notificaciones"
          (click)="getNotifications()"
        >
          <mat-icon class="material-icons-round">sync</mat-icon>
        </button>
      </div>
    </div>
    <div class="body" *ngIf="!loading && notifications.length > 0">
      <ng-container *ngFor="let item of notifications">
        <app-notification-card [notification]="item"></app-notification-card>
      </ng-container>
    </div>
    <div
      cdkMenuItem
      class="footer"
      *ngIf="!loading && notifications.length > 0"
    >
      <a [routerLink]="['/notificaciones']" (click)="(close)">Ver todas</a>
    </div>
    <div class="notfound-container" *ngIf="loading">
      <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
    </div>
    <div
      class="notfound-container"
      (click)="$event.stopPropagation(); $event.preventDefault()"
      *ngIf="!loading && notifications.length === 0 && !error"
    >
      <img
        src="../../../../../assets/images/content/notificaciones.png"
        alt=""
      />
      <h2>¡Aún no tienes notificaciones!</h2>
    </div>
    <div
      class="notfound-container"
      (click)="$event.stopPropagation(); $event.preventDefault()"
      *ngIf="!loading && error"
    >
      <img
        src="../../../../../assets/images/content/notificaciones.png"
        alt=""
      />
      <h2>No pudimos encontrar tus notificaciones</h2>
      <p>
        Parece que aún no tienes notificaciones o no pudimos encontrarlas,
        intenta de nuevo más tarde o contacta a tu Academic Advisor para más
        información.
      </p>
    </div>
  </div>
</ng-template>
