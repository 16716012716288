import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component';

@Component({
  selector: 'app-movilidad-docentes',
  templateUrl: './movilidad-docentes.component.html',
  styleUrls: ['./movilidad-docentes.component.scss'],
})
export class MovilidadDocentesComponent implements OnInit {
  constructor(public dialog: MatDialog, private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('ADENU | Movilidad Internacional');
  }

  openHelpDialog(): void {
    const dialogRef = this.dialog.open(ContactDialogComponent, {
      width: 'auto',
      data: {
        title: 'DOCENTES Y/O INVESTIGADORES ADENU | Movilidad Internacional',
      },
      panelClass: 'plain-alert'
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
