import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-estado-ticket',
  templateUrl: './estado-ticket.component.html',
  styleUrls: ['./estado-ticket.component.scss'],
})
export class EstadoTicketComponent {
  public button = false;
  public ticket = null;
  public info = null;

  constructor(private service: DataService) {}

  dataTicket(e): void {
    const ticket = parseInt(e.value, 10);
    if (Number.isNaN(ticket) !== true) {
      this.button = true;
      this.ticket = ticket;
    } else {
      this.button = false;
      this.ticket = null;
    }
  }

  getStatusTicket(): void {
    if (this.ticket != null) {
      this.service.getTicketById(this.ticket).subscribe(
        (res) => {
          this.info = res?.response_data;
        },
        (err) => {
          this.service.openAlert(
            'Algo salió mal',
            'El ticket ingresado no existe o no te pertenece',
            'Entendido'
          );
        }
      );
    }
  }
}
