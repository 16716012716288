<app-header [title]="'Notificaciones'"></app-header>
<div class="container">
  <div class="main-container main-shadow main-radius mt-5 mb-5">
    <div class="buscador">
      <div class="form-field">
        <mat-icon class="material-icons-round search"> search </mat-icon>
        <input
          matInput
          type="text"
          placeholder="Buscar por nombre"
          [(ngModel)]="searchValue"
        />
        <div class="suffix">
          <button
            *ngIf="searchValue"
            mat-icon-button
            color="primary"
            aria-label="Clear"
            (click)="cleanSearch()"
          >
            <mat-icon class="material-icons-round">close</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="categories-list" *ngIf="categories.length > 0">
      <app-filter-chip
        [name]="'Todas'"
        [active]="!this.activeCategory"
        (click)="resetNotifications()"
      ></app-filter-chip>
      <ng-container *ngFor="let item of categories">
        <app-filter-chip
          [name]="item.category_name"
          [active]="this.activeCategory === item.category_id"
          (click)="filterCategory(item)"
        ></app-filter-chip>
      </ng-container>
    </div>
    <div
      class="notifications-list"
      *ngIf="!loading && filteredNotifications.length > 0"
    >
      <ng-container
        *ngFor="let item of filteredNotifications | filter: searchValue"
      >
        <app-notification-card [notification]="item"></app-notification-card>
      </ng-container>
    </div>
    <div class="notfound-container" *ngIf="loading">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
    <div
      class="notfound-container"
      *ngIf="!loading && filteredNotifications.length === 0 && !error"
    >
      <img src="../../../assets/images/content/notificaciones.png" alt="" />
      <h2>¡Aún no tienes notificaciones!</h2>
      <div class="button-container">
        <button mat-flat-button color="primary" (click)="getNotifications()">
          Actualizar
        </button>
      </div>
    </div>
    <div class="notfound-container" *ngIf="!loading && error">
      <img src="../../../assets/images/content/notificaciones.png" alt="" />
      <h2>No pudimos encontrar tus notificaciones</h2>
      <p>
        Parece que aún no tienes notificaciones o no pudimos encontrarlas,
        intenta de nuevo más tarde o contacta a tu Academic Advisor para más
        información.
      </p>
      <div class="button-container">
        <button
          mat-stroked-button
          color="primary"
          routerLink="/academic-advisor"
        >
          Contactar Academic Advisor
        </button>
        <button mat-flat-button color="primary" (click)="getNotifications()">
          Actualizar
        </button>
      </div>
    </div>
  </div>
</div>
