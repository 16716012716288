<div *ngIf="resourceData.state !== 'Baja'" class="main">
    <div class="portrait">
      <img class="img-portrait" [src]="resourceData.imagen || resourceData.detail.image_url" alt="">
    </div>
    <div class="progress-container">
      <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
      <div>{{progress + '%'}}</div>
    </div>
    <div class="info">
        <div class="title">{{resourceData.nombre_publicacion || resourceData.detail.publication_name}}</div>
        <div class="author">
          <!-- <iconify-icon class="calendar-icon" icon="la:calendar-check"></iconify-icon>
            <div class="date-info-container mr">
              <div class="data-label">Inicio</div>
              <div class="date">{{resourceData.fecha_actualizacion | date:'dd-MM-YYYY'}}</div>
            </div>
          <iconify-icon class="calendar-icon" icon="la:calendar-times-solid"></iconify-icon>
          <div class="date-info-container">
            <div class="data-label">Vencimiento</div>
            <div class="date">{{resourceData.fecha_actualizacion | date:'dd-MM-YYYY'}}</div>
          </div> -->
          <div *ngIf="resourceData.state === 'confirmado'"class="duration-container">
            <div class="time-icon">
              <mat-icon>access_time</mat-icon>
            </div>
            <div class="course-time">
              {{resourceData.duracion + ' ' + resourceData.duracion_unidad_tiempo + ' de cursado'}}
            </div>
          </div>
          <div *ngIf="resourceData.state === 'Cursado suspendido'" class="bloq-container">
            <iconify-icon class="lock-icon" icon="mdi:lock-outline"></iconify-icon>
            <div class="bloq-adv">Bloqueada</div>  
            <div class="block-label">
              ¿Por qué está bloqueada?
            </div>
          </div>
          <div *ngIf="resourceData.state === 'Inactivo'" class="bloq-container">
            <iconify-icon class="lock-icon" icon="la:calendar-times-solid"></iconify-icon>
            <div class="bloq-adv">Vencida</div>  
            <div class="block-label">
              ¿Por qué está vencida?
            </div>
          </div>
        </div>
    </div>
    <div class="option-section">
      <div class="option-dots"></div>
      <div (click)="!isRegister ? courseRegister() : clickVideo()" class="start-course">{{progress === 0 ? 'Iniciar' : 'Continuar'}}</div>
      <!-- <div *ngIf="!isRegister"  class="start-course">Matricularse</div> -->
    </div>
    <!-- <div *ngIf="progress === 0 && !resourceData.detail" class="card-chip pre-enrolled">
      Preinscripto
    </div> -->
    <div *ngIf="progress === 0" class="card-chip to-start-chip">
      Por iniciar
    </div>
    <div *ngIf="progress > 0 && progress != 100" class="card-chip in-progress-chip">
      En curso
    </div>
    <div *ngIf="progress === 100" class="card-chip finish-chip">
      Finalizado
    </div>
</div>
