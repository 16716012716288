import { MatDialog } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DataService } from 'src/app/shared/services/data.service';
import { Component, Input, OnInit } from '@angular/core';
import { CreateDialogComponent } from './create-dialog/create-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { IEspecialidad } from 'src/app/shared/interfaces/interfaces';

@Component({
  selector: 'app-formacion-academica',
  templateUrl: './formacion-academica.component.html',
  styleUrls: ['./formacion-academica.component.scss'],
})
export class FormacionAcademicaComponent implements OnInit {
  formacionAcademica = null;
  noFormacion = false;
  isLoading = false;
  sending = false;
  certificatedHours: any;
  especialidad: IEspecialidad ;

  formacionForm: UntypedFormGroup;

  @Input() universidades;
  @Input() countries;
  @Input() allSpecialities;
  @Input() userSpecialities;
  
  @Input() areasEspecialidad = [];
  @Input() idiomas;

  constructor(
    private dataService: DataService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.formacionForm = this.formBuilder.group({
      area_especialidad: ['', Validators.required],
      estudios_docencia_superior: ['', Validators.required],
    });
    this.getFormacion();
  }

  stateToClassMapping: { [state: string]: string } = {
    'Cursando': 'chip-azul',
    'Culminado': 'chip-verde',
    'Por Iniciar': 'chip-amarillo',
  };


  getFormacion() {
    this.isLoading = true;
    this.dataService.getEstudiosDocente().subscribe(
      (data) => {
        this.formacionAcademica = data;
        this.formacionForm.setValue({
          area_especialidad: this.formacionAcademica.area_especialidad,
          estudios_docencia_superior:
            this.formacionAcademica.estudios_docencia_superior,
        });
        this.formacionForm = this.formBuilder.group({
          area_especialidad: [
            this.parseEspecialidad(data.area_especialidad),
            Validators.required,
          ],
          estudios_docencia_superior: [
            data.estudios_docencia_superior,
            Validators.required,
          ],
        });
        this.formatHoursData();
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.noFormacion = true;
      }
    );
    this.dataService.getUserSpecialties().subscribe(data =>{
      this.userSpecialities = data.speciality_areas;
    })
  }

  formatHoursData(){
    const dataKeys = Object.keys(this.formacionAcademica.horas_certificados);
    this.certificatedHours = dataKeys.flatMap(key => {
      const yearData = this.formacionAcademica.horas_certificados[key];
      return yearData.certificates.map(certificate => ({
        year: key,
        certificate_name: certificate.certificate_name,
        certificate_hours: certificate.certificate_hours,
        total_hours: yearData.total_hours
      }));
    });
  }

  guardarConfig() {
    const saveSubmit = document.getElementById('save-button');
    if (this.formacionForm.status === 'VALID') {
      this.sending = true;
      saveSubmit.innerText = 'Guardando...';
      this.dataService
        .updateAcademicsProfesor(
          this.formacionForm.get('estudios_docencia_superior').value,
          this.formacionForm.get('area_especialidad').value
        )
        .subscribe(
          (data) => {
            saveSubmit.innerText = 'Guardar cambios';

            this.sending = false;
            this.dataService.openAlert(
              '¡Estudios guardados!',
              'Tus estudios se cargaron con éxito',
              'Cerrar'
            );
          },
          (err) => {
            this._snackBar.open(
              'Ha ocurrido un error al generar el registro',
              'Cerrar',
              {
                duration: 7000,
              }
            );
          }
        );
    } else {
      this._snackBar.open(
        'No se han completado todos los campos obligatorios',
        'Cerrar',
        {
          duration: 7000,
        }
      );
    }
  }
  openSuperior(){
      const dialogRef = this.dialog.open(CreateDialogComponent, {
        width: '400px',
        data: {
          tipo: 'superior',
          defaultData: { 
            area_especialidad: {
              id: this.parseEspecialidad(this.formacionAcademica.area_especialidad),
              name: this.formacionAcademica.area_especialidad
            },
            estudios_docencia_superior: this.formacionAcademica.estudios_docencia_superior
          },
          areasEspecialidad: this.areasEspecialidad,
          states: this.stateToClassMapping
        },
        panelClass: 'plain-alert',
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'ok') {
          this.getFormacion();
        }
      });
  }

  compareValue(o1: any, o2: any): any {
    return o1 && o2 && o1 === o2;
  }

  parseEspecialidad(selection) {
    // Pais alumno
      for (const area of this.areasEspecialidad) {
        if (selection.toLowerCase() === area.speciality_name.toLowerCase()) {
          return area.speciality_id;
        }
      }
  }

  openConfirmDialog(item, tipo): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        title: '¿Estás seguro?',
        description:
          'Vas a eliminar el registro, una vez eliminado no lo podrás recuperar',
        confirmAction: 'Eliminar',
        cancelAction: 'Cancelar',
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ok') {
        if(tipo === 'estudio'){
          this.deleteEstudio(item.id)
        }
        if(tipo ==='idioma'){
          this.deleteIdioma(item.idioma_id);
        }
        if(tipo === 'certificado'){
          this.deleteCertificado(item.id)
        }
        if(tipo === 'area'){
          this.deleteArea(item.speciality_id)
        }
      }
    });
  }

  deleteArea(id){
    this.dataService.deleteSpecialty(id).subscribe((res) => {
      this.dataService.openAlert(
        '¡Área eliminada!',
        'El área seleccionada fue eliminada con éxito',
        'Cerrar'
      );
      this.getFormacion();
    })
  }

  deleteCertificado(id){
    this.dataService.deleteCertificado(id).subscribe((res) => {
      this.dataService.openAlert(
        '¡Certificado eliminado!',
        'El Certificado seleccionado fue eliminado con éxito',
        'Cerrar'
      );
      this.getFormacion();
    });
  }

  deleteEstudio(id) {
    this.dataService.deleteEstudio(id).subscribe((res) => {
      this.dataService.openAlert(
        '¡Estudio eliminado!',
        'El estudio seleccionado fue eliminado con éxito',
        'Cerrar'
      );
      this.getFormacion();
    });
  }

  openAddEstudioDialog(estudio = null): void {
    const dialogRef = this.dialog.open(CreateDialogComponent, {
      width: '400px',
      data: {
        universidades: this.universidades,
        paises: this.countries.paises,
        estudio: estudio,
        tipo: 'estudio',
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ok') {
        this.getFormacion();
      }
    });
  }

  openAddCertificadoDialog(certificado = null): void {
    const dialogRef = this.dialog.open(CreateDialogComponent, {
      width: '400px',
      data: {
        certificado: certificado,
        tipo: 'certificado',
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ok') {
        this.getFormacion();
      }
    });
  }

  deleteIdioma(id) {
    this.dataService.deleteIdioma(id).subscribe(
      (res) => {
        this.dataService.openAlert(
          '¡Idioma eliminado!',
          'El idioma seleccionado fue eliminado con éxito',
          'Cerrar'
        );
        this.getFormacion();
      },
      (err) => {
        this._snackBar.open(
          'No pudimos eliminar el registro, intenta más tarde',
          'Cerrar',
          {
            duration: 7000,
          }
        );
      }
    );
  }

  openAddIdiomaDialog(estudio = null): void {
    const dialogRef = this.dialog.open(CreateDialogComponent, {
      width: '400px',
      data: {
        idiomas: this.idiomas,
        tipo: 'idioma',
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ok') {
        this.getFormacion();
      }
    });
  }
  openAddAreaDialog(estudio = null): void {
    if(this.userSpecialities.length < 2){
    const dialogRef = this.dialog.open(CreateDialogComponent, {
      width: '400px',
      data: {
        areas: this.allSpecialities,
        tipo: 'area',
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ok') {
        this.getFormacion();
      }
    });
  }else{
    this._snackBar.open(
      'No puedes agregar más de dos áreas de especialidad',
      'Cerrar',
      {
        duration: 7000,
      }
    );
  }
}
}
