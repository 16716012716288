<div class="card-container">
  <div class="icon absolute">
    <mat-icon
      class="material-icons-round"
      [ngStyle]="{
        color: color
      }"
      >{{ icon }}</mat-icon
    >
  </div>

  <div class="card-body">
    <h3
      [ngStyle]="{
        color: color
      }"
    >
      {{ label }}
    </h3>
    <p class="value">{{ value }}</p>
    <p *ngIf="description">{{ description }}</p>
  </div>
</div>
