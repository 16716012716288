<div class="container-bg" id="form-docentes">
  <div class="heading">
    <div class="title primary">
      Formulario de inscripción Docentes y/o Investigadores de ADENU
    </div>
    <div class="description">
      Sigue los pasos y completa todos tus datos para postularte al programa.
    </div>
  </div>

  <div class="container" *ngIf="isLoading">
    <div class="center">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <div class="container" *ngIf="!isLoading && !abledUser">
    <div class="center">
      <div class="title secondary">¡Ya estás inscripto!</div>
      <div class="subtitle">
        Revisa tu mail para estar al tanto de las últimas novedades sobre tu
        postulación
      </div>
      <div class="subtitle">
        Si tienes dudas puedes escribirnos a
        <a href="mailto:ori@adenuniversity.edu.pa">ori@adenuniversity.edu.pa</a>
        o utilizar el formulario de contacto rápido
      </div>
      <button
        mat-flat-button
        color="accent"
        class="mt-3"
        (click)="makeContact()"
      >
        Contactar
      </button>
    </div>
    <div class="cronograma">
      <ng-container *ngFor="let item of cronograma">
        <div class="card-crono">
          <div class="date">{{ item.date }}</div>
          <div class="description">{{ item.descripcion }}</div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="form-container  main-radius main-shadow" *ngIf="!isLoading && abledUser">
    <mat-stepper
      #stepper
      linear="true"
      [orientation]="(stepperOrientation | async)!"
    >
      <mat-step
        [stepControl]="personalDataFormGroup"
        errorMessage="Campos requeridos"
      >
        <form [formGroup]="personalDataFormGroup">
          <ng-template matStepLabel>Datos personales</ng-template>
          <div class="title">Datos personales</div>
          <div class="columns three">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Nombre (como figura en tu pasaporte)</mat-label>
              <input
                matInput
                placeholder="Nombre"
                formControlName="name"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup.get('name').hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Apellido (como figura en tu pasaporte)</mat-label>
              <input
                matInput
                placeholder="Apellido"
                formControlName="last_name"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('last_name')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Fecha de nacimiento</mat-label>
              <input
                matInput
                type="date"
                placeholder="Selecciona tu fecha de nacimiento"
                formControlName="date_of_birth"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('date_of_birth')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>

          <div class="columns three">
            <mat-form-field
              *ngIf="countries !== undefined; else disabledNationality"
              class="full-width"
              appearance="fill"
            >
              <mat-label>Nacionalidad</mat-label>
              <mat-select
                required
                matNativeControl
                formControlName="nationality"
                [compareWith]="compareOptions"
              >
                <mat-option
                  *ngFor="let country of countries.paises"
                  [value]="country.name"
                >
                  {{ country.name }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('nationality')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <ng-template #disabledNationality>
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Nacionalidad</mat-label>
                <input matInput placeholder="Cargando" disabled />
                <div matSuffix class="sk-fading-circle">
                  <div class="sk-circle1 sk-circle"></div>
                  <div class="sk-circle2 sk-circle"></div>
                  <div class="sk-circle3 sk-circle"></div>
                  <div class="sk-circle4 sk-circle"></div>
                  <div class="sk-circle5 sk-circle"></div>
                  <div class="sk-circle6 sk-circle"></div>
                  <div class="sk-circle7 sk-circle"></div>
                  <div class="sk-circle8 sk-circle"></div>
                  <div class="sk-circle9 sk-circle"></div>
                  <div class="sk-circle10 sk-circle"></div>
                  <div class="sk-circle11 sk-circle"></div>
                  <div class="sk-circle12 sk-circle"></div>
                </div>
                <mat-hint>Cargando países</mat-hint>
              </mat-form-field>
            </ng-template>
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Número de Pasaporte (vigente)</mat-label>
              <input
                matInput
                placeholder="458799512"
                formControlName="passport"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('passport')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Fecha de vencimiento del Pasaporte</mat-label>
              <input
                matInput
                type="date"
                placeholder="Selecciona tu fecha de nacimiento"
                formControlName="passport_expiration"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('passport_expiration')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
          <div class="title">Datos de contacto</div>

          <div class="columns two" formGroupName="contact">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Correo electrónico</mat-label>
              <input
                matInput
                placeholder="example@example.com"
                formControlName="email"
                type="email"
                required
              />

              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('contact')
                    .get('email')
                    .hasError('email')
                "
                >Ingresa una dirección de correo válida</mat-error
              >
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('contact')
                    .get('email')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>Celular</mat-label>
              <lib-ngx-mat-tel-input
                [defaultCountry]="'PA'"
                formControlName="phone"
                required
              >
              </lib-ngx-mat-tel-input>
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('contact')
                    .get('phone')
                    .hasError('required')
                "
              >
                Este campo es <strong>requerido</strong>
              </mat-error>
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('contact')
                    .get('phone')
                    .hasError('format')
                "
              >
                El formato del número es <strong>invalido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="columns three" formGroupName="address">
            <mat-form-field
              *ngIf="countries !== undefined; else disabledCountry"
              class="full-width"
              appearance="fill"
            >
              <mat-label>País</mat-label>
              <mat-select
                required
                matNativeControl
                formControlName="country"
                [compareWith]="compareOptions"
              >
                <mat-option
                  *ngFor="let country of countries.paises"
                  [value]="country.name"
                >
                  {{ country.name }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('address')
                    .get('country')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <ng-template #disabledCountry>
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>País</mat-label>
                <input matInput placeholder="Cargando" disabled />
                <div matSuffix class="sk-fading-circle">
                  <div class="sk-circle1 sk-circle"></div>
                  <div class="sk-circle2 sk-circle"></div>
                  <div class="sk-circle3 sk-circle"></div>
                  <div class="sk-circle4 sk-circle"></div>
                  <div class="sk-circle5 sk-circle"></div>
                  <div class="sk-circle6 sk-circle"></div>
                  <div class="sk-circle7 sk-circle"></div>
                  <div class="sk-circle8 sk-circle"></div>
                  <div class="sk-circle9 sk-circle"></div>
                  <div class="sk-circle10 sk-circle"></div>
                  <div class="sk-circle11 sk-circle"></div>
                  <div class="sk-circle12 sk-circle"></div>
                </div>
                <mat-hint>Cargando países</mat-hint>
              </mat-form-field>
            </ng-template>
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Localidad</mat-label>
              <input
                matInput
                placeholder="Tu localidad"
                formControlName="location"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('address')
                    .get('location')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Domicilio actual</mat-label>
              <input
                matInput
                placeholder="San Justo 2546, Barrio X"
                formControlName="current_address"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('address')
                    .get('current_address')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>

          <div class="button-container">
            <div></div>
            <button mat-flat-button color="primary" (click)="checkEmail()">
              Siguiente
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step
        [stepControl]="academicDataFormGroup"
        errorMessage="Campos requeridos"
      >
        <form [formGroup]="academicDataFormGroup">
          <ng-template matStepLabel>Datos académicos</ng-template>
          <div class="title">Formación</div>

          <div class="columns two">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>Título de grado</mat-label>
              <input
                matInput
                placeholder="Licenciado en Administración de empresas"
                formControlName="grad_title"
                type="text"
                required
              />
              <mat-error
                *ngIf="
                  this.academicDataFormGroup
                    .get('grad_title')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>Área de experiencia</mat-label>
              <input
                matInput
                placeholder="Business Administration"
                formControlName="expertiseArea"
                type="text"
                required
              />
              <mat-error
                *ngIf="
                  this.academicDataFormGroup
                    .get('expertiseArea')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
          <div class="columns three" formGroupName="postgrad_title">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>Especialización</mat-label>
              <input
                matInput
                placeholder="Business Administration"
                formControlName="specialization"
                type="text"
              />
            </mat-form-field>
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>Mestría</mat-label>
              <input
                matInput
                placeholder="Business Administration"
                formControlName="master"
                type="text"
              />
            </mat-form-field>
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>Doctorado</mat-label>
              <input
                matInput
                placeholder="Business Administration"
                formControlName="doctorate"
                type="text"
              />
            </mat-form-field>
          </div>

          <div class="title">Carrera en la que te desempeñas en ADENU</div>
          <div class="description">
            Si te desempeñas en más de una carrera en ADENU, por favor elige la
            que consideres principal
          </div>

          <div class="columns two" formGroupName="dictatedCareer">
            <mat-form-field
              *ngIf="this.careers !== undefined"
              class="full-width"
              appearance="fill"
            >
              <mat-label>Selecciona una carrera</mat-label>
              <mat-select required matNativeControl formControlName="name">
                <mat-option
                  *ngFor="let career of careers"
                  [value]="career.name"
                >
                  <div class="uni-option">
                    <div class="text-body">
                      <div class="uni-name">{{ career.name }}</div>
                    </div>
                  </div></mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  this.academicDataFormGroup
                    .get('dictatedCareer')
                    .get('name')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>

            <mat-form-field class="full-width" appearance="fill">
              <mat-label>Modalidad</mat-label>
              <mat-select formControlName="mode" placeholder="Online" required>
                <mat-option value="Online">Online</mat-option>
                <mat-option value="Presencial">Presencial</mat-option>
                <mat-option value="Ambas">Ambas</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="title">Asignaturas que dictas en la carrera</div>
          <div class="description">
            Ingresa cada una de las asignaturas que dictas en la carrera
            seleccionada anteriormente
          </div>
          <ng-container formArrayName="dictatedCourses">
            <div class="courses-container">
              <ng-container
                *ngFor="
                  let courseForm of dictatedCourses.controls;
                  let i = index
                "
              >
                <div class="course-row" [formGroup]="courseForm">
                  <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Asignatura</mat-label>
                    <input
                      matInput
                      required
                      formControlName="name"
                      placeholder="Matemática"
                    />

                    <mat-error
                      *ngIf="courseForm.get('name').hasError('required')"
                      >Este campo es requerido</mat-error
                    >
                  </mat-form-field>
                  <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Modalidad</mat-label>
                    <mat-select
                      formControlName="mode"
                      placeholder="Online"
                      required
                    >
                      <mat-option value="Online">Online</mat-option>
                      <mat-option value="Presencial">Presencial</mat-option>
                      <mat-option value="Ambas">Ambas</mat-option>
                    </mat-select>

                    <mat-error
                      *ngIf="courseForm.get('mode').hasError('required')"
                      >Este campo es requerido</mat-error
                    >
                  </mat-form-field>

                  <button
                    mat-fab
                    class="mat-elevation-z0"
                    color="primary"
                    (click)="deleteCourse(i)"
                    [disabled]="i === 0"
                  >
                    <mat-icon class="material-icons-round">delete</mat-icon>
                  </button>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <button
            mat-flat-button
            color="primary"
            class="full-width"
            (click)="addDictatedCourse()"
          >
            <span class="material-icons"> add </span> Añadir asignatura
          </button>

          <div class="title">Universidad Destino</div>
          <div class="description">
            Ingresa el nombre la universidad o institución donde deseas
            concretar el plan de movilidad, la misma puede formar parte del
            <a
              href="https://adenuniversity.edu.pa/movilidad-internacional/"
              target="_blank"
              alt="Convenios de movilidad internacional ADENU"
              >listado de convenios vigentes</a
            >
            o no.
          </div>

          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Universidad destino</mat-label>
            <input
              matInput
              placeholder="Universidad de Lille"
              formControlName="selectedUniversity"
              type="text"
              required
            />
            <mat-error
              *ngIf="
                this.academicDataFormGroup
                  .get('selectedUniversity')
                  .hasError('required')
              "
              >Este campo es requerido</mat-error
            >
          </mat-form-field>

          <div class="button-container">
            <button mat-stroked-button color="primary" matStepperPrevious>
              Anterior
            </button>
            <button mat-flat-button color="primary" matStepperNext>
              Siguiente
            </button>
          </div>
        </form>
      </mat-step>
      <!-- 
      <mat-step
        [stepControl]="languagesDataFormGroup"
        errorMessage="Campos requeridos"
      >
        <form [formGroup]="languagesDataFormGroup">
          <ng-template matStepLabel>Idiomas</ng-template>
          <div class="language-row-check">
            <mat-checkbox formControlName="willing_to_learn_spanish"
              >Deseo realizar el curso de Español Online de ADENU
            </mat-checkbox>
            <mat-checkbox formControlName="languaje"
              >¿Tienes conocimientos en lenguas extranjeras?
            </mat-checkbox>
          </div>
          <button
            [disabled]="!this.languagesDataFormGroup.get('languaje').value"
            mat-flat-button
            color="accent"
            (click)="addLanguage()"
          >
            Añadir idioma <span class="material-icons"> add </span>
          </button>
          <ng-container formArrayName="list">
            <ng-container
              *ngFor="let languageForm of list.controls; let i = index"
            >
              <div class="language-row" [formGroup]="languageForm">
                <mat-form-field class="full-width" appearance="fill">
                  <mat-label>Idioma</mat-label>
                  <input
                    matInput
                    required
                    formControlName="name"
                    placeholder="Inglés"
                  />
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                  <mat-label>Nivel</mat-label>
                  <mat-select
                    formControlName="level"
                    placeholder="Tu nivel"
                    required
                  >
                    <mat-option value="Básico">Básico</mat-option>
                    <mat-option value="Intermedio">Intermedio</mat-option>
                    <mat-option value="Avanzado">Avanzado</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="checks">
                  <mat-checkbox formControlName="national_certificate"
                    >Poseo Certificado Nacional
                  </mat-checkbox>
                  <mat-checkbox formControlName="international_certificate"
                    >Poseo Certificado Internacional
                  </mat-checkbox>
                </div>

                <button mat-fab color="primary" (click)="deleteLanguage(i)">
                  <mat-icon class="material-icons-round">delete</mat-icon>
                </button>
              </div>
            </ng-container>
          </ng-container>
          <div class="button-container">
            <button mat-stroked-button color="primary" matStepperPrevious>
              Anterior
            </button>
            <button mat-flat-button color="primary" matStepperNext>
              Siguiente
            </button>
          </div>
        </form>
      </mat-step> -->
      <mat-step
        [stepControl]="filesDataFormGroup"
        errorMessage="Faltan documentos"
      >
        <form [formGroup]="filesDataFormGroup">
          <ng-template matStepLabel>Documentación requerida</ng-template>
          <div class="description">
            <div class="title primary">Carga de documentación</div>
            <div class="detail">
              <p>Deberás presentar la siguiente documentación:</p>
              <ul>
                <li>Curriculum Vitae abreviado</li>
                <li>Pasaporte escaneado</li>
                <li>
                  Carta Aval completa y firmada (<a
                    href="https://drive.google.com/uc?export=download&id=1o11fG2Ld5yy-fAELjH1PZICK3i-CqnRf"
                    target="_blank"
                    >descargar formato</a
                  >)
                </li>
                <li>
                  Declaración Jurada Aval completa y firmada (
                  <a
                    href="https://drive.google.com/uc?export=download&id=1ztpH-fZtPuT6TFZstgn-dcWNk_nbUDtj"
                    target="_blank"
                    >descargar formato</a
                  >
                  )
                </li>
                <li>
                  Plan de Trabajo y Transferencia Aval completo y firmado (<a
                    href="https://drive.google.com/uc?export=download&id=1z_fAaQEo2fl47Y_9f5P2QeMFD_V55ix3"
                    target="_blank"
                    >descargar formato</a
                  >)
                </li>
              </ul>
              <p>
                Una vez que hayas enviado tu postulación, no podrás modificar la
                documentación por este medio. Por favor, chequea la validez de
                los documentos presentados.
              </p>
            </div>
          </div>
          <app-upload-internacional
            (filesEvent)="setFiles($event)"
            [filesLimit]="5"
          ></app-upload-internacional>
          <div class="button-container">
            <button mat-stroked-button color="primary" matStepperPrevious>
              Anterior
            </button>
            <button
              [disabled]="this.files.length < 5"
              mat-flat-button
              color="primary"
              matStepperNext
              (click)="createFormData()"
            >
              Siguiente
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Enviar</ng-template>

        <div class="button-container">
          <div class="title primary">¡Chequea tu ficha antes de enviar!</div>
        </div>
        <div *ngIf="this.formData !== undefined">
          <app-ficha-inscripcion
            [userType]="'docente'"
            [formData]="this.formData"
            [filesArray]="this.files"
          ></app-ficha-inscripcion>
        </div>

        <div class="button-container">
          <button mat-stroked-button color="primary" matStepperPrevious>
            Anterior
          </button>
          <button
            mat-flat-button
            color="primary"
            id="completeInscription"
            (click)="completeInscription()"
          >
            <span id="send-text">Completar inscripción</span>
            <div id="loader" class="lds-ring no-display">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>

<div *ngIf="this.isWaiting" class="absolute" id="absolute-message">
  <div class="soonContainer">
    <div class="soonMessage spinner">
      <mat-spinner></mat-spinner>
      <h2>Enviando..</h2>
      <p>Por favor no cierres esta pestaña</p>
    </div>
  </div>
</div>
