<div id="registerContainer">
    <img src="../../../assets/images/logos/pericles-logo.png" class="img-logo" alt="">
  <div [class]="errorAlert ? 'form-container-alert' : ''" id="formContainer">
    <div class="app-logo">
      <img src="../../../assets/images/logos/logo-aden.png" />
    </div>
    <div>
      <h1 class="title-campus">Campus Faculty</h1> 
      <h3 class="subtitle-campus">¡Bienvenidos, profesores!</h3> 
    </div>
    <div [class]="errorAlert ? 'wrap-login login-with-alert': 'wrap-login'">

      <form
        novalidate
        [formGroup]="loginFormGroup"
        id="loginForm"
        action="/home"
        action="https://fa.aden.org/oauth2/authorize"
        method="post"
      >
        <input
          type="hidden"
          name="client_id"
          value="2c54fc21-43b7-4c96-ba29-a4a66fd54342"
        />
        <input
          type="hidden"
          name="redirect_uri"
          value="https://campus.adenu.edu.pa"
        />
        <input type="hidden" name="response_type" value="code" />
        <input
          type="hidden"
          name="state"
          value="iY3A4ZPnWt_MMtEtMIaKDbpjQ5BQFm0M2mDevG3UrVI"
        />

        <mat-form-field class="w-100">
          <mat-label class="label">Correo electrónico</mat-label>
          <input
            matInput
            class="no-marker"
            name="loginId"
            type="email"
            required
            formControlName="email"
            placeholder="Ingrese su correo electrónico."
          />
        </mat-form-field>
        <mat-form-field class="pb-2 w-100">
          <mat-label class="label">Contraseña</mat-label>
          <input
            class="no-marker"
            matInput
            name="password"
            required
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            placeholder="Ingrese su contraseña."
          />
          <img
            [src]="hide ? '../../../assets/images/icons/white/vissibility.png': '../../../assets/images/icons/white/vissibility-on.png'"
            class="material-icons-round eye"
            matSuffix
            (click)="hide = !hide"
          >
        </mat-form-field>
        <button
          mat-flat-button
          id="btn-login"
          [disabled]="btnDisabled"
          type="submit"
          class="w-100 .cdk-text-field-autofilled btn-login"
          (click)="onLogIn()"
        >
          Ingresar
        </button>
        <button class="pass-btn" (click)="onForgotPassword()">
          Olvidé mi contraseña
        </button>
      </form>
      <div [class]="'warn-alert'" *ngIf="errorAlert">
        <div class="body">
          <div class="head">
            <img src="../../../assets/images/icons/jam_alert.svg" alt="">
            <h1>Correo electrónico y/o contraseña incorrectos</h1>
          </div>
          <p>Por favor intenta con otras credenciales.</p>
        </div>
        <div class="triangle">

        </div>
        <button
        class="close-alert"
          mat-icon-button
          aria-label="Cerrar alerta"
          (click)="closeAlert()"
        >
          <mat-icon class="material-icons-round">close</mat-icon>
        </button>
      </div>
    </div>
    <!-- <div class="wrap-login" *ngIf="loading">
      <div class="spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div> -->
    <div class="social-icons">
      <a
        class="mx-1"
        target="_blank"
        href="https://www.facebook.com/ADENUniversityPanama/"
      >
        <img
          src="../../../../assets/images/icons/white/facebook-w.png"
          alt="Facebook"
        />
      </a>
      <!-- <a class="mx-1" target="_blank" href="https://twitter.com/aden_pa">
        <img
          class="twitter"
          src="../../../assets/images/icons/white/twitter-w.svg"
          alt="Twitter"
        />
      </a> -->
      <a
        class="mx-1"
        target="_blank"
        href="https://www.instagram.com/adenupa/?hl=es-la"
      >
        <img
          src="../../../../assets/images/icons/white/instagram-w.png"
          alt="Instagram"
        />
      </a>
      <a
        class="mx-1"
        target="_blank"
        href="https://www.linkedin.com/company/universidadaltadirecci%C3%B3n"
      >
        <img
          src="../../../../assets/images/icons/white/linkedin-w.png"
          alt="LinkedIn"
        />
      </a>
      <a
        class="mx-1"
        target="_blank"
        href="https://www.youtube.com/channel/UCpTcEFxdU19ZHrkELRNOBmg/videos"
      >
        <img
          src="../../../../assets/images/icons/white/youtube-w.png"
          alt="Youtube"
        />
      </a>
      <a
        class="mx-1"
        target="_blank"
        href="https://www.tiktok.com/@adenuniversity?"
      >
        <img
          src="../../../../assets/images/icons/white/tiktok-w.png"
          alt="TikTok"
        />
      </a>
    </div>
    <div class="mt-1">
      <a class="web-adenupa">www.adenuniversity.edu.pa</a>
    </div>
  </div>
</div>

<!-- <div class="row">
  <div id="loginForm" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <div class="wrap-login">
      <div class="app-logo">
        <img src="../../../assets/images/logos/logo-adenupa-blue.png" />
      </div>
      <form
        novalidate
        [formGroup]="loginFormGroup"
        id="formlogin"
        action="/home"
        action="https://fa.aden.org/oauth2/authorize"
        method="post"
      >
        <input
          type="hidden"
          name="client_id"
          value="2c54fc21-43b7-4c96-ba29-a4a66fd54342"
        />
        <input
          type="hidden"
          name="redirect_uri"
          value="https://campus.adenu.edu.pa"
        />
        <input type="hidden" name="response_type" value="code" />
        <input
          type="hidden"
          name="state"
          value="iY3A4ZPnWt_MMtEtMIaKDbpjQ5BQFm0M2mDevG3UrVI"
        />

        <mat-form-field class="w-100">
          <mat-label>Correo electrónico</mat-label>
          <input
            matInput
            name="loginId"
            type="email"
            required
            formControlName="email"
            placeholder="Ingrese su correo electrónico."
          />
        </mat-form-field>
        <mat-form-field class="pb-2 w-100">
          <mat-label>Contraseña</mat-label>
          <input
            matInput
            name="password"
            required
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            placeholder="Ingrese su contraseña."
            autocomplete="off"
          />
          <mat-icon
            class="material-icons-round"
            matSuffix
            (click)="hide = !hide"
          >
            {{ hide ? "visibility_off" : "visibility" }}</mat-icon
          >
        </mat-form-field>
        <button
          mat-flat-button
          id="btn-login"
          type="submit"
          color="primary"
          class="w-100 mb-2"
          (click)="onLogIn()"
        >
          Ingresar
        </button>
        <button mat-button class="w-100 mb-4" (click)="onForgotPassword()">
          Olvidé mi contraseña
        </button>
      </form>
      <div class="social-icons">
        <a
          class="mx-1"
          target="_blank"
          href="https://www.facebook.com/ADENUniversityPanama/"
        >
          <img src="../../../../assets/images/icons/facebook.png" alt="" />
        </a>
        <a class="mx-1" target="_blank" href="https://twitter.com/aden_pa">
          <img src="../../../../assets/images/icons/twitter.png" alt="" />
        </a>
        <a
          class="mx-1"
          target="_blank"
          href="https://www.instagram.com/adenupa/?hl=es-la"
        >
          <img src="../../../../assets/images/icons/instagram.png" alt="" />
        </a>
        <a
          class="mx-1"
          target="_blank"
          href="https://www.linkedin.com/company/universidadaltadirecci%C3%B3n"
        >
          <img src="../../../../assets/images/icons/linkedin.png" alt="" />
        </a>
        <a
          class="mx-1"
          target="_blank"
          href="https://www.youtube.com/channel/UCpTcEFxdU19ZHrkELRNOBmg/videos"
        >
          <img src="../../../../assets/images/icons/youtube.png" alt="" />
        </a>
      </div>
      <div class="mt-1">
        <a class="web-adenupa">www.adenuniversity.edu.pa</a>
      </div>
    </div>
  </div>
  <div id="image" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
    <img src="../../../assets/images/bg/bg-login-1.jpg" />
  </div>
</div>
 -->
