import { ContestTeam, EmailContent } from './../interfaces/interfaces';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, pipe } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContestService {
  url = environment.contestUrl;

  constructor(private http: HttpClient) {}

  /* GENÉRICO */

  send_email(content: EmailContent): Observable<any> {
    return this.http.post(`${this.url}/equipaments/send_email`, content);
  }

  send_email_impulso(content: EmailContent): Observable<any> {
    return this.http.post(`${this.url}/impulso/send_email`, content);
  }

  /* APRENDE Y EMPRENDE */

  checkUser(id: number): Observable<any> {
    return this.http.get(`${this.url}/equipaments/check_user/${id}`);
  }

  createTeam(team: ContestTeam): Observable<any> {
    return this.http.post(`${this.url}/equipaments/create_equipament`, team);
  }

  getTutors(): Observable<any> {
    return this.http.get(`${this.url}/equipaments/get_tutors`);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${this.url}/documents/get_files`);
  }

  createFolder(name: string, idEquipament: string): Observable<any> {
    const formData = new FormData();
    formData.append('name_folder', name);
    formData.append('id_equipament', idEquipament);
    return this.http.post(`${this.url}/documents/create_folder`, formData);
  }

  sendForm(data): Observable<any> {
    return this.http.post(`${this.url}/documents/create_folder`, data);
  }

  uploadFiles(folderId, filesFormData): Observable<any> {
    return this.http.post(
      `${this.url}/documents/upload_files/${folderId}`,
      filesFormData
    );
  }

  /* PROGRAMA IMPULSO */

  sendImpulsoInscription(formData): Observable<any> {
    return this.http.post(`${this.url}/impulse/upload_documentation`, formData);
  }

  checkImpulseUser(userId, userEmail): Observable<any> {
    const params = {
      id: userId,
      mail: userEmail,
    };
    return this.http.get(`${this.url}/impulse/check_user/member`, { params });
  }

  updateDocs(formData): Observable<any> {
    return this.http.put(`${this.url}/impulse/update_documentation`, formData);
  }

  /* MOVILIDAD INTERNACIONAL SALIENTES*/

  createInscription(formData): Observable<any> {
    return this.http.post(
      `${this.url}/international_mobility/form_inscription`,
      formData
    );
  }

  uploadUserDocumentation(formData): Observable<any> {
    return this.http.post(
      `${this.url}/international_mobility/upload_documentation`,
      formData
    );
  }

  checkInscription(id: number): Observable<any> {
    return this.http.get(`${this.url}/international_mobility/check/${id}`);
  }

  /* MOVILIDAD INTERNACIONAL ALUMNOS ENTRANTES*/

  createIncomingStudent(formData): Observable<any> {
    return this.http.post(
      `${this.url}/international_mobility/create_external_student`,
      formData
    );
  }

  checkIncomingStudent(email): Observable<any> {
    return this.http.post(
      `${this.url}/international_mobility/check_external_student`,
      { email }
    );
  }

  uploadIncomingStudentDocs(formData): Observable<any> {
    return this.http.post(
      `${this.url}/international_mobility/upload_documentation_extenal_student`,
      formData
    );
  }

  /* MOVILIDAD INTERNACIONAL DOCENTES ADENU*/

  createProfesor(formData): Observable<any> {
    return this.http.post(
      `${this.url}/international_mobility/create_teacher`,
      formData
    );
  }

  checkProfesor(email): Observable<any> {
    return this.http.post(`${this.url}/international_mobility/check_teacher`, {
      email,
    });
  }

  uploadProfesorDocs(formData): Observable<any> {
    return this.http.post(
      `${this.url}/international_mobility/upload_documentation_profesor`,
      formData
    );
  }

  /* PROGRAMA IMPULSO */
}
