<div id="adenu-international">
  <app-head-internacional></app-head-internacional>
  <app-sobre-internacional id="sobre"></app-sobre-internacional>
  <app-cronograma-internacional id="cronograma"></app-cronograma-internacional>
  <app-contacto-internacional
    [user]="this.capitan"
    id="contacto"
  ></app-contacto-internacional>
  <app-form-internacional id="inscripcion"></app-form-internacional>
</div>
