<img *ngIf="data.img && data.img.length" [src]="data.img" alt="">

<div class="dialog-header">
  <mat-icon class="material-icons-round bounce success" *ngIf="data.type === 'success'">{{data.icon}}</mat-icon>
  <mat-icon class="material-icons-round shake error" *ngIf="data.type === 'error'">{{data.icon}}</mat-icon>
  <div mat-dialog-title>
  <h1>{{data.title}}</h1>
  </div>
</div>
<div class="dialog-container">
  <div mat-dialog-content>
    {{data.description}}
  </div>
  <div mat-dialog-actions>
    <button mat-button class="bg-primary-aden text-white close-btn" (click)="onClose()">{{data.action}}</button>
  </div>
  <div *ngIf="data.dontShow"class="dont-show-again">
    <mat-checkbox  class="example-margin"
    (change)="changeBox($event.checked)">
    No volver a mostrar
    </mat-checkbox>
  </div>
</div>
