import { DataService } from 'src/app/shared/services/data.service';
import { Component, OnInit } from '@angular/core';
import { ContestService } from 'src/app/shared/services/contest.service';
import {
  ContestMember,
  ContestTeam,
} from 'src/app/shared/interfaces/interfaces';

@Component({
  selector: 'app-adenu-internacional',
  templateUrl: './adenu-internacional.component.html',
  styleUrls: ['./adenu-internacional.component.scss'],
})
export class AdenuInternacionalComponent implements OnInit {
  capitan: ContestMember;
  hasTeam: boolean;
  alumnos: ContestMember[] = [];
  userTeam: ContestTeam;

  constructor(
    private dataService: DataService,
    private contestService: ContestService
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this.dataService.getAlumno().subscribe(
      (res) => {
        this.capitan = {
          id: res.legajo,
          name: res.apellido,
          mail: res.email,
          img: `data:image/png;base64,${res.foto64}`,
        };

        this.checkUser(res.legajo);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  checkUser(id): void {
    this.contestService.checkUser(id).subscribe(
      (res) => {
        this.userTeam = res.equipaments;
        this.hasTeam = !res.avaliable;
      },
      (err) => {
        console.log('error');
      }
    );
  }
}
