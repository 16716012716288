export const types = {
  // Users
  GET_USER: '[GetUser] USER',
  POST_PHOTO_USER: '[PostUser] USER',
  // Countries
  GET_COUNTRIES: '[GetCountries] COUNTRIES',
  // Academic Advisor
  GET_ACADEMIC: '[GetAcademic] ACADEMIC',
  // GRADES
  GET_GRADES: '[GetGrades] GRADES',
};
