<div id="eventos">
  <app-header [title]="title"></app-header>

  <div class="container">
    <mat-tab-group
      mat-align-tabs="start"
      *ngIf="this.events"
      [selectedIndex]="selectedTab"
      class="main-radius main-shadow">
      <mat-tab label="Eventos próximos">
        <div
          class="spinner"
          *ngIf="
            (!this.nextEvents || this.nextEvents.length === 0) && isLoading
          "
        >
          <mat-spinner></mat-spinner>
        </div>
        <div class="content">
          <ng-container
            *ngIf="
              (!this.nextEvents || this.nextEvents.length === 0) && !isLoading
            "
            ><app-empty-state
              [icono]="'event'"
              [mensaje]="'Aún no hay eventos programados'"
            ></app-empty-state
          ></ng-container>
          <div class="cards-grid" *ngIf="this.nextEvents.length > 0">
            <ng-container *ngFor="let event of nextEvents">
              <app-card-evento
                [event]="event"
                [isPastEvent]="false"
              ></app-card-evento>
            </ng-container>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Eventos pasados">
        <div class="content">
          <ng-container
            *ngIf="
              (!this.pastEvents || this.pastEvents.length === 0) && isLoading
            "
          >
            <div class="spinner">
              <mat-spinner></mat-spinner>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              (!this.pastEvents || this.pastEvents.length === 0) && !isLoading
            "
          >
            <app-empty-state
              [icono]="'event'"
              [mensaje]="'No encontramos eventos pasados'"
            ></app-empty-state>
          </ng-container>
          <div class="cards-grid" *ngIf="this.pastEvents.length > 0">
            <ng-container *ngFor="let event of pastEvents">
              <app-card-evento
                [event]="event"
                [isPastEvent]="true"
              ></app-card-evento>
            </ng-container>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
