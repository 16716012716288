<div id="adenu-international">
  <app-header [title]="'Movilidad Internacional'"></app-header>
  <div class="container-bg">
    <div class="container">
      <app-formulario-docentes
        [makeContact]="openHelpDialog"
      ></app-formulario-docentes>
      <div class="action-button shake" (click)="openHelpDialog()">
        ¿Tienes dudas?
      </div>
    </div>
  </div>
</div>
