import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { DataService } from 'src/app/shared/services/data.service';
import * as reduxUser from '../../../../redux/actions/user.action';

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'app-cardprofile',
  templateUrl: './cardprofile.component.html',
  styleUrls: ['./cardprofile.component.scss'],
})
export class CardprofileComponent {
  @Input() user: any;

  public user$;

  constructor(public dialog: MatDialog, private store: Store<UserState>) {
    this.user$ = this.store.select('user');
  }

  showModalPhoto(): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '400px',
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ok') {
      }
    });
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ModalComponent',
  templateUrl: 'modal.component.html',
  styleUrls: ['./cardprofile.component.scss'],
})
export class ModalComponent {
  srcResult;
  selectedFile: ImageSnippet;

  constructor(
    private dataService: DataService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalComponent>,
    private _snackBar: MatSnackBar,
    private store: Store
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  onFileSelected(): void {
    const inputNode: any = document.querySelector('#file');

    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.srcResult = e.target.result;
      };

      reader.readAsArrayBuffer(inputNode.files[0]);
    }
  }

  changePhotoProfile(imageInput: any): void {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    const button = document.getElementById('save');
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png'
    ) {
      button.innerText = 'Guardando...';
      reader.addEventListener('load', (event: any) => {
        this.selectedFile = new ImageSnippet(event.target.result, file);

        this.dataService.postFoto(this.selectedFile.file).subscribe(
          (res) => {
            this.dataService.getDocente().subscribe((profesor) => {
              this.store.dispatch(
                new reduxUser.PostPhotoUser(
                  `data:image/png;base64,${profesor.foto64}`
                )
              );
              button.innerText = 'Guardar cambios';
              this.dataService.openAlert(
                'Foto actualizado',
                'Tu foto de perfil fue actualizada con éxito',
                'Aceptar',
                null,
                'success',
                'check_circle'
              );
              this.dialogRef.close();
            });
          },
          (err) => {
            this.dataService.openAlert(
              'No pudimos actualizar tu foto',
              'Ha ocurrido un error al actualizar la foto de perfil',
              'Aceptar',
              null,
              'error',
              'error'
            );
            button.innerText = 'Guardar cambios';
            console.log(err);
          }
        );
      });

      reader.readAsDataURL(file);
    } else {
      this._snackBar.open('El formato debe ser JPG, JPEG o PNG', 'Cerrar', {
        duration: 7000,
      });
    }
  }
}
