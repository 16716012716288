import { Criterio } from './../../../../../shared/interfaces/interfaces';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-criterio',
  templateUrl: './card-criterio.component.html',
  styleUrls: ['./card-criterio.component.scss'],
})
export class CardCriterioComponent {
  @Input() criterio: Criterio;

  constructor() {}
}
