import {
  PQRSCategory,
  PQRSCaseKind,
} from './../../../../shared/interfaces/interfaces';
import { startWith, map } from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-nuevo-ticket',
  templateUrl: './nuevo-ticket.component.html',
  styleUrls: ['./nuevo-ticket.component.scss'],
})
export class NuevoTicketComponent implements OnInit {
  public formulario: UntypedFormGroup;
  public options = null;
  public kindOfCase: PQRSCaseKind = null;
  public category = null;
  public loading = true;
  public error = false;
  public sending = false;

  public categoryControl = new UntypedFormControl('', Validators.required);

  filteredCategories: Observable<PQRSCategory[]>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private service: DataService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getSelects().then((res) => {
      this.filteredCategories = this.categoryControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) =>
          name !== ''
            ? this.filterCategories(name)
            : this.kindOfCase.categories.slice()
        )
      );
    });
  }

  async getSelects(): Promise<any> {
    this.loading = true;
    this.service.getPqrsOptions().subscribe(
      (res) => {
        this.options = res.response_data;
        this.kindOfCase = res.response_data.kind_of_cases[0];
        this.newForm();
        this.loading = false;
        this.error = false;
      },
      (err) => {
        console.log(err);
        this.loading = false;
        this.error = true;
      }
    );
  }

  private filterCategories(name: string): PQRSCategory[] {
    const filterValue = name.toLowerCase();

    return this.kindOfCase.categories.filter((category) =>
      category.name.toLowerCase().includes(filterValue)
    );
  }

  displayCategory(category): string {
    return category && category.name ? category.name : '';
  }

  newForm(): void {
    this.formulario = this.formBuilder.group({
      type: [this.kindOfCase, Validators.required],
      category: this.categoryControl,
      subject: ['', Validators.required],
      description: ['', [Validators.required]],
    });
  }

  normalizeDataForm(form): any {
    return {
      case: form.type,
      category: form.category,
      description: form.description,
      issue: form.subject,
    };
  }

  sendForm(): void {
    const form = this.normalizeDataForm(this.formulario.value);
    this.sending = true;
    this.service.setPqrs(form).subscribe(
      (res) => {
        this.dialog.open(DialogSuccess, {
          width: '450px',
          data: res.response_data,
        });
        this.sending = false;
        this.newForm();
      },
      (err) => {
        this.service.openAlert(
          'Algo salió mal',
          'No pudimos crear tu ticket, por favor intenta de nuevo más tarde',
          'Entendido'
        );
        this.sending = false;
      }
    );
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dialog-success',
  templateUrl: './dialog-success.component.html',
  styleUrls: ['./nuevo-ticket.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DialogSuccess {
  constructor(
    public dialogRef: MatDialogRef<DialogSuccess>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
