import { DetalleBloqueo } from '../../../interfaces/interfaces';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-alert',
  templateUrl: './block-alert.component.html',
  styleUrls: ['./block-alert.component.scss'],
})
export class BlockAlertComponent implements OnInit {
  @Input() estadoBloqueos: DetalleBloqueo[];
  @Input() tabOpen = false;
  @Input() alertType: string;

  constructor() {}

  ngOnInit(): void {
    if (this.tabOpen) {
      document.getElementById('alert').classList.add('active-tab');
      document.getElementById('details').classList.add('show-details');
      document.getElementById('backdrop').classList.add('backdrop-color');
      document.getElementById('alert').classList.remove('shake');
    } else {
      document.getElementById('alert').classList.remove('active-tab');
      document.getElementById('details').classList.remove('show-details');
      document.getElementById('backdrop').classList.remove('backdrop-color');
      document.getElementById('alert').classList.add('shake');
    }
  }

  activateTab(): void {
    this.tabOpen = !this.tabOpen;

    if (this.tabOpen) {
      document.getElementById('alert').classList.add('active-tab');
      document.getElementById('details').classList.add('show-details');
      document.getElementById('backdrop').classList.add('backdrop-color');
      document.getElementById('alert').classList.remove('shake');
    } else {
      document.getElementById('alert').classList.remove('active-tab');
      document.getElementById('details').classList.remove('show-details');
      document.getElementById('backdrop').classList.remove('backdrop-color');
      document.getElementById('alert').classList.add('shake');
    }
  }
}
