import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() public title: string;
  @Input() public description: string;
  @Input() public badge: string;
  @Input() public icon: string;
  @Input() public link: string;

  constructor(private router: Router) {}

  goTo() {
    if (this.link.includes('http') || this.link.includes('assets')) {
      window.open(this.link, '_blank');
    } else {
      this.router.navigate([this.link]);
    }
  }
}
