<div class="margin-bottom">
  <div class="container" *ngIf="isLoading">
    <div class="center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div class="container" *ngIf="!isLoading && noFormacion">
    <div class="center">
      <mat-icon class="material-icons-round">error</mat-icon>
      <div class="text">No pudimos encontrar tus datos académicos</div>
      <button mat-flat-button color="primary" (click)="getFormacion()">
        Reintentar
      </button>
    </div>
  </div>
  <div
    class="flex"
    *ngIf="!isLoading && areasEspecialidad && !noFormacion && areasEspecialidad.length > 0"
  >
    <form class="superior-main" [formGroup]="formacionForm">
      <div class="heading"><h2>Estudios de docencia superior</h2></div>
      <div class="superior-container">
  
        <div class="form-grid">
          <div class="superior-title">{{formacionAcademica.area_especialidad}}</div>
          <div [ngClass]="'chip ' + stateToClassMapping[formacionAcademica.estudios_docencia_superior]">
            {{formacionAcademica.estudios_docencia_superior}}
          </div>
    
        </div>
        <button
        mat-icon-button
        aria-label="Editar"
        (click)="openSuperior()"
      >
        <mat-icon class="material-icons-round">edit</mat-icon>
      </button>
      </div>
      <div class="add-card" (click)="openSuperior()">
        <mat-icon class="material-icons-round"> add </mat-icon>
        Añadir estudios
      </div>
    </form>
        <!-- <mat-form-field class="full-width" appearance="fill">
          <mat-label>Estado de estudios en docencia superior</mat-label>
          <mat-select formControlName="estudios_docencia_superior" required>
            <mat-option value="Culminado">Culminado</mat-option>
            <mat-option value="Cursando">Cursando</mat-option>
            <mat-option value="Por Iniciar">Por Iniciar</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              this.formacionForm
                .get('estudios_docencia_superior')
                .hasError('required')
            "
            >Este campo es requerido</mat-error
          >
        </mat-form-field>
        <mat-form-field *ngIf="areasEspecialidad" class="full-width" appearance="fill">
          <mat-label>Area de especialidad</mat-label>
          <mat-select
            required
            matNativeControl
            formControlName="area_especialidad"
            [compareWith]="compareValue"
          >
            <mat-option
              *ngFor="let area of areasEspecialidad"
              [value]="area.speciality_id"
            >
              {{ area.speciality_name }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              this.formacionForm.get('area_especialidad').hasError('required')
            "
            >Este campo es requerido</mat-error
          >
        </mat-form-field> -->
      <!-- <button
        mat-flat-button
        color="primary"
        id="save-button"
        (click)="guardarConfig()"
      >
        Guardar
      </button> -->
    <div>
      <div class="heading"><h2>Estudios</h2></div>
      <!-- <button mat-flat-button color="primary" (click)="openCreateDialog()">
      Añadir estudios
    </button> -->
      <div class="cards-grid">
        <ng-container *ngFor="let item of formacionAcademica.estudios">
          <div class="card-educacion main-shadow main-border main-radius">
            <div class="body">
              <h2>{{ item.titulo }}</h2>
              <h4>{{ item.universidad }}</h4>
              <div class="icon-line">
                <p>{{ item.pais }}</p>
              </div>
              <div class="icon-line">
                <iconify-icon class="material-icons-round icon" icon="ri:calendar-check-fill"></iconify-icon>
                <p>{{ item.ano_graduacion }}</p>
              </div>
            </div>
            <div class="footer">
              <button
                mat-icon-button
                aria-label="Editar"
                (click)="openAddEstudioDialog(item)"
              >
                <mat-icon class="material-icons-round">edit</mat-icon>
              </button>
              <button
                mat-icon-button
                aria-label="eliminar"
                (click)="openConfirmDialog(item, 'estudio')"
              >
                <mat-icon class="material-icons-round">delete</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="add-card" (click)="openAddEstudioDialog()">
        <mat-icon class="material-icons-round"> add </mat-icon>
        Añadir estudios
      </div>
    </div>

    <div>
      <div class="heading"><h2>Idiomas</h2></div>
      <!-- <button mat-flat-button color="primary" (click)="openCreateDialog()">
      Añadir idioma
    </button> -->
      <div class="cards-grid">
        <ng-container *ngFor="let item of formacionAcademica.idiomas">
          <div class="card-educacion main-shadow main-border main-radius">
            <div class="body">
              <h2>{{ item.idioma }}</h2>
            </div>
            <div class="footer">
              <!-- <button mat-icon-button aria-label="Editar">
                <mat-icon class="material-icons-round">edit</mat-icon>
              </button> -->
              <button
                mat-icon-button
                aria-label="eliminar"
                (click)="openConfirmDialog(item, 'idioma')"
              >
                <mat-icon class="material-icons-round">delete</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="add-card" (click)="openAddIdiomaDialog()">
        <mat-icon class="material-icons-round"> add </mat-icon> Añadir idioma
      </div>
    </div>
    <div>
      <div class="heading"><h2>Area de especialidad</h2></div>
      <div class="cards-grid">
        <ng-container *ngFor="let item of userSpecialities">
          <div class="card-educacion main-shadow main-border main-radius">
            <div class="body">
              <h2>{{ item.speciality_name }}</h2>
            </div>
            <div class="footer">
              <button
                mat-icon-button
                aria-label="eliminar"
                (click)="openConfirmDialog(item, 'area')"
              >
                <mat-icon class="material-icons-round">delete</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <button class="add-card" (click)="openAddAreaDialog()">
        <mat-icon class="material-icons-round"> add </mat-icon> Añadir area
      </button>
    </div>

    <div>
      <div class="heading"><h2>Certificados</h2></div>
      <div class="cards-grid">
        <ng-container *ngFor="let item of formacionAcademica.certificados">
          <div class="card-educacion main-shadow main-border main-radius">
            <div class="body">
              <h2>{{ item.title }}</h2>
              <h4>{{ item.entity }}</h4>
              <div class="icon-line">
                <p>{{ item.theme }}</p>
              </div>
              <div class="icon-line">
                <iconify-icon class="material-icons-round icon" icon="ri:calendar-check-fill"></iconify-icon>
                <p>{{ item.date | date :"dd-MM-YYYY" }}</p>
              </div>
            </div>
            <div class="footer">
              <button
                mat-icon-button
                aria-label="Editar"
                (click)="openAddCertificadoDialog(item)"
              >
                <mat-icon class="material-icons-round">edit</mat-icon>
              </button>
              <button
                mat-icon-button
                aria-label="eliminar"
                (click)="openConfirmDialog(item, 'certificado')"
              >
                <mat-icon class="material-icons-round">delete</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="add-card" (click)="openAddCertificadoDialog()">
        <mat-icon class="material-icons-round"> add </mat-icon>
        Añadir certificado
      </div>
    </div>
    <div class="contain-table main-shadow" *ngIf="certificatedHours.length">
      <div class="heading"><h2>Horas certificadas totales</h2></div>
      <table class="custom-table" *ngIf="!isLoading">
        <thead>
          <tr>
            <th>Año</th>
            <th>Certificado</th>
            <th>Horas Realizadas</th>
            <th>Horas Totales</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of certificatedHours; let i = index">
            <tr class="body">
              <td>{{ item.year }}</td>
              <td>{{ item.certificate_name }}</td>
              <td>{{ item.certificate_hours }}</td>
              <td>{{ item.total_hours }}</td>
            </tr>
            <tr *ngIf="i < certificatedHours.length - 1 && item.year !== certificatedHours[i + 1].year">
              <td colspan="4" class="year-separator"></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
