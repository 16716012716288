import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ceaden',
  templateUrl: './ceaden.component.html',
  styleUrls: ['./ceaden.component.scss'],
})
export class CeadenComponent {
  title = 'Centro de Emprendimiento ADEN University';

  links = [
    { name: 'Sobre nosotros', tag: 'sobre-nosotros' },
    { name: 'Programas', tag: 'programas' },
    { name: 'Alianzas', tag: 'alianzas' },
  ];
  activeLink = this.links[0];

  programs = [
    {
      name: 'Impulso',
      image: 'impulso-logo-fullcolor.png',
      description:
        'Es un programa creado para apoyar a los estudiantes de ADENU en el desarrollo, incubación y aceleración de sus emprendimientos',
      open: true,
      tag: 'programa-impulso',
      init: '2022-03-11T00:00:00',
      end: '2022-04-10T00:00:00',
    },
    {
      name: 'Reto Aprende y Emprende',
      image: 'aprende-emprende-logo.png',
      description:
        'Fue un programa destinado a formar alumnos de ADENU a través del trabajo en equipo y la gestión de emprendimientos reales',
      open: false,
      tag: 'reto-aprende-y-emprende',
      init: '',
      end: '',
    },
  ];

  constructor(private location: Location) {}

  back(): void {
    this.location.back();
  }
}
