<div>
  <div class="dialog-header">
    <div class="title">{{ pageTitle }}</div>
    <button
      class="close"
      mat-icon-button
      aria-label="Close"
      (click)="onClose()"
    >
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
  <div class="dialog-container" *ngIf="data.tipo === 'estudio'">
    <div mat-dialog-content class="mt-2">
      <form [formGroup]="estudiosForm" class="form-grid">
        <div class="form-grid">
          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Título obtenido</mat-label>
            <input matInput placeholder="" formControlName="titulo" required />
            <mat-error
              *ngIf="this.estudiosForm.get('titulo').hasError('required')"
              >Este campo es requerido</mat-error
            >
          </mat-form-field>

          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Universidad</mat-label>
            <input
              type="text"
              placeholder="Elige una"
              matInput
              [formControl]="universidadesCtrl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              [displayWith]="displayProperty"
            >
              <mat-option
                *ngFor="let option of filteredUnis | async"
                [value]="option"
              >
                {{ option.university_name }}
              </mat-option>
            </mat-autocomplete>
            
            <mat-error *ngIf="universidadesCtrl.errors?.notFound">
              Universidad no encontrada
            </mat-error>

            <mat-error *ngIf="this.universidadesCtrl.hasError('required')"
              >Este campo es requerido</mat-error
            >
          </mat-form-field>

          <mat-form-field class="full-width" appearance="fill">
            <mat-label>País</mat-label>
            <mat-select
              required
              matNativeControl
              formControlName="pais_id"
              [compareWith]="compareCountry"
            >
              <mat-option
                *ngFor="let country of data.paises"
                [value]="country.id"
              >
                {{ country.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="this.estudiosForm.get('pais_id').hasError('required')"
              >Este campo es requerido</mat-error
            >
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Año de graduación</mat-label>
            <input
              matInput
              [min]="moment('1950', 'YYYY')"
              [max]="moment()"
              [matDatepicker]="dp"
              formControlName="ano_graduacion"
            />
            <mat-hint>MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker
              #dp
              startView="multi-year"
              (yearSelected)="setYear($event, dp)"
              panelClass="example-month-picker"
            >
            </mat-datepicker>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div mat-dialog-actions>
      <div class="button-container">
        <button mat-stroked-button mat-dialog-close>Cerrar</button>
        <button
          mat-flat-button
          color="primary"
          id="create-estudio"
          [disabled]="loading"
          (click)="onSubmit()"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
  <div class="dialog-container" *ngIf="data.tipo === 'certificado'">
    <div mat-dialog-content class="mt-2">
      <form [formGroup]="certificadosForm" class="form-grid">
        <div class="form-grid">

          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Nombre del certificado</mat-label>
            <input matInput placeholder="" formControlName="title" required />
            <mat-error
              *ngIf="this.certificadosForm.get('title').hasError('required')"
              >Este campo es requerido</mat-error
            >
          </mat-form-field>

          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Tematica</mat-label>
            <input matInput placeholder="" formControlName="theme" required />
            <mat-error
              *ngIf="this.certificadosForm.get('theme').hasError('required')"
              >Este campo es requerido</mat-error
            >
          </mat-form-field>
          
          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Fecha</mat-label>
            <input matInput [matDatepicker]="dp" formControlName="date" placeholder="DD/MM/YYYY" required />
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Entidad emitida</mat-label>
            <input matInput placeholder="" formControlName="entity" required />
            <mat-error
              *ngIf="this.certificadosForm.get('entity').hasError('required')"
              >Este campo es requerido</mat-error
            >
          </mat-form-field>
          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Horas certificadas</mat-label>
            <input matInput placeholder="" type="number" formControlName="hours" />
          </mat-form-field>
          <div class="full-width file-contain" appearance="fill">
            <div type="button" class="file-btn" (click)="fileInput.click()">{{isFile ? 'Cambiar archivo' : 'Adjuntar archivo'}}</div>
            <input hidden (change)="onFileSelected()" accept="image/jpeg, image/png, application/pdf" #fileInput type="file" id="file">
            <div
              class="error-msg"
              *ngIf="this.certificadosForm.get('file').hasError('required')|| !this.certificadosForm.get('file').value"
              >Puedes adjuntar contenido en formato pdf o jpg.</div>
              <div class="container-info-file" *ngIf="isFile">
                <div class="file-info">{{
                  this.certificadosForm.get('file').value.file_name || this.certificadosForm.get('file').value.name}} 
                  ({{transformBytes(this.certificadosForm.get('file').value.file_size)  || 
                  transformBytes(this.certificadosForm.get('file').value.size)}} K)</div>
              </div>
              <!-- <mat-icon style="color: #6DDE71;" *ngIf="isFile">file_copy</mat-icon> -->

          </div>
          
        </div>
      </form>
    </div>
    <div mat-dialog-actions>
      <div class="button-container">
        <button mat-stroked-button mat-dialog-close>Cerrar</button>
        <button
          mat-flat-button
          color="primary"
          id="create-certificado"
          [disabled]="loading"
          (click)="onSubmit()"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
  <div class="dialog-container" *ngIf="data.tipo === 'idioma'">
    <div mat-dialog-content class="mt-2">
      <form class="form-grid">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Selecciona un idioma</mat-label>
          <mat-select
            required
            matNativeControl
            [formControl]="idiomaCtrl"
            [compareWith]="compareCountry"
          >
            <mat-option
              *ngFor="let idioma of data.idiomas"
              [value]="idioma.idioma_id"
            >
              {{ idioma.name }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="this.idiomaCtrl.hasError('required')"
            >Este campo es requerido</mat-error
          >
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions>
      <div class="button-container">
        <button mat-stroked-button mat-dialog-close>Cerrar</button>
        <button
          mat-flat-button
          color="primary"
          id="create-estudio"
          [disabled]="loading || this.idiomaCtrl.value === 0"
          (click)="postIdioma()"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
  <div class="dialog-container" *ngIf="data.tipo === 'area'">
    <div mat-dialog-content class="mt-2">
      <form class="form-grid">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Selecciona una especialidad</mat-label>
          <mat-select
            required
            [formControl]="areaCtrl"
            multiple
          >
            <mat-option 
            *ngFor="let area of data.areas" 
            [value]="area.speciality_id"
            [disabled]="areaCtrl.value?.length >= 1 && !areaCtrl.value?.includes(area.speciality_id)"
            >
              {{ area.speciality_name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="areaCtrl.hasError('minMaxSelected')">
            Debes seleccionar entre al menos un area.
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions>
      <div class="button-container">
        <button mat-stroked-button mat-dialog-close>Cerrar</button>
        <button
          mat-flat-button
          color="primary"
          id="create-estudio"
          [disabled]="loading || areaCtrl.invalid"
          (click)="postArea()"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
  <div class="dialog-container" *ngIf="data.tipo === 'superior'">
    <div mat-dialog-content class="mt-2">
      <form class="form-grid" [formGroup]="superiorForm">
       <mat-form-field class="full-width" appearance="fill">
          <mat-label>Estado de estudios en docencia superior</mat-label>
          <mat-select formControlName="estudios_docencia_superior" required>
            <mat-option *ngFor="let state of data.states | keyvalue" [value]="state.key">{{state.key}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              this.superiorForm
                .get('estudios_docencia_superior')
                .hasError('required')
            "
            >Este campo es requerido</mat-error
          >
        </mat-form-field>
        <mat-form-field *ngIf="data.areasEspecialidad" class="full-width" appearance="fill">
          <mat-label>Area de especialidad</mat-label>
          <mat-select
            required
            matNativeControl
            formControlName="area_especialidad"
          >
            <mat-option
              *ngFor="let area of data.areasEspecialidad"
              [value]="area.speciality_id"
            >
              {{ area.speciality_name }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              this.superiorForm.get('area_especialidad').hasError('required')
            "
            >Este campo es requerido</mat-error
          >
        </mat-form-field>
      <!-- <button
        mat-flat-button
        color="primary"
        id="save-button"
        (click)="guardarConfig()"
      >
        Guardar
      </button> -->
      </form>
    </div>
    <div mat-dialog-actions>
      <div class="button-container">
        <button mat-stroked-button mat-dialog-close>Cerrar</button>
        <button
          mat-flat-button
          color="primary"
          id="save-button"
          [disabled]="this.superiorForm.hasError('required')"
          (click)="guardarConfig()"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>
