import { Component } from '@angular/core';

@Component({
  selector: 'app-cronograma-internacional',
  templateUrl: './cronograma-internacional.component.html',
  styleUrls: ['./cronograma-internacional.component.scss'],
})
export class CronogramaInternacionalComponent {
  public cronograma = [
    {
      id: 1,
      date: '1 DIC - 15 FEB',
      descripcion: '¡Postulaciones abiertas!',
    },
    {
      id: 2,
      date: '15 FEB - 11 MAR',
      descripcion: 'Análisis y selección de alumnos',
    },
    {
      id: 3,
      date: '14 MAR',
      descripcion: 'Comunicación de alumnos seleccionados',
    },
    {
      id: 4,
      date: '16 MAR',
      descripcion: 'Entrevista a alumnos seleccionados',
    },
    {
      id: 5,
      date: '1 ABR',
      descripcion: 'Inicio de postulación a universidades extranjeras',
    },
  ];

  constructor() {}
}
