import { DataService } from 'src/app/shared/services/data.service';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public notifications = [];
  public error = false;
  public loading = false;
  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.getNotifications();
  }

  getNotifications() {
    this.loading = true;
    this.dataService.getNotifications().subscribe({
      next: (res) => {
        this.notifications = res.notificaciones.sort(
          (a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()
        );
      },
      error: (err) => {
        this.error = true;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  updateNotifications($event: any){
    $event.stopPropagation();
    this.getNotifications();
  }

  getUnseenNotifications() {
    return this.notifications.filter((item) => !item.is_open).length;
  }

}
