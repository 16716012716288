import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-eventos-banner',
  templateUrl: './eventos-banner.component.html',
  styleUrls: ['./eventos-banner.component.scss'],
})
export class EventosBannerComponent implements OnInit {
  @Input() hasChip: boolean;
  @Input() chipTitle: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() link: string;
  @Input() type: string;

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.type === 'rematricula') {
      document.getElementById('banner').classList.remove('default');
      document.getElementById('banner').classList.add('green');
    }
  }

  goTo() {
    if (this.link.includes('http') || this.link.includes('assets')) {
      window.open(this.link, '_blank');
    } else {
      this.router.navigate([this.link]);
    }
  }
}
