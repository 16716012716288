<div class="dialog-container">
  <div class="button-container">
    <button
      class="close"
      mat-icon-button
      aria-label="Close"
      (click)="onClose()"
    >
      <mat-icon class="material-icons-round"> close </mat-icon>
    </button>
  </div>

  <img src="../../../../../assets/images/content/Success.svg" alt="success" />
  <h3>Tu numero de ticket es:</h3>
  <h1>{{ data }}</h1>
  <h3>
    El número será enviado a tu correo electrónico, por favor guárdalo y no lo
    pierdas.
  </h3>
</div>
