import { UntypedFormControl } from '@angular/forms';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { Observable } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  ViewChild,
  AfterViewInit,
  Input,
  OnInit,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DataService } from 'src/app/shared/services/data.service';
import { GradesState } from 'src/app/redux/reducers/grades.reducer';
import { Store } from '@ngrx/store';
import { statusMappings } from 'src/app/shared/data/notas.data';

@Component({
  selector: 'app-notas-table',
  templateUrl: './notas-table.component.html',
  styleUrls: ['./notas-table.component.scss'],
})
export class NotasTableComponent implements OnInit, AfterViewInit {
  @Input() notes;
  public dataSource;
  public allNotes: number;
  public searchValue = '';

  public tableColumns = [
    {
      columnDef: 'exam_name',
      header: 'Nombre de materia',
      cell: (note: any) => `${note.exam_name}`,
    },
    {
      columnDef: 'evaluation_type',
      header: 'Grado',
      cell: (note: any) =>
        `${
          note.mark >= 91 && note.mark <= 100
            ? 'A'
            : note.mark >= 81 && note.mark <= 90.99
            ? 'B'
            : note.mark >= 71 && note.mark <= 80.99
            ? 'C'
            : note.mark >= 61 && note.mark <= 70.99
            ? 'D'
            : note.mark >= 0 &&
              note.mark <= 60.99 &&
              note.line_status === 'fail' &&
              note.student_status !== 'preconv_fallida'
            ? 'F'
            : 'Sin datos'
        }`,
    },
    {
      columnDef: 'line_status',
      header: 'Estado',
      cell: (note: any) =>
        `${
          note.line_status === 'pass' && note.student_status !== 'preconv_fallida'
            ? 'Aprobado'
            : note.line_status === 'fail' &&
              note.student_status !== 'preconv_fallida'
            ? 'Reprobado'
            : 'Sin datos'
        }`,
    },
    {
      columnDef: 'student_status',
      header: 'Estatus estudiante',
      cell: (note: any) => statusMappings[note.student_status] || statusMappings['default']
    },
    {
      columnDef: 'mark',
      header: 'Nota',
      cell: (note: any) =>
        note.student_status !== 'preconv_fallida'
          ? `${note.mark}`
          : 'Sin datos',
    },
  ];

  public columnsToDisplay = this.tableColumns.map((c) => c.columnDef);

  isLoading = false;
  isError = false;

  selected = new UntypedFormControl(0);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private cdref: ChangeDetectorRef, private service: DataService) {}

  ngOnInit(): void {
    this.renderTable(this.notes);
    this.allNotes = this.notes.length;
  }

  ngAfterViewInit(): void {
    this.cdref.detectChanges();
  }

  renderTable(notes): void {
    this.dataSource = new MatTableDataSource<any>(notes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search(e): void {
    const filterValue = (e.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cleanSearch(): void {
    this.searchValue = '';
    this.dataSource.filter = this.searchValue.trim().toLowerCase();
  }
}
