<div id="adenu-international">
  <div class="heading">
    <div class="title">¿Tienes dudas?</div>
    <button class="close" mat-icon-button aria-label="Close" mat-dialog-close>
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="description">
      Envíanos tu consulta y en breve será respondida a tu correo electrónico
    </div>

    <form class="form-contact">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Nombre completo</mat-label>
        <input
          matInput
          placeholder="Jon Doe"
          [formControl]="nameCtrl"
          [errorStateMatcher]="matcher"
          required
        />
        <mat-error *ngIf="this.nameCtrl.hasError('required')"
          >Este campo es requerido</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Email</mat-label>
        <input
          matInput
          placeholder="example@example.com"
          [formControl]="emailCtrl"
          [errorStateMatcher]="matcher"
          required
        />
        <mat-error *ngIf="this.emailCtrl.hasError('email')"
          >Ingresa una dirección de correo electrónico válida</mat-error
        >
        <mat-error *ngIf="this.emailCtrl.hasError('required')"
          >Este campo es requerido</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Asunto</mat-label>
        <input
          matInput
          placeholder="Duda sobre la documentación requerida"
          [formControl]="subjectCtrl"
          [errorStateMatcher]="matcher"
          required
        />
        <mat-error *ngIf="this.subjectCtrl.hasError('required')"
          >Este campo es requerido</mat-error
        >
      </mat-form-field>
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Escribe tu consulta</mat-label>
        <textarea
          matInput
          #message
          placeholder="Mi consulta es.."
          maxlength="800"
          [formControl]="messageCtrl"
          [errorStateMatcher]="matcher"
          required
        ></textarea>
        <mat-error *ngIf="messageCtrl.hasError('required')">
          Este campo es <strong>obligatorio</strong>
        </mat-error>
        <mat-hint align="end">{{ message.value?.length || 0 }}/800 </mat-hint>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions>
    <button
      mat-flat-button
      [disabled]="messageCtrl.hasError('required')"
      id="send-button"
      class="custom-button"
      color="accent"
      (click)="sendMessage()"
    >
      Enviar
    </button>
  </div>
</div>
