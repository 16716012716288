import { ContestService } from './../../../shared/services/contest.service';
import {
  ContestMember,
  ContestTeam,
  ContestTutor,
} from './../../../shared/interfaces/interfaces';
import { DataService } from 'src/app/shared/services/data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-concurso',
  templateUrl: './concurso.component.html',
  styleUrls: ['./concurso.component.scss'],
})
export class ConcursoComponent implements OnInit {
  capitan: ContestMember;
  hasTeam: boolean;
  alumnos: ContestMember[] = [];
  userTeam: ContestTeam;

  constructor(
    private dataService: DataService,
    private contestService: ContestService
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this.dataService.getAlumno().subscribe(
      (res) => {
        this.capitan = {
          id: res.legajo,
          name: res.apellido,
          mail: res.email,
          img: `data:image/png;base64,${res.foto64}`,
        };

        this.checkUser(res.legajo);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  checkUser(id): void {
    this.contestService.checkUser(id).subscribe(
      (res) => {
        this.userTeam = res.equipaments;
        this.hasTeam = !res.avaliable;

        localStorage.setItem('equipo', JSON.stringify(res.equipaments));
        if (res.equipaments.assigned_tutor_id !== undefined) {
          const assignedTutor: ContestTutor = res.equipaments.tutors.find(
            (tutor) => tutor.id === res.equipaments.assigned_tutor_id
          );
          localStorage.setItem('assignedTutor', JSON.stringify(assignedTutor));
        }
      },
      (err) => {
        console.log('error');
      }
    );
  }
}
