import { ContestMember } from './../../../../../shared/interfaces/interfaces';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-miembro',
  templateUrl: './card-miembro.component.html',
  styleUrls: ['./card-miembro.component.scss'],
})
export class CardMiembroComponent implements OnInit {
  @Input() isCapitan: string;
  @Input() miembro: ContestMember;
  @Input() photo: string;
  @Input() isTeam: boolean;
  @Input() selectedAlumnos: ContestMember[];
  @Input() removeMember: (args: any) => void;

  img: string;

  constructor() {}

  ngOnInit(): void {
    this.img = this.miembro.img ? this.miembro.img : this.photo;
  }
}
