<div class="container-fluid header-bg">
  <div class="container">
    <!-- <div class="row">
            <div class="col-12">
                <button mat-button class="back-button" (click)="back()">&lt; Volver atrás</button>
                <h1>{{title}}</h1>
            </div>
        </div> -->
    <div class="body">
      <button mat-icon-button (click)="back()">
        <mat-icon class="material-icons-round">arrow_back</mat-icon>
      </button>
      <div class="text">{{ title }}</div>
    </div>
  </div>
</div>
