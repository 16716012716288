import { universities } from './../../shared/data/universities';
import { UserDoc } from './../../shared/interfaces/interfaces';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import * as reduxUser from '../../redux/actions/user.action';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
  title = 'Mi perfil';
  docsList = [];
  docsGroupByArea = [];
  userSpecialities  = [];
  allSpecialities = [];
  isLoading = true;
  noDocs = false;

  selectedTab = new UntypedFormControl(0);
  panelOpenState = true;

  public user$;
  public user;

  public countries;
  public universidades;
  public areasEspecialidad;
  public idiomas;

  docIdentifyer = (index:number, item: any) => item.key;

  constructor(
    private dataService: DataService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private store: Store<UserState>
  ) {}

  ngOnInit() {
    this.changeDocState = this.changeDocState.bind(this);
    this.getUser();
    this.getUniversidades();
    this.getCountries();
    this.getAllSpecialties();
    this.getUserSpecialties();
    this.getAerasEspecialidad();
    this.getIdiomas();
    this.activeRoute.queryParams.subscribe((params) => {
      if (params.page === 'docs') {
        this.selectedTab.setValue(2);
      } else if (params.page === 'academics') {
        this.selectedTab.setValue(1);
      } else {
        this.selectedTab.setValue(0);
      }
    });
  }
  getUserSpecialties(){
    this.dataService.getUserSpecialties().subscribe({
      next: (data) => {
        this.userSpecialities = data.speciality_areas;
      },
      error:(err) =>{
        console.log(err);
      }
    })
  }
  getAllSpecialties(){
    this.dataService.getAllSpecialties().subscribe({
      next: (data) => {
        this.allSpecialities = data.specialities;
      },
      error:(err) =>{
        console.log(err);
      }
    })
  }
  getUser() {
    this.dataService.getDocente().subscribe(
      (res) => {
        if (
          (res.nombre === '' || res.nombre === null) &&
          (res.correo_academico === '' ||
            !res.correo_academico ||
            res.correo_academico === undefined)
        ) {
          this.dataService.openAlert(
            '¡Ups!',
            'Estamos teniendo problemas con nuestro servidor, por favor intenta más tarde',
            'Ok'
          );
          setTimeout(() => {
            this.dataService.clearSis_id();
            this.dataService.clearToken();
          }, 5000);
        }
        this.user = {
          ...res,
          foto64: `data:image/png;base64,${res.foto64}`,
          sis_id: localStorage.getItem('sis_id'),
        };
        this.getDocs();
      },
      (err) => {
        this.dataService.openAlert(
          '¡Ups!',
          'Estamos teniendo problemas con nuestro servidor, por favor vuelve a iniciar sesión',
          'Ok'
        );
        setTimeout(() => {
          this.dataService.clearSis_id();
          this.dataService.clearToken();
          window.location.reload();
        }, 5000);
      }
    );
  }

  getDocs() {
    this.isLoading = true;
    this.noDocs = false;
    this.dataService.getDocuments().subscribe(
      (data) => {
        if (
          (data.documentos &&
            data.documentos.length <= 0) ||
          data.documentos.error
        ) {
          this.noDocs = true;
        } else {
          this.docsList = data.documentos;
          // CHECK AREA
          this.docsList = this.docsList.filter(
            (doc) => doc.area !== '' && doc.area !== null
          );

          // CHECK MODALIDAD

          this.docsList = this.docsList.filter(
            (doc) =>
              doc.modalidad &&
              (doc.modalidad === this.user.modalidad || doc.modalidad === 'b')
          );

          // CHECK DOCENTE EXTRANJERO

          if (!this.user.docente_extranjero) {
            this.docsList = this.docsList.filter(
              (doc) => !doc.solo_extranjeros
            );
          }

          // AGRUPACIÓN POR ÁREA
          this.docsGroupByArea = this.docsList.reduce((group, doc) => {
            const { area } = doc;
            group[area] = group[area] ?? [];
            group[area].push(doc);
            return group;
          }, {});
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.noDocs = true;
      }
    );
  }

  changeDocState(docId, state) {
    this.docsList.map((item) => {
      if (item.category_id === docId) {
        item.estado = state;
      }
      return item;
    });
    this.docsGroupByArea = this.docsList.reduce((group, doc) => {
      const { area } = doc;
      group[area] = group[area] ?? [];
      group[area].push(doc);
      return group;
    }, {});
  }

  getPorcentaje(item) {
    return (
      Math.round((item.filter((i) => i.estado ?  i.estado.toLowerCase() === 'aprobado' : '').length /
        item.length) *
      100)
    );
  }

  getCountries(): void {
    this.dataService.getPaises().subscribe((data) => {
      this.countries = data;
    });
  }

  getUniversidades() {
    this.dataService.getUniversidades().subscribe((res) => {
      this.universidades = res.universities;
    });
  }

  getAerasEspecialidad() {
    this.dataService.getAreasEspecialidad().subscribe((res) => {
      this.areasEspecialidad = res.specialities;
    });
  }

  getIdiomas() {
    this.dataService.getIdiomas().subscribe((res) => {
      this.idiomas = res.idiomas;
    });
  }

  goTo(tag) {
    this.router.navigate(['/perfil'], { queryParams: { page: tag } });
  }
}
