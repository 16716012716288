import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as debounce from 'lodash'
import {
  EstadoFinanciero,
  Factura,
  Categories
} from './../../shared/interfaces/interfaces';
import { Observable } from 'rxjs';
import { UserState } from './../../redux/reducers/user.reducer';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { DataService } from 'src/app/shared/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { MoreInfoDialogComponent } from './components/more-info-dialog/more-info-dialog.component';
import { AnyRecord } from 'dns';
@Component({
  selector: 'app-estado-financiero',
  templateUrl: './estado-financiero.component.html',
  styleUrls: ['./estado-financiero.component.scss'],
})
export class EstadoFinancieroComponent implements OnInit {
  title = 'Gestión de pagos';

  public dataSource;
  public searchValue = '';

  public user$: Observable<any>;
  apiRes: any;

  public estadoFinanciero = [];
  payOrders = [];

  public tableColumns = [
    {
      columnDef: 'type_id',
      header: 'Orden',
      cell: (pago: any) => pago.name,
    },
    {
      columnDef: 'partner_ref',
      header: 'Referencia',
      cell: (pago: any) => pago.ref ? pago.ref : '-',
    },
    {
      columnDef: 'date_order',
      header: 'Fecha Aviso',
      cell: (pago: any) => pago.date_order,
    },
    {
      columnDef: 'date_e_payment',
      header: 'Fecha Pago',
      cell: (pago: any) => pago.payment_date ? pago.payment_date : '-',
    },
    {
      columnDef: 'state',
      header: 'Estado',
      cell: (pago: any) => pago.state,
    },
    {
      columnDef: 'amountNew',
      header: 'Monto',
      cell: (pago: any) =>
        `${pago.currency} ${
          pago.amount 
        } `,
    },
  ];
  

  public categories: Categories[] = [
    {value: 'all', viewValue: 'Todos'},
    {value: 'draft', viewValue: 'A autorizar'},
    {value: 'purchase', viewValue: 'A cobrar'},
    {value: 'cancel', viewValue: 'Cancelado'},
    {value: 'paid out'||'paid_out', viewValue: 'Pagado'},
    {value: 'check', viewValue: 'Revisado por compras'},
    {value: 'sent', viewValue: 'Peticion de cotizacion enviada'},
    {value: 'confirmed', viewValue: 'Confirmado'},
  ];

  public secondTable

  public columnsToDisplay = this.tableColumns.map((c) => c.columnDef);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  /* CARDS STATUS */

  public pagosAutorizados = 0;
  public pagosPorAutorizar = 0;
  public saldoDolar = 0.0;
  public saldoLocal = 0.0;

  isLoading = false;
  isError = false;

  constructor(private service: DataService, private dialog: MatDialog) {}

  ngOnInit(): void {
    /* document.getElementById('estado-financiero').classList.add('blur'); */
    /* this.getEstadoFinanciero(); */
    this.getPagos();
    this.service.getPayOrders().subscribe(res => {
      this.payOrders = res.response_data;
    })
  }

  renderTable(datos): void {
    this.dataSource = new MatTableDataSource<any>(datos);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search(e): void {
    const filterValue = (e.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cleanSearch(): void {
    this.searchValue = '';
    this.dataSource.filter = this.searchValue.trim().toLowerCase();
  }

  setCardsValues(): void {
    this.estadoFinanciero.forEach((order) => {
      switch (order.state) {
        case 'A cobrar' || 'purchase':
          if (order.currency_id === 'USD') {
            this.saldoDolar = this.saldoDolar + order.amount_totalD;
          } else {
            this.saldoLocal = this.saldoLocal + order.amount_total;
          }
          this.pagosAutorizados++;
          break;
        case 'A autorizar' || 'draft':
          this.pagosPorAutorizar++;
          break;
        default:
          break;
      }
    });
  }
  optionChange(event: any){
    if(event.value != 'all'){
      let filterData = debounce.filter(this.apiRes, (item) =>{
        return item.state.toLowerCase() === event.value.toLowerCase() 
      })
      this.renderTable(filterData);
    }else{
      this.renderTable(this.apiRes)
    }
  }
  getPagos(): void {
    this.isLoading = true;
    this.isError = false;
    this.service.getPagos().subscribe(
      (res) => {
        this.isLoading = false;
        this.estadoFinanciero = res.response_data;
        this.setCardsValues();
        this.apiRes = res.response_data;
        this.renderTable(this.estadoFinanciero);
      },
      (error) => {
        this.isLoading = false;
        this.isError = true;
        console.log(error);
      }
    );
  }

  openMoreInfo(pago): void {
    const dialogRef = this.dialog.open(MoreInfoDialogComponent, {
      minWidth: '50vw',
      autoFocus: false,
      maxHeight: '90vh',
      data: {
        title: 'Detalles de la orden',
        pago: pago,
      },
      panelClass: 'generic-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
