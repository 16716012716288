<div
  class="card-container"
  [ngStyle]="{
    'background-color': color
  }"
>
  <div class="icon-container">
    <mat-icon class="material-icons-round">{{ icon }}</mat-icon>
  </div>
  <div class="card-body icon">
    <div class="content">
      <h3>
        {{ label }}
      </h3>
      <p class="value">{{ value }}</p>
      <!-- <p *ngIf="description">{{ description }}</p> -->
    </div>
  </div>
</div>
