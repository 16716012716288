import { ShareDialogComponent } from '../../../../shared/components/dialogs/share-dialog/share-dialog.component';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { Evento } from 'src/app/shared/interfaces/interfaces';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-card-evento',
  templateUrl: './card-evento.component.html',
  styleUrls: ['./card-evento.component.scss'],
})
export class CardEventoComponent implements OnInit {
  @Input() event: Evento;
  @Input() isPastEvent: boolean;

  public buttonValue: string;
  public moment = moment;

  public eventDateBegin;
  public eventDateEnd;
  public utc;

  public formatEventDateBegin;

  public formatEventDateEnd;

  public currentDate;
  public formatCurrentDate;

  links = [];

  constructor(private dialog: MatDialog, private sanitizer: DomSanitizer) {
    moment.updateLocale('es', {
      months:
        'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
          '_'
        ),
      monthsShort:
        'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
      weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split(
        '_'
      ),
      weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
      weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
    });
  }

  ngOnInit(): void {
    this.utc = this.getLocalUTC();
    this.eventDateBegin = new Date(
      this.event.date_begin.replaceAll('-', '/') + `${this.utc} UTC`
    );
    this.eventDateEnd = new Date(
      this.event.date_end.replaceAll('-', '/') + `${this.utc} UTC`
    );

    this.formatEventDateBegin = moment(this.eventDateBegin)
      .locale('es')
      .format('LL');

    this.formatEventDateEnd = moment(this.eventDateEnd)
      .locale('es')
      .format('LL');

    this.currentDate = new Date();

    this.formatCurrentDate = moment(this.currentDate).locale('es').format('LL');

    this.defineButton();
  }

  getLocalUTC(): string {
    const hours = new Date().getTimezoneOffset();
    if (hours > 0) {
      return `-${hours / 60}`;
    } else {
      return `+${(hours * -1) / 60}`;
    }
  }

  parseMonth(month): string {
    const FINAL_MONTH = {
      ENE: 'Enero',
      FEB: 'Febrero',
      MAR: 'Marzo',
      ABI: 'Abril',
      MAY: 'Mayo',
      JUN: 'Junio',
      JUL: 'Julio',
      AGO: 'Agosto',
      SEP: 'Septiembre',
      OCT: 'Octubre',
      NOV: 'Noviembre',
      DIC: 'Diciembre',
    };
    return FINAL_MONTH[month] || month;
  }

  getValidDate(): string {
    if (isNaN(this.eventDateBegin.getTime())) {
      return this.event.day + ' de ' + this.parseMonth(this.event.month);
    } else {
      return this.formatEventDateEnd !== this.formatEventDateBegin
        ? `${this.formatEventDateBegin} - ${this.formatEventDateEnd}`
        : this.formatEventDateBegin;
    }
  }

  getHourNumber(hour): number {
    return parseInt(hour.substring(0, 2), 10);
  }

  getMinuteNumber(hour): string {
    return hour.substring(3, 5);
  }

  defineButton(): void {
    this.event.is_registered &&
    (this.eventDateBegin >= this.currentDate ||
      this.formatEventDateBegin === this.formatCurrentDate)
      ? (this.buttonValue = 'Ingresar al evento A')
      : this.event.registration_url !== '' &&
        this.event.registration_url != null &&
        !this.event.is_registered &&
        (this.event.webinar_url !== '' || this.event.webinar_url === '') &&
        this.eventDateBegin >= this.currentDate
      ? (this.buttonValue = 'Inscribirme')
      : this.event.registration_url === '' &&
        this.event.webinar_url !== '' &&
        (this.eventDateBegin >= this.currentDate ||
          this.formatEventDateBegin === this.formatCurrentDate) &&
        !this.event.is_registered
      ? (this.buttonValue = 'Ingresar al evento B')
      : this.event.webinar_url !== '' &&
        ((this.eventDateBegin < this.currentDate &&
          this.eventDateEnd < this.currentDate) ||
          this.formatEventDateBegin === this.formatCurrentDate)
      ? (this.buttonValue = 'Volver a ver')
      : this.event.webinar_url !== '' &&
        ((this.eventDateBegin < this.currentDate &&
          this.eventDateEnd >= this.currentDate) ||
          this.formatEventDateBegin === this.formatCurrentDate)
      ? (this.buttonValue = 'Ingresar al evento C')
      : // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        null;
  }

  openShare(link, tipo): void {
    const dialogRef = this.dialog.open(ShareDialogComponent, {
      width: '400px',
      maxWidth: '90vw',
      data: {
        title: 'Compartir',
        link,
        tipo,
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  getEvent() {
    const eventCalendar = {
      title: `${this.event.event_type ? this.event.event_type + ' | ' : ''}${
        this.event.publication_name
      } - ADENU Panamá`,
      description: '',
      start: this.eventDateBegin.toISOString(),
      end: this.eventDateEnd.toISOString(),
      url: 'https://campus.adenu.edu.pa/eventos',
    };

    this.links = [
      {
        name: 'Google Calendar',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(
          google(eventCalendar)
        ),
      },
      {
        name: 'Outlook',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(
          outlook(eventCalendar)
        ),
      },
      {
        name: 'Office 365',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(
          office365(eventCalendar)
        ),
      },
      {
        name: 'Yahoo',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(
          yahoo(eventCalendar)
        ),
      },
      {
        name: 'iCal',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(ics(eventCalendar)),
      },
    ];
  }
}
