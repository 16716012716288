import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private dataService: DataService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.dataService.isAuthorizated()) {
      return true;
    } else {
      localStorage.setItem('origin', state.url);
      console.log('Access denied');
      this.dataService.clearToken();
      this.dataService.clearSis_id();
      // this.dataService.clearExp();
      this.router.navigateByUrl('login');
      return false;
    }
  }
}
