import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  description: string;
  action: string;
  img?: string;
  value: number;
  onCloseFunction: any;
  type?: string;
  icon?: string;
  dontShow?: boolean;
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  dontShowAgain = false;
  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onClose(): void {
    if (this.data.onCloseFunction != null) {
      this.data.onCloseFunction();
    }
    localStorage.setItem('referralAlert', this.dontShowAgain.toString())
    this.dialogRef.close();
  }

  changeBox(checked){
    this.dontShowAgain = checked;
  }
}
