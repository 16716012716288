<div class="dialog-header">
  <div mat-dialog-title>
    <h1>Detalles del pago</h1>
    <button
      class="close"
      mat-icon-button
      aria-label="Close"
      (click)="onClose()"
    >
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content class="dialog-container">
  <mat-tab-group>
    <mat-tab label="Info Adicional">
      <div class="info-container">
        <ul>
          <li>Nº de orden: {{ data.pago.name }}</li>
          <li>Fecha de creación {{ data.pago.date_order }}</li>
          <li>Comentario adicional:</li>
        </ul>
        <div class="notas" [innerText]="data.pago.notes"></div>
      </div>
    </mat-tab>
    <mat-tab label="Alumnos">
      <div class="alumnos-table main-radius main-shadow main-border">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          #pagosSort="matSort"
          class="mat-elevation-z0"
        >
          <ng-container
            [matColumnDef]="column.columnDef"
            [ngSwitch]="true"
            *ngFor="let column of tableColumns"
          >
            <ng-container *ngSwitchDefault>
              <th mat-header-cell mat-sort-header *matHeaderCellDef>
                {{ column.header }}
              </th>
              <td mat-cell *matCellDef="let row">
                <div *ngIf="column.cell(row) !== 'Sin datos'; else noData">
                  {{ column.cell(row) }}
                </div>
                <ng-template #noData>
                  <div class="no-data">
                    {{ column.cell(row) }}
                  </div>
                </ng-template>
              </td>
            </ng-container>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: columnsToDisplay"
            class="example-element-row"
          ></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="7">
              <div class="no-result" *ngIf="value.value !== ''">
                <mat-icon class="material-icons-round">search</mat-icon>
                <div class="text">No encontramos resultados para</div>
                <div class="searched-value">"{{ value.value }}"</div>
              </div>
              <div class="no-result" *ngIf="notes === []">
                <mat-icon class="material-icons-round">search</mat-icon>
                <div class="text">
                  Aún no tienes notas cargadas en el sistema
                </div>
              </div>
            </td>
          </tr>
        </table>

        <div class="mat-row" *ngIf="error && !loading">
          <div class="mat-cell" colspan="7">
            <div class="no-result">
              <mat-icon class="material-icons-round">search</mat-icon>
              <div class="searched-value">
                Ups.. no encontramos a tus alumnos.
              </div>
              <div class="text">¡Intenta de nuevo más tarde!</div>
              <button mat-flat-button color="primary" (click)="getAlumnos()">
                Volver a cargar
              </button>
            </div>
          </div>
        </div>

        <div class="mat-row" *ngIf="loading && error">
          <div class="mat-cell" colspan="7">
            <div class="no-result">
              <mat-spinner></mat-spinner>
            </div>
          </div>
        </div>
        <div class="paginator-container" id="paginator">
          <mat-paginator
            [pageSizeOptions]="[5, 10, 15, 20]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Reportar">
      <div class="alumnos-table">
        <form class="form-contact">
          <h2>¿Cuál es el motivo?</h2>
          <mat-radio-group
            aria-label="Selecciona una opcion"
            class="differ-container"
            color="primary"
            [formControl]="subjectCtrl"
          >
            <mat-radio-button [value]="'consulta'">Consulta</mat-radio-button>
            <mat-radio-button [value]="'error'"
              >Error de carga</mat-radio-button
            >
          </mat-radio-group>
          <h2>
            Explícanos con detalle
            {{
              subjectCtrl.value === "consulta" ? "tu consulta" : "el problema"
            }}
          </h2>
          <mat-form-field class="full-width" appearance="fill">
            <mat-label>Escribe tu consulta</mat-label>
            <textarea
              matInput
              #message
              placeholder="Mi consulta es.."
              maxlength="800"
              [formControl]="messageCtrl"
              required
            ></textarea>
            <mat-error *ngIf="messageCtrl.hasError('required')">
              Este campo es <strong>obligatorio</strong>
            </mat-error>
            <mat-hint align="end"
              >{{ messageCtrl.value?.length || 0 }}/800
            </mat-hint>
          </mat-form-field>
          <button
            mat-flat-button
            color="primary"
            [disabled]="messageCtrl.hasError('required')"
            (click)="sendReport()"
          >
            Enviar reporte
          </button>
        </form>
      </div>
    </mat-tab>
  </mat-tab-group>
  <!-- <div class="accordion-container">
    <mat-accordion class="mat-elevation-z0">
      <ng-container>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
          [expanded]="true"
          class="mat-elevation-z0"
        >
          <mat-expansion-panel-header>
            <div class="header">
              <div class="title">Información Adicional</div>
            </div>
          </mat-expansion-panel-header>
          <div class="alumnos-table"></div>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
          class="mat-elevation-z0"
        >
          <mat-expansion-panel-header>
            <div class="header">
              <div class="title">Alumnos</div>
            </div>
          </mat-expansion-panel-header>
          <div class="alumnos-table main-radius main-shadow main-border">
            <table
              mat-table
              [dataSource]="dataSource"
              matSort
              #pagosSort="matSort"
              class="mat-elevation-z0"
            >
              <ng-container
                [matColumnDef]="column.columnDef"
                [ngSwitch]="true"
                *ngFor="let column of tableColumns"
              >
                <ng-container *ngSwitchDefault>
                  <th mat-header-cell mat-sort-header *matHeaderCellDef>
                    {{ column.header }}
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <div *ngIf="column.cell(row) !== 'Sin datos'; else noData">
                      {{ column.cell(row) }}
                    </div>
                    <ng-template #noData>
                      <div class="no-data">
                        {{ column.cell(row) }}
                      </div>
                    </ng-template>
                  </td>
                </ng-container>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: columnsToDisplay"
                class="example-element-row"
              ></tr>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="7">
                  <div class="no-result" *ngIf="value.value !== ''">
                    <mat-icon class="material-icons-round">search</mat-icon>
                    <div class="text">No encontramos resultados para</div>
                    <div class="searched-value">"{{ value.value }}"</div>
                  </div>
                  <div class="no-result" *ngIf="notes === []">
                    <mat-icon class="material-icons-round">search</mat-icon>
                    <div class="text">
                      Aún no tienes notas cargadas en el sistema
                    </div>
                  </div>
                </td>
              </tr>
            </table>

            <div class="mat-row" *ngIf="error && !loading">
              <div class="mat-cell" colspan="7">
                <div class="no-result">
                  <mat-icon class="material-icons-round">search</mat-icon>
                  <div class="searched-value">
                    Ups.. no encontramos a tus alumnos.
                  </div>
                  <div class="text">¡Intenta de nuevo más tarde!</div>
                  <button
                    mat-flat-button
                    color="primary"
                    (click)="getAlumnos()"
                  >
                    Volver a cargar
                  </button>
                </div>
              </div>
            </div>

            <div class="mat-row" *ngIf="loading && error">
              <div class="mat-cell" colspan="7">
                <div class="no-result">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
            <div class="paginator-container" id="paginator">
              <mat-paginator
                [pageSizeOptions]="[5, 10, 15, 20]"
                showFirstLastButtons
              ></mat-paginator>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
          class="mat-elevation-z0"
        >
          <mat-expansion-panel-header>
            <div class="header">
              <div class="title">Reportar</div>
            </div>
          </mat-expansion-panel-header>
          <div class="alumnos-table">
            <form class="form-contact">
              <h2>¿Cuál es el motivo?</h2>
              <mat-radio-group
                aria-label="Selecciona una opcion"
                class="differ-container"
                color="primary"
                [formControl]="subjectCtrl"
              >
                <mat-radio-button [value]="'consulta'"
                  >Consulta</mat-radio-button
                >
                <mat-radio-button [value]="'error'"
                  >Error de carga</mat-radio-button
                >
              </mat-radio-group>
              <h2>
                Explícanos con detalle
                {{
                  subjectCtrl.value === "consulta"
                    ? "tu consulta"
                    : "el problema"
                }}
              </h2>
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>Escribe tu consulta</mat-label>
                <textarea
                  matInput
                  #message
                  placeholder="Mi consulta es.."
                  maxlength="800"
                  [formControl]="messageCtrl"
                  required
                ></textarea>
                <mat-error *ngIf="messageCtrl.hasError('required')">
                  Este campo es <strong>obligatorio</strong>
                </mat-error>
                <mat-hint align="end"
                  >{{ message.value?.length || 0 }}/800
                </mat-hint>
              </mat-form-field>
              <button mat-flat-button color="primary" (click)="sendReport()">
                Enviar reporte
              </button>
            </form>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div> -->
</div>
