<div class="container">
  <div class="show-container"></div>
  <div
    class="show-container-mobile"
    id="mobile-show"
    (click)="triggerSocials()"
  ></div>
  <div class="hidden-container" id="hidden-container">
    <div class="social-icons">
      <a
        class="mx-1"
        target="_blank"
        href="https://www.facebook.com/ADENUniversityPanama/"
      >
        <img
          src="../../../../assets/images/icons/red/facebook-w.png"
          alt=""
        />
      </a>
      <a class="mx-1" target="_blank" href="https://twitter.com/Adenupa">
        <img src="../../../../assets/images/icons/red/twitter-red.png" alt="" />
      </a>
      <a
        class="mx-1"
        target="_blank"
        href="https://www.instagram.com/adenupa/?hl=es-la"
      >
        <img
          src="../../../../assets/images/icons/red/instagram-red.png"
          alt=""
        />
      </a>
      <a
        class="mx-1"
        target="_blank"
        href="https://www.linkedin.com/company/adenupa"
      >
        <img
          src="../../../../assets/images/icons/red/linkedin-red.png"
          alt=""
        />
      </a>
      <a
        class="mx-1"
        target="_blank"
        href="https://www.youtube.com/channel/UCpTcEFxdU19ZHrkELRNOBmg/videos"
      >
        <img src="../../../../assets/images/icons/red/youtube-red.png" alt="" />
      </a>
      <a
        class="mx-1"
        target="_blank"
        href="https://www.tiktok.com/@adenuniversity?"
      >
        <img src="../../../../assets/images/icons/red/tiktok-red.png" alt="" />
      </a>
      <a class="mx-2" target="_blank" href="https://adenuniversity.edu.pa/">
        <span style="font-size: 14px" class="text-primary-aden" id="web-link"
          >www.adenuniversity.edu.pa</span
        >
        <img
          src="../../../../assets/images/icons/red/web-red.png"
          alt=""
          id="web-icon"
        />
      </a>
    </div>
  </div>
</div>
<!-- <div class="chat"><img src="assets/images/logos/chat.png"></div> -->
