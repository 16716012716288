import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { ICourse, IResource } from 'src/app/shared/interfaces/interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {

  courseList$: Observable<ICourse>;
  courseStore$: Observable<IResource[]>
  list = [];
  allCoursesList = [];
  inProgressList = [];
  pendingList = [];
  isError = false;
  endedList = [];
  isLoading = false;

  constructor(
    private dataService: DataService,
    private router: Router,
    private snackbar: MatSnackBar,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.initGetResources();
  }
  initGetResources(){
    this.isLoading = true;
    this.dataService.getAllCourses().subscribe({
      next: course => {
        this.allCoursesList = course;
        this.list = course;
      },
      error: err => {
        console.log(err)
        this.isError = true;
        this.isLoading = false;
      }
    })
    this.getRegisterCourse()
  }
  goToVideo = (id) => {
    this.router.navigateByUrl('course/' + id)
  }
  createEnrollment(){
    // this.dataService.createEnrollment().subscribe(data => console.log(data))
  }
  getRegisterCourse(){
    const repoId = localStorage.getItem('repo_id');
    this.isLoading = true;
    this.dataService.getRegisterCourse(repoId).subscribe(data => {
      const typeOaList = data.response_data.enrollments.filter(item => item.type === 'oa');
      this.list = this.list.filter(item => !typeOaList.some(oaItem => oaItem.detail.id === item.id));
      this.list.push(...typeOaList);
      this.allCoursesList.forEach(course => {
        const matchingOaItem = typeOaList.find(oaItem => oaItem.detail.id === course.id);
        if (matchingOaItem) {
          matchingOaItem.duracion = course.duracion;
          matchingOaItem.duracion_unidad_tiempo = course.duracion_unidad_tiempo;
        }
      });
      this.getFilteredCourses();
    });
  }
  getFilteredCourses(){
    this.endedList = this.list.filter(course =>  course.detail && course.detail.progress === 100)
    this.pendingList = this.list.filter(course => course.detail && course.detail.progress === 0)
    this.inProgressList = this.list.filter(course => course.detail && course.detail.progress > 0 && course.detail.progress < 100)
    this.isLoading = false;
  }
  onRegisterCourse = (resourceId) => {
    this.dataService.registerCourse(resourceId).subscribe({
      next: data => {
        this.snackbar.open('Curso registrado con exito', 'OK', {
          duration: 5000,
          panelClass: ['success-snackbar'],
        });
        this.initGetResources();
        this.goToVideo(resourceId)
      },
      error: err => {
        this.snackbar.open('Hubo un problema al registrar el curso', 'OK', {
          duration: 5000,
          panelClass: ['error-snackbar'],
        });
        console.log(err)
      }
    })
  }
}