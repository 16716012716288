import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-multi-docs-modal',
  templateUrl: './multi-docs-modal.component.html',
  styleUrls: ['./multi-docs-modal.component.scss']
})
export class MultiDocsModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dataService: DataService) { }
  ngOnInit(): void {
    console.log(this.data.userDoc.documentos_adjuntos)
    // this.checkFile();
  }
  onFileSelected($event, id) {
    const file: File = $event.target.files[0];
    this.checkFile(file, true, id);
  }
  checkFile(file, isMulti = false, id) {
    const fileSize = file.size / 1024 / 1024;
    const fileType = file.name.split('.').pop();
    const acceptedFiles = ['jpg', 'jpeg', 'png', 'pdf'];

    if (file && fileSize <= 10) {
      if (acceptedFiles.includes(fileType)) {
        if(isMulti){
          this.updateDoc(file, isMulti, id)
        }
      } else {
        this.dataService.openAlert(
          'No aceptamos este tipo de archivo',
          'Sólo puedes subir tus documentos como PDF o en formato de imagen: JPG, JPEG o PNG',
          'Aceptar',
          null,
          'error',
          'error'
        );
      }
    } else {
      this.dataService.openAlert(
        '¡Tu archivo es muy pesado!',
        'El archivo no debe exceder los 10MB, reduce su peso e intenta nuevamente',
        'Aceptar',
        null,
        'error',
        'error'
      );
    }
  }

  closeModal(){
    this.dataService
  }

  updateDoc(file, isMulti = false, id) {
    if(isMulti){
      this.dataService.updateMultiDocs(file, id).subscribe(
        (res) => {
          this.dataService.openAlert(
            '¡Documento actualizado con éxito!',
            'Ya recibimos tu documento, una vez que sea revisado verás el estado de la entrega aquí',
            'Aceptar',
            null,
            'success',
            'check_circle'
          );
          /* this.getDocs(); */
          this.data.changeDocState(this.data.userDoc.category_id, 'En revisión');
        },
        (error) => {
          this.dataService.openAlert(
            'No pudimos actualizar el documento, intenta de nuevo más tarde',
            'OK',
            'error'
          );
        }
      )
    }else{
      this.dataService.updateDocument(file, this.data.userDoc.id).subscribe(
        (res) => {
          this.dataService.openAlert(
            '¡Documento actualizado con éxito!',
            'Ya recibimos tu documento, una vez que sea revisado verás el estado de la entrega aquí',
            'Aceptar',
            null,
            'success',
            'check_circle'
          );
          /* this.getDocs(); */
          this.data.changeDocState(this.data.userDoc.category_id, 'En revisión');
        },
        (error) => {
          this.dataService.openAlert(
            'No pudimos actualizar el documento, intenta de nuevo más tarde',
            'OK',
            'error'
          );
        }
      );
    }
  }
}

