<div
  class="profile-content card-aden height d-flex justify-content-between"
  *ngIf="user"
>
  <div class="profile">
    <div class="image mr-4">
      <img
        src="{{
          (user$ | async)?.foto64
            ? (user$ | async)?.foto64
            : 'assets/images/logos/avatar.png'
        }}"
        alt=""
      />

      <button
        mat-mini-fab
        color="primary"
        (click)="showModalPhoto()"
        class="upload-image"
        aria-label="Cargar foto de perfil"
      >
        <mat-icon class="material-icons-round">file_upload</mat-icon>
      </button>
    </div>
    <div class="data">
      <h2>
        {{ user.nombre }}
      </h2>
      <p class="mail">{{ user.correo_academico }}</p>
      <p>Legajo: {{ user.sis_id }}</p>
    </div>
  </div>
</div>
