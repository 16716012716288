import { Component, OnInit } from '@angular/core';
import { ChildActivationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { DataService } from 'src/app/shared/services/data.service';
import * as reduxUser from '../../redux/actions/user.action';
import moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  public cardData: Array<any> = [
    {
      id: 1,
      title: 'Gestión de pagos',
      badge: 'Gestión de pagos',
      icon: 'payments',
      description: '¡Chequea tus órdenes de pago!',
      link: '/pagos',
    },
    {
      id: 2,
      title: 'Mi perfil',
      badge: 'PERFIL',
      icon: 'account_circle',
      description: 'Chequea tu perfil y completa tus datos y documentación',
      link: '/perfil',
    },
  ];

  public user;
  public user$: Observable<any>;

  public today = new Date();

  public moment: any = moment;

  constructor(
    private router: Router,
    private dataService: DataService,
    private store: Store<UserState>
  ) {
    this.user$ = store.select('user');
  }

  getDate(dateString) {
    return new Date(dateString);
  }
}
