import { TourService } from 'ngx-ui-tour-md-menu';
import { UntypedFormControl } from '@angular/forms';
import { DetalleBloqueo } from '../../../interfaces/interfaces';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { DataService } from '../../../services/data.service';
import * as reduxUser from '../../../../redux/actions/user.action';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnDestroy, OnInit {
  @Input()
  showFooter: boolean;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  public expandedSidenav = new UntypedFormControl(false);
  estadoBloqueos: DetalleBloqueo[] = [];

  public user$: Observable<any>;
  constructor(
    private dataService: DataService,
    private store: Store<UserState>,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private readonly tourService: TourService
  ) {
    this.user$ = store.select('user');

    this.mobileQuery = media.matchMedia('(max-width: 912px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  public user = {
    nombre: '',
    apellido: '',
    rol: {
      denominacion: '',
    },
    imagen: '',
  };

  isHandset = true;

  ngOnInit(): void {
    this.user$.subscribe((resStore) => {
      if (resStore.user === null) {
        this.getUser();
      }
    });
    this.tourService.initialize(
      [
        {
          anchorId: 'sidenav',
          content:
            'El contenido principal de la página lo encontrarás en este menú, cada vez que te posiciones por encima podrás ver cada una de las secciones disponibles',
          title: '¡Nuevo menú!',
          enableBackdrop: true,
          disablePageScrolling: true,
          prevBtnTitle: 'Anterior',
          nextBtnTitle: 'Siguiente',
          endBtnTitle: 'Finalizar',
        },
        {
          anchorId: 'toggleSidenav',
          content:
            'Haciendo click en este botón podrás fijar el menú lateral. Vuelve a hacer click para ocultarlo. <br/><br/><strong>Haz click en el botón para continuar</strong>',
          title: 'Puedes fijar el menu',
          prevBtnTitle: 'Anterior',
          nextBtnTitle: 'Siguiente',
          endBtnTitle: 'Finalizar',
          nextOnAnchorClick: true,
        },
        {
          anchorId: 'canvas',
          content: 'Accede a la plataforma de cursado desde aquí.',
          title: 'Acceso a CANVAS',
          route: '',
          prevBtnTitle: 'Anterior',
          nextBtnTitle: 'Siguiente',
          endBtnTitle: 'Finalizar',
        },
        {
          anchorId: 'pagos',
          content: 'Chequea tus órdenes de pago y su estado aquí',
          title: 'Getión de pagos',
          route: '',
          prevBtnTitle: 'Anterior',
          nextBtnTitle: 'Siguiente',
          endBtnTitle: 'Finalizar',
        },
        {
          anchorId: 'movilidad',
          content:
            'Inscríbete para participar del programa de Movilidad Internacional de ADENU',
          title: 'Movilidad internacional',
          route: '',
          prevBtnTitle: 'Anterior',
          nextBtnTitle: 'Siguiente',
          endBtnTitle: 'Finalizar',
        },
        {
          anchorId: 'user-menu',
          content:
            'Al hacer click en este botón podrás ver las secciones realacionadas con tu cuenta. <br/><br/><strong>Haz click en tu foto para continuar.</strong>',
          title: 'Menú de usuario',
          route: '',
          prevBtnTitle: 'Anterior',
          nextBtnTitle: 'Siguiente',
          endBtnTitle: 'Finalizar',
          nextOnAnchorClick: true,
        },
        {
          anchorId: 'perfil',
          content:
            'Edita tus datos personales, formación académica y documentación',
          title: 'Datos de perfil',
          route: '',
          prevBtnTitle: 'Anterior',
          nextBtnTitle: 'Siguiente',
          endBtnTitle: 'Finalizar',
          isAsync: true,
        },
        {
          anchorId: 'user-logout',
          content: 'Cierra sesión cuando ya no utilices el campus',
          title: 'Cerrar Sesión',
          route: '',
          prevBtnTitle: 'Anterior',
          nextBtnTitle: 'Siguiente',
          endBtnTitle: 'Finalizar',
          isOptional: true,
        },
        {
          anchorId: 'user-start-tour',
          content:
            '¡Vuelve a realizar el tour cuantas veces quieras! <br/><br/>Ahora sí, haz click en <strong>Finalizar</strong> para completar el tour.',
          title: 'Tour de navegación',
          route: '',
          prevBtnTitle: 'Anterior',
          nextBtnTitle: 'Siguiente',
          endBtnTitle: 'Finalizar',
          isOptional: true,
        },
      ],
      { route: 'home', disablePageScrolling: true, enableBackdrop: false }
    );

    if (!localStorage.getItem('finishTour') && !this.mobileQuery.matches) {
      this.tourService.start();
    }

    this.tourService.end$.subscribe((red) => {
      localStorage.setItem('finishTour', 'true');
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  getUser(): void {
    this.dataService.getDocente().subscribe(
      (res) => {
        if (
          (res.nombre === '' || res.nombre === null) &&
          (res.correo_academico === '' ||
            !res.correo_academico ||
            res.correo_academico === undefined)
        ) {
          this.dataService.openAlert(
            '¡Ups!',
            'Estamos teniendo problemas con nuestro servidor, por favor intenta más tarde',
            'Ok'
          );
          setTimeout(() => {
            this.onSignOut();
          }, 5000);
        }

        localStorage.setItem(
          'userData',
          JSON.stringify({
            ...res,
            foto64: `data:image/png;base64,${res.foto64}`,
            sis_id: localStorage.getItem('sis_id'),
          })
        );

        this.store.dispatch(
          new reduxUser.GetUser({
            ...res,
            foto64: `data:image/png;base64,${res.foto64}`,
            sis_id: localStorage.getItem('sis_id'),
          })
        );
      },
      (err) => {
        this.dataService.openAlert(
          '¡Ups!',
          'Estamos teniendo problemas con nuestro servidor, por favor vuelve a iniciar sesión',
          'Ok'
        );
        setTimeout(() => {
          this.dataService.clearSis_id();
          this.dataService.clearToken();
          window.location.reload();
        }, 5000);
      }
    );
  }

  startTour(): void {
    this.tourService.start();

    this.tourService.end$.subscribe((red) => {
      localStorage.setItem('finishTour', 'true');
    });
  }

  onSignOut(): void {
    this.dataService.clearSis_id();
    this.dataService.clearToken();
    this.dataService.clear_estado_bloqueo();
    window.location.reload();
  }

  /* setContentMargin(event) {
    this.expandedSidenav.setValue(event);
  } */
}
