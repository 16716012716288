<div id="plan-estudio">
    <div class="sticky-header"><app-header [title]="title"></app-header></div>

    <div class="container flex" *ngIf="carreras && !isLoading">
        <mat-tab-group mat-align-tabs="start" class="main-radius main-shadow">
            <mat-tab *ngFor="let carrera of carreras; let index = index" [label]="carrera.carrera">
                <div class="content">
                    <div class="heading main-radius mb-4">
                        <div class="text-content">
                            <h1>{{ carrera.carrera }}</h1>
                            <h3><strong>RESOLUCIÓN: </strong>{{ carrera.resolucion }}</h3>
                        </div>
                        <button mat-flat-button color="primary" (click)="generatePdf(carrera)">
                            <mat-icon class="material-icons-round">download</mat-icon>
                            Descargar
                            <mat-progress-spinner diameter="20" mode="indeterminate" [color]="'#ffffff'"
                                *ngIf="isLoading"></mat-progress-spinner>
                        </button>
                    </div>
                    <app-plan-table [planEstudio]="carrera.plan"></app-plan-table>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="error" *ngIf="isError && !isLoading">
        <div class="no-result">
            <img class="error-img" src="../../../../assets/images/content/sin-resultados.svg" alt="" />
            <div class="searched-value">Ups.. no encontramos tus carreras</div>
            <div class="text">
                ¡Intenta de nuevo más tarde o contacta a tu Academic Advisor para
                verificar el problema!
            </div>
            <button mat-flat-button color="primary" (click)="getAlumno()">
                Volver a cargar
            </button>
        </div>
    </div>
    <div class="load-container" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
</div>