import { UntypedFormControl } from '@angular/forms';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnDestroy, OnInit {
  miCursadoToggle = new UntypedFormControl(false);
  ceadenToggle = new UntypedFormControl(false);
  showLabels = new UntypedFormControl(false);
  hoverControl = new UntypedFormControl(false);

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  /* openSidenav = new FormControl(false); */
  @Input() expandedSidenav = new UntypedFormControl(false);

  openSidenav = new UntypedFormControl(false);

  @Output() sidenavIsOpen = new EventEmitter<boolean>();

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 912px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.closeSidenav = this.closeSidenav.bind(this);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  toggleSidenav() {
    if (this.expandedSidenav.value || this.openSidenav.value) {
      this.openSidenav.setValue(false);
      this.sidenavIsOpen.emit(false);
    } else {
      this.openSidenav.setValue(true);
      this.sidenavIsOpen.emit(true);
    }
  }

  toggleDropdowns() {
    if (this.miCursadoToggle.value) {
      this.miCursadoToggle.setValue(false);
    }
    if (this.ceadenToggle.value) {
      this.ceadenToggle.setValue(false);
    }
  }

  closeSidenav() {
    if (
      this.mobileQuery.matches &&
      (this.expandedSidenav.value || this.openSidenav.value)
    ) {
      this.openSidenav.setValue(false);
      this.sidenavIsOpen.emit(false);
    }
  }

  goToEmpleo() {
    document.forms['job-form'].submit();
  }
}
