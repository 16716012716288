import { UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DataService } from 'src/app/shared/services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  title: string;
  pago: any;
}

@Component({
  selector: 'app-more-info-dialog',
  templateUrl: './more-info-dialog.component.html',
  styleUrls: ['./more-info-dialog.component.scss'],
})
export class MoreInfoDialogComponent implements OnInit {
  public students = [];
  panelOpenState = true;

  error = false;
  loading = true;

  public dataSource;

  public tableColumns = [
    {
      columnDef: 'codpersona',
      header: 'Legajo',
      cell: (student: any) => student.codpersona,
    },
    {
      columnDef: 'alumno',
      header: 'Nombre',
      cell: (student: any) => student.alumno,
    },
    {
      columnDef: 'fechamodif',
      header: 'Última modificación',
      cell: (student: any) => student.fechamodif,
    },
    {
      columnDef: 'estado',
      header: 'Estado',
      cell: (student: any) => student.estado,
    },
  ];

  public columnsToDisplay = this.tableColumns.map((c) => c.columnDef);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  messageCtrl = new UntypedFormControl('', Validators.required);
  subjectCtrl = new UntypedFormControl('Consulta');

  constructor(
    public dialogRef: MatDialogRef<MoreInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dataService: DataService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getAlumnos();
  }

  getAlumnos(): void {
    this.loading = true;
    this.dataService.getStudentsSis(this.data.pago.name).subscribe(
      (res) => {
        this.students = res;
        this.loading = true;
        this.renderTable(this.students);
      },
      (error) => {
        this.error = true;
        this.loading = false;
      }
    );
  }

  renderTable(datos): void {
    this.dataSource = new MatTableDataSource<any>(datos);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  sendReport() {
    this.dataService
      .reportPago(
        this.messageCtrl.value,
        this.data.pago.type_id,
        this.subjectCtrl.value,
        'florencia.salcedo@aden.org'
      )
      .subscribe(
        (res) => {
          this._snackBar.open('¡Mensaje enviado con éxito!', 'OK', {
            duration: 2000,
            panelClass: ['success-snackbar'],
          });
        },
        (err) => {
          this._snackBar.open(
            'Ocurrió un error, por favor intenta de nuevo más tarde',
            'OK',
            {
              duration: 2000,
              panelClass: ['error-snackbar'],
            }
          );
        }
      );
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
