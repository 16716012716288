<div
  class="card-wrapper"
  [ngClass]="{ new: !notification.is_open }"
  (click)="markAsViewed()"
>
  <div class="heading">
    <div class="title">
      {{ notification.title }}
    </div>
    <div class="date">
      {{ moment.utc(notification.date).local().locale("es").toNow(true) }}
    </div>
  </div>
  <p class="content">
    {{ notification.message }}
  </p>
</div>
