import { Criterio } from '../../../../../shared/interfaces/interfaces';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-requisito-internacional',
  templateUrl: './card-requisito-internacional.component.html',
  styleUrls: ['./card-requisito-internacional.component.scss'],
})
export class CardRequisitoInternacionalComponent {
  @Input() requisito: Criterio;

  constructor() {}
}
