export const universities = [
  {
    name: 'IQS - Universitat Ramon Llull',
    country: 'España',
    type: 'Universidad',
    link: 'https://www.iqs.edu/es',
    img: 'IQS',
    flag: 'spain',
    externalInscription: false,
    id: 1,
  },
  {
    name: 'Vinglet Barcelona',
    country: 'España',
    type: 'Organización',
    link: 'https://www.vinglet.com/',
    img: 'Vinglet',
    flag: 'spain',
    externalInscription: true,
    id: 2,
  },
  {
    name: 'Universidad de Valladolid',
    country: 'España',
    type: 'Universidad',
    link: 'https://www.uva.es/',
    img: 'UdeValladolid',
    flag: 'spain',
    externalInscription: false,
    id: 3,
  },
  {
    name: 'Universidad de La Laguna',
    country: 'España',
    type: 'Universidad',
    link: 'https://www.ull.es/',
    img: 'ULaLaguna',
    flag: 'spain',
    externalInscription: false,
    id: 4,
  },
  {
    name: 'Universidad Politécnica de Cartagena',
    country: 'España',
    type: 'Universidad',
    link: 'https://www.upct.es/',
    img: 'PolitecnicoCartagena',
    flag: 'spain',
    externalInscription: false,
    id: 5,
  },
  {
    name: 'Universidad de Extremadura',
    country: 'España',
    type: 'Universidad',
    link: 'https://www.unex.es/',
    img: 'UEX',
    flag: 'spain',
    externalInscription: false,
    id: 17,
  },
  {
    name: 'Universidad de León',
    country: 'España',
    type: 'Universidad',
    link: 'https://www.unileon.es/',
    img: 'UdeLeon',
    flag: 'spain',
    externalInscription: false,
    id: 18,
  },
  {
    name: 'Université Catholique de Lille, Facultad de Gestión, Economía y Ciencias',
    country: 'Francia',
    type: 'Universidad',
    link: 'https://www.fges.fr/',
    img: 'LilleFacultad',
    flag: 'france',
    externalInscription: false,
    id: 6,
  },
  {
    name: 'Politécnico de Milán',
    country: 'Italia',
    type: 'Universidad',
    link: 'https://www.polimi.it/',
    img: 'PolitecnicoMilano',
    flag: 'italia',
    externalInscription: false,
    id: 7,
  },
  {
    name: 'Powislanski University',
    country: 'Polonia',
    type: 'Universidad',
    link: 'https://psw.kwidzyn.edu.pl/',
    img: 'Powislanski',
    flag: 'polonia',
    externalInscription: false,
    id: 8,
  },
  {
    name: 'FHNW - University of Applied Sciences and Arts Northwestern Switzerland (School of Business)',
    country: 'Suiza',
    type: 'Universidad',
    link: 'https://www.fhnw.ch/en/',
    img: 'FHNW',
    flag: 'suiza',
    externalInscription: false,
    id: 9,
  },
  {
    name: 'Berkeley, University of California',
    country: 'USA',
    type: 'Universidad',
    link: 'https://summer.berkeley.edu/',
    img: 'Berkeley',
    flag: 'usa',
    externalInscription: true,
    id: 10,
  },
  {
    name: 'McGill University',
    country: 'Canadá',
    type: 'Universidad',
    link: 'https://www.mcgill.ca/continuingstudies/international-students-hub/programs-international-students/short-programs/winter-short-programs/courses-programs',
    img: 'McGill',
    flag: 'canada',
    externalInscription: true,
    id: 11,
  },
  {
    name: 'Trent University',
    country: 'Canadá',
    type: 'Universidad',
    link: 'https://www.trentu.ca/',
    img: 'UTrent',
    flag: 'canada',
    externalInscription: false,
    id: 12,
  },
  {
    name: 'UPAEP - Universidad Popular Autónoma del Estado de Puebla',
    country: 'México',
    type: 'Universidad',
    link: 'https://www.upaep.mx/',
    img: 'UPAEP',
    flag: 'mexico',
    externalInscription: false,
    id: 13,
  },
  {
    name: 'USS - Universidad Señor de Sipán',
    country: 'Perú',
    type: 'Universidad',
    link: 'https://www.uss.edu.pe/',
    img: 'USS',
    flag: 'peru',
    externalInscription: false,
    id: 14,
  },
  {
    name: 'Universidad Peruana de Ciencias Aplicadas',
    country: 'Perú',
    type: 'Universidad',
    link: 'https://www.upc.edu.pe/',
    img: 'UPeruanaCienciasAplicadas',
    flag: 'peru',
    externalInscription: false,
    id: 15,
  },
  {
    name: 'HZ University of Applied Sciences',
    country: 'Países Bajos',
    type: 'Universidad',
    link: 'https://hz.nl/',
    img: 'HZ',
    flag: 'netherlands',
    externalInscription: false,
    id: 16,
  },
  
];

export const universitiesDetails = [
  {
    name: 'IQS - Universitat Ramon Llull',
    country: 'España',
    careersTitle: 'Carreras y cursos disponibles:',
    careers: [
      'Grado en Administración y Dirección de Empresas (ADE)',
      'Grado en Marketing',
      'Grado en International Business',
      'Grado en Ingeniería en Tecnologías Industriales',
    ],

    logo: '../../../../assets/images/content/paises/IQS.png',
    details: [
      'Movilidad de alumnos',
      'Tiempo: 1 cuatrimestre',
      'Períodos: Primer cuatrimestre (febrero – mayo) | Segundo cuatrimestre (septiembre – diciembre)',
      'Viaje de estudio a Barcelona.',
    ],
    web: 'https://www.iqs.edu/es',
  },
  {
    name: 'Université Catholique de Lille',
    country: 'Francia',
    careersTitle: 'Carreras y cursos disponibles:',
    careers: [
      'Bachelor Degree in Economics and Management',
      'Bachelor in banking and insurance: Chargé de clientèle',
      'Bachelor in International Business',
      'Bachelor in management',
      'Bachelors in Economics and Business',
      'Business Law et Management',
      'International Business and Law',
    ],
    logo: '../../../../assets/images/content/paises/Lille.png',
    details: [
      'Movilidad de alumnos',
      'Tiempo: 1 cuatrimestre',
      'Períodos: Primer cuatrimestre (febrero – mayo) | Segundo cuatrimestre (septiembre – diciembre)',
      'Idioma: Francés o Inglés (algunas materias)',
    ],
    web: 'https://www.univ-catholille.fr/',
  },
  {
    name: 'Powislanski University',
    country: 'Polonia',
    careersTitle: 'Carreras y cursos disponibles:',
    careers: [
      'Economía con especializaciones',
      'Transporte / Envío / Logística',
      'Economía de la Empresa',
      'Economía de la gestión',
      'Ciencias de la computación aplicadas',
      'Finanzas / Banca / Contabilidad',
      'Administración de recursos humanos',
      'RRHH (Recursos Humanos)',
      'Economía de los servicios y la producción',
      'Agroeconomía',
    ],
    logo: '../../../../assets/images/content/paises/Powislanski.png',
    details: [
      'Movilidad de alumnos',
      'Tiempo: 1 cuatrimestre',
      'Períodos: Primer cuatrimestre (febrero – mayo) | Segundo cuatrimestre (septiembre – diciembre)',
      'Idioma: Inglés',
    ],
    web: 'https://psw.kwidzyn.edu.pl/',
  },
  {
    name: 'UPAEP - Universidad Popular Autónoma del Estado de Puebla',
    country: 'México',
    careersTitle: 'Carreras y cursos disponibles:',
    careers: [
      'Ingeniería Industrial',
      'Administración de Empresas',
      'Administración Financiera y Bursátil',
      'Comercio y Estrategia Internacional',
      'Contaduría y Alta Dirección',
      'Dirección de Hospitalidad y Turismo',
      'Inteligencia de Negocios',
      'Mercadotecniarial',
      'Comercio Internacional y Logística',
      'Mercadotecnia y Gestión de Ventas',
    ],
    logo: '../../../../assets/images/content/paises/UPAEP.png',
    details: [
      'Movilidad de alumnos',
      'Tiempo: 1 cuatrimestre',
      'Períodos: Primer cuatrimestre (febrero – mayo) | Segundo cuatrimestre (septiembre – diciembre)',
    ],
    web: 'https://www.upaep.mx/',
  },
  {
    name: 'USS - Universidad Señor de Sipán',
    country: 'Perú',
    careersTitle: 'Carreras y cursos disponibles:',
    careers: [
      'Administración',
      'Contabilidad',
      'Negocios Internacionales',
      'Turismo y Negocios',
    ],
    logo: '../../../../assets/images/content/paises/USS.png',
    details: [
      'Movilidad de alumnos',
      'Tiempo: 1 cuatrimestre',
      'Períodos: Primer cuatrimestre (febrero – mayo) | Segundo cuatrimestre (septiembre – diciembre)',
    ],
    web: 'https://www.uss.edu.pe/uss',
  },
  {
    name: 'FHNW - University of Applied Sciences and Arts Northwestern Switzerland (School of Business)',
    country: 'Suiza',
    careersTitle: 'Carreras y cursos disponibles:',
    careers: [
      'School of Business: Cursos disponibles según el cuatrimestre y el idioma',
    ],
    urlCareers:
      'https://www.fhnw.ch/plattformen/iobusiness/exchange-at-fhnw-school-of-business/course-offer/',
    logo: '../../../../assets/images/content/paises/FHNW.png',
    details: [
      'Movilidad de alumnos',
      'Tiempo: 1 cuatrimestre',
      'Períodos: Primer cuatrimestre (febrero – mayo) | Segundo cuatrimestre (septiembre – diciembre)',
      'Cantidad de plazas: 1 alumno por año académico',
    ],
    web: 'https://www.fhnw.ch/de/',
  },
  {
    name: 'Berkeley, University of California',
    country: 'Estados Unidos',
    careersTitle: 'Cursos relacionados al negocio',
    careers: [
      'Microeconomic Analysis for Business Decisions',
      'Financial Accounting',
      'Managerial Accounting',
      'Introduction to Finance',
      'Introduction to Business Analytics',
      'Leading People',
      'Marketing',
      'The Social, Political, and Ethical Environment of Business',
      'Financial Information Analysis',
      'Corporate Finance and Financial Statement Analysis',
      'Financial Institutions and Markets',
      'Investments',
      'Behavioral Finance',
      'Special Topics in Finance: Financial Derivatives',
      'Project Management',
      'Special Topics in Operations and Information Technology',
      'Management: Advanced Business Analytics',
      'Negotiation and Conflict Resolution',
      'Power and Politics in Organizations',
      'Leadership',
      'Customer Insights',
      'Brand Management and Strategy',
      'Advertising Strategy',
      'Introduction to International Business',
      'Business and Sustainability',
      'Leadership: Purpose, Authority, and Empowerment',
      '¡Además, cuenta con más de 600 cursos de distintas disciplinas disponibles para inscribirse!',
    ],
    urlCareers:
      'https://drive.google.com/file/d/1wtiw2wWzuTMIfkUxjbF1JCIl1uTBAJLD/view',
    logo: '../../../../assets/images/content/paises/Berkeley.png',
    period: [
      {
        title: 'Business y otros cursos',
        list: [
          'Session A & 12W: Thursday, May 20th, 5-6 PM PST',
          'Session D & F: Thursday, July 1st, 5-6 PM PST',
        ],
      },
      {
        title: 'Cursos de Idioma',
        list: [
          'Session D & F: Thursday, July 1st, 5-6 PM PST',
          'Session E: Thursday, July 22nd, 5-6 PM PST',
        ],
      },
    ],
    details: [
      'Movilidad de alumnos',
      'Esta movilidad implica el pago de créditos por parte del alumno en Berkeley. Sin embargo, por ser alumno de ADEN University cuenta con un importante beneficio económico.',
      'Duración: dependerá de la cantidad de materias que desee cursar el estudiante',
      'Cantidad de plazas: sin límite',
      'Consultar en ori@adenuniversity.edu.par por el valor del programa',
    ],
    web: 'https://www.berkeley.edu/',
  },
  {
    name: 'McGill University - Programas cortos de invierno',
    country: 'Canadá',
    careersTitle: 'Cursos y programas orientados a:',
    careers: [
      'Social Media & Digital Communication',
      'Business Analytics',
      'Machine Learning',
      'Introduction to Big Data',
      'Sustainable Food Systems',
      'International Organizations',
      'Innovation & Entrepreneurship',
    ],
    urlCareers:
      'https://www.mcgill.ca/continuingstudies/international-students-hub/programs-international-students/short-programs/winter-short-programs/courses-programs',
    logo: '../../../../assets/images/content/paises/McGill.png',
    costos: [
      '$1175 CAD por estudiante, por curso',
      '$2350 CAD por estudiante, por programa',
    ],
    details: [
      '<strong>Programa:</strong> 100% online',
      '<strong>Destinatarios:</strong> alumnos de carreras presenciales y virtuales de ADENU.',
      '<strong>Nivel de inglés:</strong> TOEFL iBT 79, IELTS 6.0, Duolingo 105, CEFR B2, CET-4 500 / CET-6 470, o equivalente.',
      '<strong>Fecha de cursado:</strong> 17 de Enero al 11 de Febrero, 2022',
      '<strong>Inscripciones:</strong> Septiembre',
      '<strong>Fecha de cierre:</strong> 15 de noviembre, 2021',
      '<strong>Metodología de cursado:</strong> El alumno puede inscribirse a un curso de 30hs',
      '<strong>Certificación:</strong> Certificación oficial. Los alumnos podrán acreditar las calificaciones obtenidas en estos cursos en su carrera de ADENU (con previa revisión y aprobación del cuerpo académico de ADENU',
    ],
    inscripciones: true,
    description:
      'ADEN University firmó un valioso convenio con McGill University (Quebec - Canadá), el cual les brindará la posibilidad a alumnos de ADENU realizar cursos orientados a Negocios, Marketing, Comunicación digital, Big data, entre otros. Todos online y con certificación oficial de calificaciones.',
    web: 'https://www.mcgill.ca/continuingstudies/international-students-hub/programs-international-students/short-programs/winter-short-programs/courses-programs',
  },
];
