import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import {
  ContestMember,
  ImpulsoMember,
} from 'src/app/shared/interfaces/interfaces';
import { ContestService } from 'src/app/shared/services/contest.service';
import { DataService } from 'src/app/shared/services/data.service';
import moment from 'moment-timezone';

@Component({
  selector: 'app-form-impulso',
  templateUrl: './form-impulso.component.html',
  styleUrls: ['./form-impulso.component.scss'],
})
export class FormImpulsoComponent implements OnInit {
  @Input() setWaiting: () => void;
  @Input() onlyDocs: boolean = false;

  public moment = moment;
  public today = new Date();

  public impulsoInit = new Date('2022-03-11T13:00:00-05:00');
  public impulsoEnd = new Date('2022-04-10T23:59:59-05:00');

  public cargaEnd = new Date('2022-04-22T23:59:59-05:00');

  public leftTime =
    this.impulsoInit.getTime() / 1000 - this.today.getTime() / 1000 > 172800
      ? this.impulsoInit.getTime() / 1000 - this.today.getTime() / 1000 - 86400
      : this.impulsoInit.getTime() / 1000 - this.today.getTime() / 1000;

  public timerConfig = {
    leftTime: this.leftTime > 0 ? this.leftTime : 0,
    format:
      this.leftTime < 86400 && this.leftTime >= 3600
        ? 'HH:mm:ss'
        : this.leftTime < 3600
        ? 'mm:ss'
        : 'dd:HH:mm:ss',
  };

  public isLoading = false;
  public availableUser = true;
  public project;

  public mainForm: UntypedFormGroup;
  public memberForm: UntypedFormGroup;
  public alumnos: ContestMember[] = [];

  public formData: FormData = new FormData();

  public user$;
  public user;

  public miembrosEquipo: ImpulsoMember[] = [];

  studyLevels = [
    'Sin estudios',
    'Primario',
    'Secundario Completo',
    'Secundario Incompleto',
    'Terciario Completo',
    'Terciario Incompleto',
    'Universitario Completo',
    'Universitario Incompleto',
  ];

  alumnoCtrl = new UntypedFormControl();
  filteredAlumnos: Observable<ContestMember[]>;

  filesControl = new UntypedFormControl(false);

  files = {
    propuesta: null,
    video: null,
  };

  constructor(
    private contestService: ContestService,
    private dataService: DataService,
    private formBuilder: UntypedFormBuilder,
    private store: Store<UserState>,
    public snackbar: MatSnackBar
  ) {
    this.user$ = store.select('user');
    this.filteredAlumnos = this.alumnoCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      map((alumno) => {
        return alumno && this.alumnos.length > 0
          ? this._filterAlumnos(alumno)
          : this.alumnos.slice();
      })
    );
  }

  ngOnInit(): void {
    this.user$.subscribe((user) => {
      if (user) {
        this.user = user;
        this.checkUser(user);
      }
    });
    this.buildMainForm();
    this.buildMemberForm();
    this.getAlumnos().then((results) => {
      this.filteredAlumnos = this.alumnoCtrl.valueChanges.pipe(
        startWith(''),
        map((alumno) => {
          if (this.alumnos.length > 0) {
            return alumno ? this._filterAlumnos(alumno) : this.alumnos.slice();
          } else {
            return this.alumnos;
          }
        })
      );
    });
  }

  checkUser(user) {
    this.isLoading = true;
    this.contestService
      .checkImpulseUser(user.legajo, user.email)
      .subscribe((res) => {
        this.availableUser = res.avaliable;
        if (!res.avaliable) {
          this.project = res.checkData;
        }
        this.isLoading = false;
      });
  }

  buildMainForm() {
    this.mainForm = this.formBuilder.group({
      nombre_proyecto: ['', Validators.required],
      tiene_equipo: [false, Validators.required],
    });
  }

  buildMemberForm() {
    this.memberForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      mail: ['', [Validators.required, Validators.email]],
      phone: new UntypedFormControl(
        {
          value: '',
          disabled: false,
        },
        [Validators.required]
      ),
      study_level: ['', Validators.required],
    });
  }

  addMember() {
    if (
      this.miembrosEquipo.some(
        (member) => member.mail === this.memberForm.get('mail').value
      )
    ) {
      this.snackbar.open(
        '¡Ya existe una persona con este mail en tu equipo!',
        'OK',
        {
          duration: 5000,
          panelClass: ['red-snackbar'],
        }
      );
    } else {
      this.contestService
        .checkImpulseUser(
          this.memberForm.get('id').value === ''
            ? null
            : this.memberForm.get('id').value,
          this.memberForm.get('mail').value
        )
        .subscribe(
          (res) => {
            if (res.avaliable) {
              this.miembrosEquipo.push(this.memberForm.value);
              this.resetForms();
            } else {
              this.snackbar.open(
                '¡Esta persona ya pertenece a un equipo!',
                'OK',
                {
                  duration: 5000,
                  panelClass: ['red-snackbar'],
                }
              );
            }
          },
          (err) => {
            this.miembrosEquipo.push(this.memberForm.value);
            this.resetForms();
          }
        );
    }
  }

  resetForms() {
    this.memberForm.patchValue({
      id: '',
      name: '',
      mail: '',
      phone: '',
      study_level: '',
    });
    this.memberForm.markAsPristine();
    this.memberForm.markAsUntouched();
    this.alumnoCtrl.reset();
  }

  resetMembers() {
    this.miembrosEquipo = [];
  }

  removeMember(member) {
    this.miembrosEquipo = this.miembrosEquipo.filter((item) => item !== member);
  }

  onPropuestaSelected(event) {
    this.files.propuesta = event.target.files[0];
  }

  onPropuestaDelete() {
    this.files.propuesta = null;
  }

  onVideoSelected(event) {
    const fileSize = event.target.files[0].size / 1024 / 1024;
    if (fileSize <= 100) {
      this.files.video = event.target.files[0];
    } else {
      this.snackbar.open('El archivo debe pesar menos de 100MB', 'OK', {
        duration: 5000,
        panelClass: ['indigo-snackbar'],
      });
    }
  }

  onVideoDelete() {
    this.files.video = null;
  }

  async getAlumnos(): Promise<any> {
    this.isLoading = true;
    this.alumnoCtrl.disable();
    this.dataService.getAllAlumnos().subscribe((res) => {
      res.forEach((alumno) => {
        if (
          typeof alumno !== 'string' &&
          alumno.gr_no !== localStorage.getItem('sis_id')
        ) {
          this.alumnos.push({
            id: alumno.gr_no,
            name: alumno.name,
            mail: alumno.email,
            img: '',
          });
        }
      });
      this.isLoading = false;
      this.alumnoCtrl.enable();
    });
  }

  private _filterAlumnos(value): ContestMember[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      if (this.alumnos.length > 0) {
        return this.alumnos.filter((alumno) =>
          alumno.name.toLowerCase().includes(filterValue)
        );
      }
    } else {
      return this.alumnos;
    }
  }

  selectAlumno(alumno) {
    this.memberForm.patchValue({
      id: alumno.id,
      name: alumno.name,
      mail: alumno.mail,
      phone: '',
      study_level: '',
    });
  }

  public displayProperty(value): void {
    if (value) {
      return value.name;
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onFormSubmit() {
    this.setWaiting();
    this.isLoading = true;
    document.getElementById('impulso').classList.add('blur');
    this.formData.append(
      'nombre_proyecto',
      this.mainForm.value.nombre_proyecto
    );
    this.formData.append(
      'capitan',
      JSON.stringify({
        id: this.user.legajo,
        name: this.user.apellido,
        mail: this.user.email,
        phone: this.user.movil,
      })
    );
    this.formData.append('equipo', JSON.stringify(this.miembrosEquipo));
    this.formData.append('propuesta', this.files.propuesta);
    this.formData.append('video', this.files.video);

    this.contestService.sendImpulsoInscription(this.formData).subscribe(
      (res) => {
        document.getElementById('impulso').classList.remove('blur');
        this.setWaiting();
        this.availableUser = false;
        this.checkUser(this.user);
        this.isLoading = false;
        this.files.propuesta = null;
        this.files.video = null;
      },
      (err) => {
        document.getElementById('impulso').classList.remove('blur');
        this.setWaiting();
        this.checkUser(this.user);
        this.isLoading = false;
      }
    );
  }

  onFilesSubmit() {
    this.setWaiting();
    this.isLoading = true;
    const updateFormData = new FormData();
    updateFormData.append('propuesta', this.files.propuesta);
    updateFormData.append('video', this.files.video);
    updateFormData.append('id_equipment', this.project.id);
    updateFormData.append(
      'url_folder',
      this.project.documentacion_drive.replace(
        'https://drive.google.com/drive/folders/',
        ''
      )
    );
    this.contestService.updateDocs(updateFormData).subscribe(
      (res) => {
        this.setWaiting();
        this.snackbar.open('Documentos actualizados', 'OK', {
          duration: 5000,
          panelClass: ['success-snackbar'],
        });
        this.isLoading = false;
        this.checkUser(this.user);
      },
      (err) => {
        this.setWaiting();
        this.snackbar.open('Error al actualizar documentos', 'OK', {
          duration: 5000,
          panelClass: ['error-snackbar'],
        });
        this.isLoading = false;
      }
    );
  }
}
