import { CompletedDialogComponent } from 'src/app/shared/components/dialogs/completed-dialog/completed-dialog.component';
import {
  ContestMember,
  ContestTeam,
} from '../../../shared/interfaces/interfaces';
import { ContestService } from '../../../shared/services/contest.service';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-upload-internacional',
  templateUrl: './upload-internacional.component.html',
  styleUrls: ['./upload-internacional.component.scss'],
})
export class UploadInternacionalComponent {
  files: any[] = [];

  @Input() capitan: ContestMember;
  @Input() team: ContestTeam;
  @Input() filesLimit = 4;

  @Output() filesEvent = new EventEmitter<any[]>();

  constructor(
    private contestService: ContestService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  /**
   * on file drop handler
   */
  onFileDropped($event): void {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files): void {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number): void {
    this.files.splice(index, 1);
    this.filesEvent.emit(this.files);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number): void {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 100);
      }
    }, 100);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>): void {
    let invalidFiles = false;
    for (const item of files) {
      if (item.size > 5000000) {
        invalidFiles = true;
      } else {
        item.progress = 0;
        this.files.push(item);
      }
    }

    if (invalidFiles) {
      this._snackBar.open(
        'Algunos de los archivos superan los 5MB y no se pudieron cargar',
        'OK',
        {
          duration: 5000,
          panelClass: ['indigo-snackbar'],
        }
      );
    }

    this.uploadFilesSimulator(0);
    this.filesEvent.emit(this.files);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  sendFiles(): void {
    this.filesEvent.emit(this.files);
  }
}
