<div>
  <div>
    <div class="dialog-header">
      <div class="title">{{ this.data.asignatura.nombre }}</div>
      <button
        class="close"
        mat-icon-button
        aria-label="Close"
        (click)="onClose()"
      >
        <mat-icon class="material-icons-round">close</mat-icon>
      </button>
    </div>
    <div class="dialog-container">
      <div mat-dialog-content>
        <!-- <img [src]="this.data.img" /> -->
        <div class="section-title">Descripción</div>
        <div class="description" *ngIf="data.asignatura.descripcion">
          {{ data.asignatura.descripcion }}
        </div>
        <div class="empty-description" *ngIf="!data.asignatura.descripcion">
          Sin descripción disponible
        </div>
        <!-- <div class="dificultad" [ngSwitch]="data.asignatura.modelo">
          <div *ngSwitchCase="'a'" class="badge-item green-bg w-text">
            Baja dedicación
          </div>
          <div *ngSwitchCase="'b'" class="badge-item yellow-bg w-text">
            Media dedicación
          </div>
          <div *ngSwitchCase="'c'" class="badge-item red-bg w-text">
            Alta dedicación
          </div>
        </div> -->
        <div class="section-title">Periodo</div>
        <div class="description" *ngIf="data.asignatura.periodo">
          {{ data.asignatura.periodo }}
        </div>
        <div class="empty-description" *ngIf="!data.asignatura.periodo">
          Sin periodo asignado
        </div>
        <div class="section-title">Horarios</div>
        <ul
          *ngIf="
            data.asignatura.horarios && data.asignatura.horarios.length > 0
          "
        >
          <ng-container *ngFor="let horario of data.asignatura.horarios">
            <li class="description">{{ horario }}</li>
          </ng-container>
        </ul>
        <div
          class="empty-description"
          *ngIf="
            data.asignatura.horarios.length === 0 || !data.asignatura.horarios
          "
        >
          Sin horarios disponibles
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button color="primary" (click)="onClose()">
      Cerrar
    </button>
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="data.asignatura"
      cdkFocusInitial
    >
      {{
        data.asignatura.isSelected ? "Quitar de mi lista" : "Añadir a mi lista"
      }}
    </button>
  </div>
</div>
