<app-header [title]="title"></app-header>
<div class="container mt-5 mb-5">
  <app-cardprofile [user]="user"></app-cardprofile>

  <div class="main-container flex">
    <mat-tab-group
      mat-align-tabs="start"
      [selectedIndex]="selectedTab.value"
      (selectedIndexChange)="
        selectedTab.setValue($event);
        goTo(
          selectedTab.value === 0
            ? 'data'
            : selectedTab.value === 1
            ? 'academics'
            : 'docs'
        )
      "
    >
      <mat-tab label="Datos Personales">
        <ng-template matTabContent>
          <div class="content">
            <app-form [user]="user" [countries]="countries"></app-form>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Formación Académica">
        <ng-template matTabContent>
          <div class="content" *ngIf="areasEspecialidad">
            <app-formacion-academica
              [universidades]="universidades"
              [countries]="countries"
              [userSpecialities]="userSpecialities"
              [allSpecialities]="allSpecialities"
              [areasEspecialidad]="areasEspecialidad"
              [idiomas]="idiomas"
            ></app-formacion-academica>
          </div>
          <div class="content" *ngIf="!areasEspecialidad">
              <div class="center">
                <mat-spinner></mat-spinner>
              </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Documentación">
        <ng-template matTabContent>
          <div class="content">
            <div class="container" *ngIf="isLoading && !noDocs">
              <div class="center">
                <mat-spinner></mat-spinner>
              </div>
            </div>
            <div class="container" *ngIf="!isLoading && noDocs">
              <div class="center">
                <mat-icon class="material-icons-round">error</mat-icon>
                <div class="text">
                  No pudimos encontrar el listado de documentos
                </div>
                <button mat-flat-button color="primary" (click)="getDocs()">
                  Reintentar
                </button>
              </div>
            </div>
            <div class="main-grid" *ngIf="!isLoading && !noDocs">
              <div class="banner-doc-info">
                <p><strong>Formatos aceptados:</strong> PDF, JPG, JPEG o PNG</p>
                <p><strong>Tamaño máximo:</strong> 10MB por archivo</p>
              </div>
              <div class="warn-container">
                <iconify-icon icon="ri:alert-line"></iconify-icon>
                <div class="text-warn">Recuerda: Los formatos aceptados son PDF, JPG, JPEG o PNG. El tamaño máximo es de 10MB por archivo.</div>
              </div>
              <mat-accordion class="mat-elevation-z0">
                <ng-container
                  *ngFor="
                    let item of docsGroupByArea | keyvalue;
                    trackBy: docIdentifyer
                  "
                >
                  <mat-expansion-panel
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false"
                    class="mat-elevation-z0"
                    *ngIf="item.key !== ''"
                  >
                    <mat-expansion-panel-header>
                      <div class="header">
                        <div class="title">
                          {{ item.key === "" ? "General" : item.key }}
                        </div>
                        <div class="mid-container">
                          <div class="subtitle doc">Estado de documentacion</div>
                          <div class="progress">
                            <mat-progress-bar
                              [color]="'#35D0A5'"
                              class="example-margin"
                              mode="determinate"
                              [value]="getPorcentaje(item.value)"
                            >
                            </mat-progress-bar>
                          </div>
                          <div class="text">
                            {{ getPorcentaje(item.value) | number : "1.0-2" }}%
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="docs-grid" *ngIf="!isLoading && !noDocs">
                      <ng-container
                        *ngFor="let doc of item.value; trackBy: docsIdentifyer"
                      >
                        <app-document-card
                          *ngIf="
                            !doc.solo_extranjeros ||
                            (doc.solo_extranjeros &&
                              (user$ | async)?.escuela_pais !== 'Panama')
                          "
                          [userDoc]="doc"
                          [changeDocState]="changeDocState"
                        ></app-document-card>
                      </ng-container>
                    </div>
                  </mat-expansion-panel>
                </ng-container>
              </mat-accordion>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
