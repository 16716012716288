<div>
  <h1>Ingresa el número de ticket</h1>
  <div class="form">
    <mat-form-field
      style="width: 100%"
      class="example-full-width"
      appearance="fill"
    >
      <mat-label>Número de ticket*</mat-label>
      <input matInput type="number" #ticket (input)="dataTicket(ticket)" />
    </mat-form-field>
    <br />
    <button
      mat-raised-button
      class="bg-primary-aden text-white px-5"
      type="submit"
      *ngIf="button === true"
      (click)="getStatusTicket()"
    >
      Comprobar ticket
    </button>
    <hr *ngIf="info !== null" />
  </div>
  <h1 *ngIf="info !== null">Revisa el estado de tu ticket</h1>
  <div class="data" *ngIf="info !== null">
    <div class="header">
      <div class="minicard">
        <div class="title">Nº TICKET</div>
        <div class="data-card">{{ info.ticket_number }}</div>
      </div>
      <div class="minicard">
        <div class="title">ESTADO</div>
        <div class="data-card">{{ info.status }}</div>
      </div>
      <div class="minicard">
        <div class="title">FECHA EMISIÓN</div>
        <div class="data-card">{{ info.create_date }}</div>
      </div>
    </div>
    <div class="body">
      <div class="data-grid">
        <div class="sub-body">
          <div class="title-body">Área responsable</div>
          <div class="value-body">{{ info.area }}</div>
        </div>
        <div class="sub-body">
          <div class="title-body">Tipo de caso</div>
          <div class="value-body">{{ info.case }}</div>
        </div>
        <div class="sub-body">
          <div class="title-body">Categoría</div>
          <div class="value-body">{{ info.category }}</div>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="row">
        <div class="col-12">
          <div class="sub-body">
            <div class="title-body">Asunto</div>
            <div class="value-body">{{ info.issue }}</div>
          </div>
        </div>
        <div class="col-12">
          <div class="sub-body">
            <div class="title-body">Descripción</div>
            <div class="value-body">{{ info.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
