import { universities } from './../../../../../shared/data/universities';
import { startWith, map, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { DataService } from 'src/app/shared/services/data.service';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment, { default as _rollupMoment, Moment } from 'moment';
import { FormControl, UntypedFormControl } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';

export interface DialogData {
  universidades?: any;
  paises?: any;
  defaultData?: any,
  areasEspecialidad?: any,
  states?: any,
  idiomas?: any;
  estudio?: any;
  certificado?: any;
  tipo: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    yearA11yLabel: 'YYYY',
  },
};

export const MY_FORMATS_CERTIFICADO = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    yearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {
      provide: MAT_DATE_FORMATS,
      useFactory: (data: DialogData) => {
        return data.tipo === 'certificado' ? MY_FORMATS_CERTIFICADO : MY_FORMATS;
      },
      deps: [MAT_DIALOG_DATA],
    },
  ],
})
export class CreateDialogComponent implements OnInit {
  estudiosForm: UntypedFormGroup;
  certificadosForm: UntypedFormGroup;
  universityInputError = false;
  superiorForm: UntypedFormGroup;
  selectedCountry;
  loading = false;
  filteredUnis: Observable<any[]>;
  selectedUniversity: number;
  universidadesCtrl = new UntypedFormControl();
  isFile = false;
  fileChanged = false;
  universityNotFound = false;
  idiomaCtrl = new UntypedFormControl(0, Validators.required);
  areaCtrl = new UntypedFormControl([], [Validators.required, this.minMaxSelectedValidator(1, 2)]);

  moment = moment;
  pageTitle = 'Añadir estudio';

  constructor(
    public dialogRef: MatDialogRef<CreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dataService: DataService,
    private formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  universidadValidator(universidades: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputValue = control.value;
      const foundUniversity = universidades.find(
        uni => {
          if(inputValue && !inputValue.university_id){
            return uni.university_name.toLowerCase() === inputValue?.toLowerCase()
          }
          return true;
        }
      );
      if (!foundUniversity) {
        return { notFound: true };
      }
      return null;
    };
  }

  ngOnInit(): void {
    this.universidadesCtrl.setValidators([
      Validators.required, // Agrega el validador requerido predeterminado
      this.universidadValidator(this.data.universidades) // Agrega el validador personalizado
    ]);
    if (this.data.tipo === 'estudio') {
      this.filteredUnis = this.universidadesCtrl.valueChanges.pipe(
        startWith(''),
        debounceTime(500),
        map((uni) => {
          console.log(this._filterUnis(uni))
          return this.data.universidades && this.data.universidades.length > 0
            ? this._filterUnis(uni)
            : this.data.universidades.slice();
        })
      );

      this.pageTitle = this.data.estudio
        ? 'Editar estudios'
        : 'Añadir estudios';

      if (this.data.estudio) {
        this.estudiosForm = this.formBuilder.group({
          estudio_id: [this.data.estudio.id],
          pais_id: [
            this.parsePais(this.data.estudio.pais),
            Validators.required,
          ],
          titulo: [this.data.estudio.titulo, Validators.required],
          ano_graduacion: [
            moment(this.data.estudio.ano_graduacion, 'YYYY'),
            Validators.required,
          ],
        });
        this.setSelectedUni(this.data.estudio.universidad);
        this.universidadesCtrl.setValue(
          this.getUniversidad(this.data.estudio.universidad)
        );
      } else {
        this.estudiosForm = this.formBuilder.group({
          estudio_id: [0],
          pais_id: [1, Validators.required],
          titulo: ['', Validators.required],
          ano_graduacion: [moment(), Validators.required],
        });
      }
    }

    if(this.data.tipo === 'certificado'){
      if(this.data.certificado){
        this.certificadosForm = this.formBuilder.group({
          id: [this.data.certificado.id],
          title: [this.data.certificado.title, Validators.required],
          entity: [this.data.certificado.entity, Validators.required],
          theme: [this.data.certificado.theme, Validators.required],
          file: [
            {
              file_name: this.data.certificado.file_name, 
              file_size: this.data.certificado.file_size
            }, 
          Validators.required
        ],
          date: [ this.data.certificado.date, 
            Validators.required
          ],
          hours: [this.data.certificado.hours]
        });
        if(this.certificadosForm.get('file').value){
          this.isFile = true;
        }
      }else{
        this.certificadosForm = this.formBuilder.group({
          date: [
            this.data.certificado?.date
              ? moment(this.data.certificado.date, 'MM/DD/YYYY')
              : moment(),
            Validators.required,
          ],
          title: ['', Validators.required],
          theme: ['', Validators.required],
          entity: ['', Validators.required],
          file: ['', Validators.required],
          hours: [0]
        });
      }
    }

    if (this.data.tipo === 'idioma') {
      this.pageTitle = 'Añadir idioma';
    }
    if (this.data.tipo === 'area') {
      this.pageTitle = 'Añadir area';
    }

    if(this.data.tipo === 'superior'){
      console.log(this.data.defaultData.area_especialidad.name)
      this.superiorForm = this.formBuilder.group({
        area_especialidad: [ this.data.defaultData.area_especialidad.id, Validators.required],
        estudios_docencia_superior: ['', Validators.required],
      });
      if(this.data.defaultData.estudios_docencia_superior){
        this.superiorForm.setValue({
          area_especialidad: this.data.defaultData.area_especialidad.id,
          estudios_docencia_superior:
            this.data.defaultData.estudios_docencia_superior,
        });
      }
    }
  }
  transformBytes(bytes: number): number{
    return Math.round(bytes / 1024) 
  }

  deleteFile(){
    this.certificadosForm.get('file').setValue(false);
    this.isFile = false;
  }

   guardarConfig() {
    const saveSubmit = document.getElementById('save-button');
    if (this.superiorForm.status === 'VALID') {
      saveSubmit.innerText = 'Guardando...';
      this.dataService
        .updateAcademicsProfesor(
          this.superiorForm.get('estudios_docencia_superior').value,
          this.superiorForm.get('area_especialidad').value
          
        )
        .subscribe(
          (data) => {
            saveSubmit.innerText = 'Guardar';
            this.dataService.openAlert(
              '¡Estudios guardados!',
              'Tus estudios se cargaron con éxito',
              'Cerrar'
            );
            this.dialogRef.close('ok');
          },
          (err) => {
            this._snackBar.open(
              'Ha ocurrido un error al generar el registro',
              'Cerrar',
              {
                duration: 7000,
              }
            );
          }
        );
    } else {
      this._snackBar.open(
        'No se han completado todos los campos obligatorios',
        'Cerrar',
        {
          duration: 7000,
        }
      );
    }
  }

  onFileSelected(): void {
    const fileInput: HTMLInputElement = document.querySelector('#file');

    if (fileInput.files && fileInput.files[0]) {
      const selectedFile: File = fileInput.files[0];
      const reader: FileReader = new FileReader();
      console.log('a')
      if(fileInput.files[0].size < 2097152){
        console.log('b')
        this.certificadosForm.get('file').setValue(fileInput.files[0]);
        this.isFile = true;
        this.fileChanged = true
      }else{
        this.certificadosForm.get('file').setValue(null);
        this.isFile = false;    
        this._snackBar.open(
          'Solo se admiten archivos de 2 megas o menos',
          'Cerrar',
          {
            duration: 6000,
          }
        );
      }
  
      // reader.onload = (event) => {
      //   const base64Data: string = event.target.result as string;
      // };
      reader.readAsDataURL(selectedFile);
    }
  }


  private _filterUnis(value): any[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      const filteredUniversities = this.data.universidades.filter(uni =>
        uni.university_name.toLowerCase().includes(filterValue)
      );

      this.universityNotFound = filteredUniversities.length === 0;
      console.log(filteredUniversities.length === 0)

      return filteredUniversities;
    } else {
      this.selectedUniversity = value.university_id;
      return this.data.universidades;
    }
  }

  public displayProperty(value): void {
    if (value) {
      return value.university_name;
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  compareCountry(o1: any, o2: any): any {
    return o1 && o2 && o1 === o2;
  }

  setDate(date: Moment, datepicker:MatDatepicker<Moment>){
    console.log(date, datepicker)
    datepicker.close()
  }

  setYear(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.estudiosForm.get('ano_graduacion').value!;
    ctrlValue.year(normalizedYear.year());
    this.estudiosForm.get('ano_graduacion').setValue(ctrlValue);
    datepicker.close();
  }

  parseUniversidad(selection): number {
    for (const uni of this.data.universidades) {
      if (selection.toLowerCase() === uni.university_name.toLowerCase()) {
        return uni.university_name;
      }
    }
  }

  setSelectedUni(selection) {
    this.selectedUniversity = this.getUniversidad(selection).university_id;
  }

  getUniversidad(selection) {
    return this.data.universidades.find(
      (uni) => uni.university_name.toLowerCase() === selection.toLowerCase()
    );
  }

  parsePais(selection): number {
    for (const pais of this.data.paises) {
      if (selection.toLowerCase() === pais.name.toLowerCase()) {
        return pais.id;
      }
    }
  }

  onSubmit(): void {
    if(this.data.tipo === 'estudio'){
      const saveSubmit = document.getElementById('create-estudio');
      if (this.estudiosForm.status === 'VALID') {
        this.loading = true;
        saveSubmit.innerText = 'Guardando...';
  
        const data = {
          ...this.estudiosForm.value,
          universidad_id: this.selectedUniversity,
          ano_graduacion: this.estudiosForm.get('ano_graduacion').value.year(),
        };
  
        const peticion = this.data.estudio
          ? this.dataService.updateEstudios(data)
          : this.dataService.addEstudios(data);
  
        peticion.subscribe(
          (data) => {
            saveSubmit.innerText = 'Guardar cambios';
  
            this.loading = false;
            this.dataService.openAlert(
              '¡Estudios guardados!',
              'Tus estudios se cargaron con éxito',
              'Cerrar'
            );
            this.dialogRef.close('ok');
          },
          (err) => {
            saveSubmit.innerText = 'Guardar cambios';
            this.loading = false;
            this._snackBar.open(
              'Ha ocurrido un error al generar el registro',
              'Cerrar',
              {
                duration: 7000,
              }
            );
          }
        );
      } else {
        this._snackBar.open(
          'No se han completado todos los campos obligatorios',
          'Cerrar',
          {
            duration: 7000,
          }
        );
      }
    }
    if(this.data.tipo === 'certificado'){
      const saveSubmit = document.getElementById('create-certificado');
      if (this.certificadosForm.status === 'VALID' && this.certificadosForm.get('file').value) {
        this.loading = true;
        saveSubmit.innerText = 'Guardando...';
  
        const formattedDate = moment(this.certificadosForm.get('date').value).format('MM/DD/YYYY');

        const data = {
          ...this.certificadosForm.value,
          date: formattedDate,
        };
        console.log(data)
  
        const peticion = this.data.certificado
          ? this.dataService.updateCertificados(data, this.fileChanged)
          : this.dataService.addCertificado(data);
  
        peticion.subscribe(
          (data) => {
            saveSubmit.innerText = 'Guardar cambios';
  
            this.loading = false;
            this.dataService.openAlert(
              '¡Estudios guardados!',
              'Tus estudios se cargaron con éxito',
              'Cerrar'
            );
            this.dialogRef.close('ok');
          },
          (err) => {
            saveSubmit.innerText = 'Guardar cambios';
            this.loading = false;
            this._snackBar.open(
              'Ha ocurrido un error al generar el registro',
              'Cerrar',
              {
                duration: 7000,
              }
            );
          }
        );
      } else {
        this._snackBar.open(
          'No se han completado todos los campos obligatorios',
          'Cerrar',
          {
            duration: 7000,
          }
        );
      }
    }

  }

  postIdioma() {
    this.loading = true;
    const saveSubmit = document.getElementById('create-estudio');
    saveSubmit.innerText = 'Guardando...';
    this.dataService.addIdioma(this.idiomaCtrl.value).subscribe(
      (data) => {
        saveSubmit.innerText = 'Guardar cambios';

        this.loading = false;
        this.dataService.openAlert(
          '¡Idioma guardado!',
          'El idioma se cargó con éxito',
          'Cerrar'
        );
        this.dialogRef.close('ok');
      },
      (err) => {
        saveSubmit.innerText = 'Guardar cambios';
        this.loading = false;
        this._snackBar.open(
          'Ha ocurrido un error al generar el registro',
          'Cerrar',
          {
            duration: 7000,
          }
        );
      }
    );
  }
  postArea() {
    this.loading = true;
    const saveSubmit = document.getElementById('create-estudio');
    saveSubmit.innerText = 'Guardando...';
    this.dataService.addSpecialty(this.areaCtrl.value[0]).subscribe(
      (data) => {
        saveSubmit.innerText = 'Guardar cambios';
        this.loading = false;
        this.dataService.openAlert(
          '¡Área guardada!',
          'El área se cargó con éxito',
          'Cerrar'
        ); 
        this.dialogRef.close('ok');
      },
      (err) => {
        saveSubmit.innerText = 'Guardar cambios';
        this.loading = false;
        this._snackBar.open(
          'Ha ocurrido un error al generar el registro',
          'Cerrar',
          {
            duration: 7000,
          }
        );
      }
    );
  }
  minMaxSelectedValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedValues = control.value;
      if (Array.isArray(selectedValues) && selectedValues.length >= min && selectedValues.length <= max) {
        return null;
      }
      return { minMaxSelected: true };
    };
  }
}
