import { ProgramaImpulsoComponent } from './pages/ceaden/programa-impulso/programa-impulso.component';
import { CeadenComponent } from './pages/ceaden/ceaden.component';
import { CheckBlockingGuard } from './shared/guards/check-blocking.guard';
import { MovilidadDocentesComponent } from './pages/adenu-internacional/movilidad-docentes/movilidad-docentes.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { ConcursoComponent } from './pages/ceaden/aprende-emprende/concurso.component';
import { ContestLayoutComponent } from './shared/layouts/contest-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { HomeLayoutComponent } from './shared/layouts/home-layaot.component';
import { LoginLayoutComponent } from './shared/layouts/login-layout.component';
import { PeticionesComponent } from './pages/peticiones/peticiones.component';
import { EstadoFinancieroComponent } from './pages/estado-financiero/estado-financiero.component';
import { AdenuInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/adenu-internacional.component';
import { NotificacionesComponent } from './pages/notificaciones/notificaciones.component';
import { NotasComponent } from './pages/notas/notas.component';
import { PlanEstudioComponent } from './pages/plan-estudio/plan-estudio.component';
import { CourseComponent } from './pages/course/course.component';
import { CourseViewerComponent } from './pages/course/course-viewer/course-viewer.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      /* {
        path: 'eventos',
        component: EventosComponent,
        canActivate: [AuthGuard],
      }, */

      {
        path: 'adenu-internacional',
        component: MovilidadDocentesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'perfil',
        component: PerfilComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'courses',
        component: CourseComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'course/:id',
        component: CourseViewerComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'pagos',
        component: EstadoFinancieroComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'ceaden',
        component: CeadenComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'ceaden/reto-aprende-y-emprende',
        component: ConcursoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'ceaden/programa-impulso',
        component: ProgramaImpulsoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'notificaciones',
        component: NotificacionesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'notas',
        component: NotasComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'plan-estudio',
        component: PlanEstudioComponent,
        canActivate: [AuthGuard],
      }
    ],
  },
  {
    path: '',
    component: LoginLayoutComponent,

    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [CheckBlockingGuard],
      },
    ],
  },

  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'movilidad-docentes',
        component: MovilidadDocentesComponent,
      },
    ],
  },

  // { path: '', pathMatch: 'full', redirectTo: 'login'},
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 240],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
