import { Factura } from '../../../../shared/interfaces/interfaces';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-payment-table',
  templateUrl: './payment-table.component.html',
  styleUrls: ['./payment-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PaymentTableComponent implements OnInit {
  @Input() facturas: Factura[];

  dataSource;

  public searchValue = '';

  public tableColumns = [
    {
      columnDef: 'fecha',
      header: 'Fecha',
      cell: (factura: Factura) => `${factura.fecha}`,
    },
    {
      columnDef: 'estado',
      header: 'Estado',
      cell: (factura: Factura) =>
        new Date(factura.vencimiento) < new Date() && factura.Saldo > 0
          ? 'Vencido'
          : factura.Saldo > 0
          ? 'Abierto'
          : 'Pagado',
    },
    {
      columnDef: 'comprobante',
      header: 'Comprobante',
      cell: (factura: Factura) => [
        `Nº ${factura.comprobante}`,
        factura.detalle,
      ],
    },
    {
      columnDef: 'cuotas',
      header: 'Cant. Cuotas',
      cell: (factura: Factura) => `${factura.cuotas}`,
    },
    {
      columnDef: 'vencimiento',
      header: 'Vencimiento',
      cell: (factura: Factura) => `${factura.vencimiento}`,
    },
    {
      columnDef: 'total',
      header: 'Total facturado',
      cell: (factura: Factura) => `${factura.total}`,
    },
    {
      columnDef: 'Saldo',
      header: 'Saldo',
      cell: (factura: Factura) => `${factura.Saldo}`,
    },
  ];

  public data: Factura[] = [
    {
      Saldo: 0,
      comprobante: 'FC - 45487487',
      cuotas: 5,
      detalle: 'Compra de creditos',
      fecha: '2021-06-12',
      move_line: [
        {
          conciliado: 'SI',
          cuota: 1,
          deuda: 0,
          fecha_pago: '2021-06-12',
          fecha_vencimiento: '2021-06-12',
          monto: 400,
        },
        {
          conciliado: 'SI',
          cuota: 2,
          deuda: 0,
          fecha_pago: '2021-06-12',
          fecha_vencimiento: '2021-06-12',
          monto: 400,
        },
        {
          conciliado: 'SI',
          cuota: 3,
          deuda: 0,
          fecha_pago: '2021-06-12',
          fecha_vencimiento: '2021-06-12',
          monto: 400,
        },
        {
          conciliado: 'SI',
          cuota: 4,
          deuda: 0,
          fecha_pago: '2021-06-12',
          fecha_vencimiento: '2021-06-12',
          monto: 400,
        },
        {
          conciliado: 'SI',
          cuota: 5,
          deuda: 0,
          fecha_pago: '2021-06-12',
          fecha_vencimiento: '2021-06-12',
          monto: 400,
        },
      ],
      total: 2000,
      vencimiento: '2021-06-12',
    },
    {
      Saldo: 200,
      comprobante: 'FC - 78784959',
      cuotas: 3,
      detalle: 'Compra de creditos',
      fecha: '2021-06-12',
      move_line: [
        {
          conciliado: 'SI',
          cuota: 1,
          deuda: 0,
          fecha_pago: '2021-06-12',
          fecha_vencimiento: '2021-06-12',
          monto: 200,
        },
        {
          conciliado: 'SI',
          cuota: 2,
          deuda: 0,
          fecha_pago: '2021-06-12',
          fecha_vencimiento: '2021-06-12',
          monto: 200,
        },
        {
          conciliado: 'SI',
          cuota: 3,
          deuda: 0,
          fecha_pago: '',
          fecha_vencimiento: '2021-06-12',
          monto: 200,
        },
      ],
      total: 600,
      vencimiento: '2021-06-12',
    },
    {
      Saldo: 200,
      comprobante: 'FC - 48784754',
      cuotas: 5,
      detalle: 'Compra de creditos',
      fecha: '2021-06-12',
      move_line: [
        {
          conciliado: 'SI',
          cuota: 1,
          deuda: 0,
          fecha_pago: '2021-06-12',
          fecha_vencimiento: '2021-06-12',
          monto: 200,
        },
        {
          conciliado: 'SI',
          cuota: 2,
          deuda: 0,
          fecha_pago: '',
          fecha_vencimiento: '2021-06-12',
          monto: 200,
        },
        {
          conciliado: 'SI',
          cuota: 3,
          deuda: 0,
          fecha_pago: '',
          fecha_vencimiento: '2021-08-30',
          monto: 200,
        },
      ],
      total: 600,
      vencimiento: '2021-08-20',
    },
  ];

  public columnsToDisplay = this.tableColumns.map((c) => c.columnDef);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor() {}

  ngOnInit(): void {
    this.renderTable();
  }

  renderTable(): void {
    this.dataSource = new MatTableDataSource(this.facturas);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search(e): void {
    const filterValue = (e.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cleanSearch(): void {
    this.searchValue = '';
    this.dataSource.filter = this.searchValue.trim().toLowerCase();
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}
