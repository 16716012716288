import { UntypedFormControl } from '@angular/forms';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { CarreraPrincipal, NotasCarreras } from './../../shared/interfaces/interfaces';
import { Observable } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DataService } from 'src/app/shared/services/data.service';
import { GradesState } from 'src/app/redux/reducers/grades.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-notas',
  templateUrl: './notas.component.html',
  styleUrls: ['./notas.component.scss'],
})
export class NotasComponent implements OnInit {
  public notes = [];
  public allNotes: number;
  title = 'Mis notas';
  public user$: Observable<any>;

  public carreras: NotasCarreras[];

  isLoading = false;
  isError = false;

  selected = new UntypedFormControl(0);

  constructor(
    private service: DataService,
    private userStore: Store<UserState>
  ) {
    this.user$ = userStore.select('user');
  }

  ngOnInit(): void {
    this.getNotas();
  }
  getNotas(){
    this.isError = false;
    this.isLoading = true
    this.service.getNotas().subscribe({
      next: (res) =>{
        this.carreras = res.data;
        this.isLoading = false;
        this.isError = this.carreras[0].career_lines?.length ? false : true;
      },
      error: (res) =>{
        this.isError = true;
        this.isLoading = false;
      }
    })
  }
}
