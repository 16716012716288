<app-header [title]="title"></app-header>
<div class="container">
  <mat-tab-group>
    <mat-tab label="Nuevo ticket">
        <div class="card-aden-plain">
            <app-nuevo-ticket></app-nuevo-ticket>
        </div>
    </mat-tab>
    <mat-tab label="Consultar estado ticket">
        <div class="card-aden-plain">
            <app-estado-ticket></app-estado-ticket>
        </div>
    </mat-tab>
  </mat-tab-group>
</div>
