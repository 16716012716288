import { CompletedDialogComponent } from '../../../../shared/components/dialogs/completed-dialog/completed-dialog.component';
import {
  CareerCourse,
  Idioma,
} from './../../../../shared/interfaces/interfaces';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { StepperOrientation } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { universities } from 'src/app/shared/data/universities';
import { ContestService } from 'src/app/shared/services/contest.service';
import { DataService } from 'src/app/shared/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';
import { adenuCareers } from 'src/app/shared/data/adenuCareers';
import { ProfesorMailValidator } from 'src/app/shared/validators/movilidad-email-validators';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-formulario-docentes',
  templateUrl: './formulario-docentes.component.html',
  styleUrls: ['./formulario-docentes.component.scss'],
})
export class FormularioDocentesComponent implements OnInit {
  @Input() makeContact: any;

  isLoading = false;
  isWaiting = false;
  abledUser = true;

  public user = JSON.parse(localStorage.getItem('userData'));

  public emailAvailable = false;

  public countries;
  public selectedNationality;
  public selectedCountry;
  public files: any[] = [];
  public formData: any;

  public careers = adenuCareers;
  public selectedCareer;

  public courses: CareerCourse[] = [];
  /* public dictatedCurses: CareerCourse[] = []; */

  personalDataFormGroup: UntypedFormGroup;
  academicDataFormGroup: UntypedFormGroup;
  languagesDataFormGroup: UntypedFormGroup;
  filesDataFormGroup: UntypedFormGroup;

  languagesData: Idioma[] = [];

  stepperOrientation: Observable<StepperOrientation>;
  @ViewChild('stepper') stepper: MatStepper;

  public universities = universities.filter((uni) => !uni.externalInscription);

  public cronograma = [
    {
      id: 1,
      date: '1 DIC - 1 MAR',
      descripcion: '¡Postulaciones abiertas!',
    },
    {
      id: 2,
      date: '2 - 21 MAR',
      descripcion: 'Análisis y selección de docentes',
    },
    {
      id: 3,
      date: '22 MAR',
      descripcion: 'Comunicación de docentes seleccionados',
    },
    {
      id: 4,
      date: '25 MAR',
      descripcion: 'Entrevista a docentes seleccionados',
    },
    {
      id: 5,
      date: '15 ABR',
      descripcion: 'Inicio de postulación a universidades extranjeras',
    },
  ];

  constructor(
    private contestService: ContestService,
    private dataService: DataService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    breakpointObserver: BreakpointObserver
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 1000px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  ngOnInit(): void {
    this.getCountries();
    /* this.getCareers(); */
    this.personalDataFormGroup = this.formBuilder.group({
      name: [this.user.nombre, Validators.required],
      last_name: ['', Validators.required],
      date_of_birth: [this.user.fecha_nacimiento, Validators.required],
      nationality: [this.user.nacionalidad, Validators.required],
      passport: ['', Validators.required],
      passport_expiration: ['', Validators.required],
      contact: this.formBuilder.group({
        email: [this.user.correo_academico, [Validators.required, Validators.email]],
        phone: new UntypedFormControl({ value: this.user.movil, disabled: false }, [
          Validators.required,
        ]),
      }),
      address: this.formBuilder.group({
        current_address: [this.user.direccion, Validators.required],
        location: ['', Validators.required],
        country: ['', Validators.required],
      }),
    });
    this.academicDataFormGroup = this.formBuilder.group({
      grad_title: ['', Validators.required],
      postgrad_title: this.formBuilder.group({
        specialization: [''],
        master: [''],
        doctorate: [''],
      }),
      dictatedCareer: this.formBuilder.group({
        name: ['', Validators.required],
        mode: ['', Validators.required],
      }),
      dictatedCourses: this.formBuilder.array([]),
      expertiseArea: ['', Validators.required],
      selectedUniversity: ['', Validators.required],
    });
    this.addDictatedCourse();
    /* this.languagesDataFormGroup = this.formBuilder.group({
      languaje: [false, Validators.required],
      list: this.formBuilder.array([]),
    }); */
    this.filesDataFormGroup = this.formBuilder.group({
      passport: ['', Validators.required],
      cv: [''],
      guaranteeLetter: [''],
      swornDeclaration: [''],
      workPlan: [''],
    });
  }

  checkEmail(): void {
    this.contestService
      .checkProfesor(
        this.personalDataFormGroup.get('contact').get('email').value
      )
      .subscribe((data) => {
        if (data) {
          this.emailAvailable = data.avaliable;

          if (data.avaliable) {
            this.emailAvailable = true;
            this.stepper.next();
          } else {
            this.emailAvailable = false;
            this.dataService.openAlert(
              '¡El email ingresado ya existe!',
              'El correo electrónico de contacto ingresado ya ha sido utilizado por otro postulante, ingresa otro email de contacto para continuar',
              'OK'
            );
          }
        }
      });
  }

  getCountries(): void {
    this.dataService.getPaises().subscribe((data) => {
      this.countries = data;
    });
  }

  getCareers(): void {
    this.dataService.getCarreras().subscribe((data) => {
      this.careers = data;
      this.isLoading = false;
    });
  }

  compareOptions(o1: any, o2: any): any {
    return o1 && o2 && o1 === o2;
  }

  /* FILES MANAGEMENT */

  setFiles(newFile): void {
    this.files = newFile;
    if (this.files.length >= 5) {
      this.filesDataFormGroup.get('passport').setValue('Cargado');
    } else {
      this.filesDataFormGroup.get('passport').setValue('');
    }
    this.createFormData();
  }

  /* LANGUAGES LIST */
  get list(): UntypedFormArray {
    return this.languagesDataFormGroup.get('list') as UntypedFormArray;
  }

  addLanguage(): void {
    const languageForm = this.formBuilder.group({
      name: ['', Validators.required],
      level: ['', Validators.required],
      national_certificate: [false, Validators.required],
      international_certificate: [false, Validators.required],
    });

    this.list.push(languageForm);
  }

  emptyList(): void {
    this.languagesDataFormGroup
      .get('list')
      .setValue(this.formBuilder.array([]));
  }

  deleteLanguage(languageId: number): void {
    this.list.removeAt(languageId);
  }

  /* COURSES LIST */
  get dictatedCourses(): UntypedFormArray {
    return this.academicDataFormGroup.get('dictatedCourses') as UntypedFormArray;
  }

  addDictatedCourse(): void {
    const courseForm = this.formBuilder.group({
      name: ['', Validators.required],
      mode: ['', Validators.required],
    });

    this.dictatedCourses.push(courseForm);
  }

  emptyCourseList(): void {
    this.academicDataFormGroup
      .get('dictatedCourses')
      .setValue(this.formBuilder.array([]));
  }

  deleteCourse(courseId: number): void {
    this.dictatedCourses.removeAt(courseId);
  }

  /* SELECTED CAREER */

  setSelectedCareer(): void {
    const name = this.academicDataFormGroup
      .get('dictatedCareer')
      .get('name').value;
    this.selectedCareer = this.careers.find((career) => career.name === name);
  }

  /* DIÁLOGO SUCCESS */

  openCompletedDialog(type, title, description, img): void {
    const dialogRef = this.dialog.open(CompletedDialogComponent, {
      width: 'auto',
      data: { type, title, description, img, dialogId: 'dialog-international' },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  /* COMPLETAR INSCRIPCIÓN */

  createFormData(): void {
    this.formData = {
      personal_data: {
        name: this.personalDataFormGroup.get('name').value,
        last_name: this.personalDataFormGroup.get('last_name').value,
        date_of_birth: this.personalDataFormGroup.get('date_of_birth').value,
        nationality: this.personalDataFormGroup.get('nationality').value,
        passport: this.personalDataFormGroup.get('passport').value,
        passport_expiration: this.personalDataFormGroup.get(
          'passport_expiration'
        ).value,
      },
      contact: this.personalDataFormGroup.get('contact').value,
      address: this.personalDataFormGroup.get('address').value,
      /* languajes: this.languagesDataFormGroup.value, */
      academic_data: this.academicDataFormGroup.value,
      documentation: this.filesDataFormGroup.value,
    };
  }

  completeInscription(): void {
    this.isWaiting = true;

    const sendBtnText = document.getElementById('send-text');
    document.getElementById('loader').classList.remove('no-display');
    document.getElementById('completeInscription').classList.add('transparent');
    document
      .getElementById('completeInscription')
      .setAttribute('disabled', 'true');
    sendBtnText.innerHTML = 'Enviando ';

    const filesData = new FormData();

    filesData.append(
      'student',
      `${this.formData.personal_data.name} ${this.formData.personal_data.last_name}`
    );

    filesData.append('passport', this.files[0]);
    filesData.append('cv', this.files[1]);
    filesData.append('guaranteeLetter', this.files[2]);
    filesData.append('swornDeclaration', this.files[3]);
    filesData.append('workPlan', this.files[4]);

    if (this.files.length > 5) {
      for (let i = 5; i < this.files.length; i++) {
        filesData.append('others', this.files[i]);
      }
    }

    this.contestService.createProfesor(this.formData).subscribe(
      (res) => {
        filesData.append('id', res.response_data._id);
        this.contestService.uploadProfesorDocs(filesData).subscribe(
          (files) => {
            this.isWaiting = false;
            this.openCompletedDialog(
              'success',
              '¡Inscripción completada con éxito!',
              'Tu postulación fue enviada, recibirás la confirmación en tu mail',
              '../../../../../assets/images/content/world-internacional.png'
            );

            this.abledUser = false;

            document.getElementById('loader').classList.add('no-display');
            document
              .getElementById('completeInscription')
              .classList.remove('transparent');
            document
              .getElementById('completeInscription')
              .removeAttribute('disabled');
            sendBtnText.innerHTML = 'Completar inscripción';
          },
          (err) => {
            this.isWaiting = false;
            this.openCompletedDialog(
              'error',
              '¡Ups!',
              'Ocurrió un error inesperado, por favor intenta de nuevo más tarde',
              '../../../../../assets/images/content/error-blue.png'
            );

            document.getElementById('loader').classList.add('no-display');
            document
              .getElementById('completeInscription')
              .classList.remove('transparent');
            document
              .getElementById('completeInscription')
              .removeAttribute('disabled');
            sendBtnText.innerHTML = 'Completar inscripción';
          }
        );
      },
      (err) => {
        this.isWaiting = false;
        this.openCompletedDialog(
          'error',
          '¡Ups!',
          'Ocurrió un error inesperado, por favor intenta de nuevo más tarde',
          '../../../../../assets/images/content/error-blue.png'
        );
        document.getElementById('loader').classList.add('no-display');
        document
          .getElementById('completeInscription')
          .classList.remove('transparent');
        document
          .getElementById('completeInscription')
          .removeAttribute('disabled');
        sendBtnText.innerHTML = 'Completar inscripción';
      }
    );
  }
}
