import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from './shared/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit  {
  title = 'faculty-adenu-panama';
  visible = false;

  constructor() {}

  ngOnInit() {
    localStorage.removeItem('estadoBloqueos');
  }

  public user = {
    nombre: 'Pedro',
    apellido: 'Sanchez',
    rol: {
      denominacion: 'admin',
    },
  };

  isHandset = true;
}
