<div>
  <div class="dialog-header">
    <button
      class="close"
      mat-icon-button
      aria-label="Close"
      (click)="onClose()"
    >
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
  <div class="dialog-container">
    <div mat-dialog-content>
      <div class="social-text" *ngIf="data.tipo === 'pasado'">
        <div class="title">¿Te gustó este evento?</div>
        <div class="subtitle">¡Compártelo!</div>
      </div>
      <div class="social-text" *ngIf="data.tipo === 'proximo'">
        <div class="title">¿Te emociona participar de este evento?</div>
        <div class="subtitle">¡Compártelo!</div>
      </div>
      <share-buttons
        [theme]="'circles-dark'"
        [include]="['facebook', 'twitter', 'linkedin', 'whatsapp', 'telegram']"
        [show]="5"
        [url]="data.link"
        [image]="'../../../../assets/images/content/event-default.jpg'"
        [autoSetMeta]="true"
      ></share-buttons>
      <div class="link-wrapper">
        <div class="link-div">
          <input type="text" [value]="data.link" />
          <button
            mat-button
            color="primary"
            #tooltip="matTooltip"
            matTooltip="¡Copiado!"
            matTooltipPosition="below"
            [matTooltipDisabled]="disabledTooltip"
            (click)="copyLink(); tooltip.toggle()"
          >
            Copiar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
