import { Component } from '@angular/core';

@Component({
  selector: 'app-home-layout',
  template: `
    <app-navigation [showFooter]="true">
      <router-outlet></router-outlet>
    </app-navigation>
    <tour-step-template> </tour-step-template>
  `,
  styles: [],
})
export class HomeLayoutComponent {}
