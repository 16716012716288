import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-data-card',
  templateUrl: './generic-data-card.component.html',
  styleUrls: [
    './generic-data-card.component.scss',
    '../../../../../assets/themes/_colors.scss',
  ],
})
export class GenericDataCardComponent {
  @Input() label: string;
  @Input() description: string;
  @Input() value: any;
  @Input() icon: string;
  @Input() color: string = '#6181FF';

  constructor() {}

  /* ngOnInit(): void {
  }
 */
}
