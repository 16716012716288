<div id="impulso-form">
  <div class="banner" *ngIf="today < impulsoEnd">
    <h4>¡RECUERDA!</h4>
    <p>
      El formulario de inscripción estará disponible desde el
      <strong
        >{{ moment(impulsoInit).locale("es").format("LLL") + "hs" }} ({{
          moment.tz.guess().replaceAll("_", " ")
        }})</strong
      >
      hasta el
      <strong
        >{{ moment(impulsoEnd).locale("es").format("LLL") + "hs" }} ({{
          moment.tz.guess().replaceAll("_", " ")
        }})</strong
      >
    </p>
  </div>

  <div class="contador" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <div class="contador" *ngIf="today < impulsoInit && !isLoading">
    <h1>¡Las inscripciones se habilitarán pronto!</h1>
    <h4>Faltan:</h4>
    <countdown [config]="timerConfig"></countdown>
    <div class="detalle">
      <p *ngIf="this.leftTime >= 86400">Días</p>
      <p *ngIf="this.leftTime > 3600">Horas</p>
      <p>Min</p>
      <p>Seg</p>
    </div>
  </div>

  <div
    class="contador"
    *ngIf="today > impulsoEnd && availableUser && !isLoading"
  >
    <h1>¡Inscripciones cerradas!</h1>
    <h2>
      El día 29 de Abril de 2022 anunciaremos a los seleccionados para
      participar en el programa.
    </h2>
  </div>

  <div class="contador message" *ngIf="!availableUser && !isLoading">
    <h1>¡Ya estás inscripto!</h1>
    <div class="message">
      <ng-container *ngIf="project">
        <h4>Nombre proyecto</h4>
        <p>{{ project.project_name }}</p>
        <h4>Capitán</h4>
        <p>{{ project.creator_captain.name }}</p>
        <h4>Equipo</h4>
        <p *ngIf="project.equipament.length > 0">
          <ng-container *ngFor="let item of project.equipament; let i = index"
            >{{ item.name }}
            {{ i !== project.equipament.length - 1 ? ", " : "." }}</ng-container
          >
        </p>
        <p *ngIf="project.equipament.length === 0">Te inscribiste sin equipo</p>
        <h4>Documentos</h4>
        <p>
          {{
            project.valid_files === "false"
              ? "No adjuntaste la Propuesta Técnica y el Elevator Pitch con la inscripción"
              : "Adjuntaste correctamente la Propuesta Técnica y el Elevator Pitch"
          }}
        </p>
        <div
          class="docs-container main-shadow main-border main-radius"
          *ngIf="project.valid_files === 'false' && today > cargaEnd"
        >
          <h1>El tiempo para cargar la documentación ha finalizado</h1>
          <p>
            Si tuviste problema con la carga, ponte en contacto con nuestro
            Centro de Emprendimiento a la brevedad.
          </p>
        </div>
        <div
          class="docs-container main-shadow main-border main-radius"
          *ngIf="project.valid_files === 'false' && today <= cargaEnd"
        >
          <h1>Adjunta los documentos</h1>
          <p>
            Tienes tiempo hasta el 22 de Abril para adjuntar la Propuesta
            Técnica y el Elevator Pitch de tu proyecto
          </p>
          <div class="documentos">
            <!-- PROPUESTA TÉCNICA -->
            <div class="docHeader">
              <div class="number">1</div>
              <h2>Propuesta técnica</h2>
            </div>
            <p>
              Descarga el
              <a
                href="https://drive.google.com/uc?export=download&id=1OiNbllRJF7Nq9XCHE3ngzvo6GbOdVXoj"
                >modelo</a
              >, complétalo y cárgalo aquí
            </p>
            <div class="cargar-documento">
              <input
                type="file"
                accept=".pdf, .docx, .doc"
                (change)="onPropuestaSelected($event)"
                #propuestaUpload
                hidden
              />
              <button
                mat-flat-button
                color="accent"
                (click)="propuestaUpload.click()"
              >
                Seleccionar archivo
              </button>
              <div
                class="file-info main-border main-radius shadow-100"
                *ngIf="files.propuesta"
              >
                <div class="body">
                  <h4>{{ files.propuesta.name }}</h4>
                  <p>{{ formatBytes(files.propuesta.size) }}</p>
                </div>
                <button
                  mat-icon-button
                  color="accent"
                  (click)="onPropuestaDelete()"
                >
                  <mat-icon class="material-icons-round">delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="documentos">
            <!-- ELEVATOR PITCH -->

            <div class="docHeader">
              <div class="number">2</div>
              <h2>Elevator Pitch</h2>
            </div>
            <p>
              Graba un video de hasta <strong>1 (UN) minuto</strong> en el que
              nos des una visión general de tu proyecto.
            </p>
            <p>
              <span
                >Máximo 100MB, formatos aceptados: MP4, M4V, 3GPP, MOV,
                AVI.</span
              >
            </p>
            <div class="cargar-documento">
              <input
                type="file"
                accept=".mp4, .m4v, .avi, .3gpp, .mov"
                (change)="onVideoSelected($event)"
                #videoUpload
                hidden
              />
              <button
                mat-flat-button
                color="accent"
                (click)="videoUpload.click()"
              >
                Seleccionar archivo
              </button>
              <div
                class="file-info main-border main-radius shadow-100"
                *ngIf="files.video"
              >
                <div class="body">
                  <h4>{{ files.video.name }}</h4>
                  <p>{{ formatBytes(files.video.size) }}</p>
                </div>
                <button
                  mat-icon-button
                  color="accent"
                  (click)="onVideoDelete()"
                >
                  <mat-icon class="material-icons-round">delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <button
            mat-flat-button
            class="primary-button"
            [disabled]="!files.propuesta || !files.video"
            color="primary"
            (click)="onFilesSubmit()"
          >
            Enviar documentos
            <mat-icon class="material-icons-round">send</mat-icon>
          </button>
        </div>

        <div
          class="informe main-radius main-shadow"
          *ngIf="project.valid_files === 'false'"
        >
          <p>
            Si tienes dudas, o necesitas ayuda con tu propuesta recuerda que
            puedes comunicarte con nuestro Centro de Emprendimiento en cualquier
            momento a través del correo
            <a href="mailto:ceaden@adenuniversity.edu.pa"
              >ceaden@adenuniversity.edu.pa</a
            >
            o desde el
            <a [routerLink]="['/ceaden/programa-impulso']" fragment="contacto"
              >formulario de consultas</a
            >
          </p>
        </div>
        <h2>
          El día 29 de Abril de 2022 anunciaremos a los seleccionados para
          participar en el programa.
        </h2>
      </ng-container>
    </div>
  </div>

  <div
    class="form-container"
    id="form-impulso"
    *ngIf="today < impulsoEnd && availableUser && !isLoading"
  >
    <div class="card-capitan">
      <div class="header">
        <h1>Estudiante</h1>
        <div class="perfil">
          <mat-icon class="material-icons-round">error</mat-icon>
          Tomamos los datos de tu perfil para la inscripción
        </div>
      </div>
      <div class="data">
        <div class="data-display">
          <h4>Nombre</h4>
          <p>{{ (user$ | async)?.nombre }}</p>
        </div>
        <div class="data-display">
          <h4>Mail</h4>
          <p>{{ (user$ | async)?.email }}</p>
        </div>
        <div class="data-display">
          <h4>Teléfono</h4>
          <p>{{ (user$ | async)?.movil }}</p>
        </div>
      </div>
    </div>
    <h1>Sobre el proyecto</h1>
    <form [formGroup]="mainForm">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Nombre del proyecto</mat-label>
        <input
          matInput
          placeholder="Nombre"
          formControlName="nombre_proyecto"
          required
        />
        <mat-error
          *ngIf="this.mainForm.get('nombre_proyecto').hasError('required')"
          >Este campo es requerido</mat-error
        >
      </mat-form-field>

      <h2>¿Trabajarás en equipo?</h2>
      <mat-radio-group
        aria-label="Selecciona una opcion"
        class="differ-container"
        color="primary"
        formControlName="tiene_equipo"
      >
        <mat-radio-button [value]="false" (click)="resetMembers()"
          >No, lo haré solo.</mat-radio-button
        >
        <mat-radio-button [value]="true"
          >Sí, ¡tengo un equipo!</mat-radio-button
        >
      </mat-radio-group>

      <div
        id="team"
        *ngIf="mainForm.get('tiene_equipo').value"
        class="main-border main-radius main-shadow"
      >
        <h2>Añade miembros a tu equipo</h2>
        <div class="form-container">
          <h3>
            SI EL MIEMBRO ES ALUMNO PUEDES BUSCARLO EN EL LISTADO PARA
            AUTOCOMPLETAR EL FORMULARIO
          </h3>
          <mat-form-field class="full-width">
            <mat-label>Busca un alumno por su nombre</mat-label>
            <input
              matInput
              aria-label="State"
              [formControl]="alumnoCtrl"
              [matAutocomplete]="auto"
              [matAutocompleteDisabled]="this.isLoading"
            />
            <mat-icon matPrefix>search</mat-icon>
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayProperty"
              (optionSelected)="selectAlumno($event.option.value)"
            >
              <mat-option
                *ngFor="let alumno of filteredAlumnos | async"
                [value]="alumno"
              >
                <span>{{ alumno.name }}</span> |
                <small>{{ alumno.mail }}</small>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <form [formGroup]="memberForm">
            <mat-form-field appearance="fill">
              <mat-label>Nombre completo</mat-label>
              <input
                matInput
                placeholder="Jane Doe"
                formControlName="name"
                required
              />
              <mat-error
                *ngIf="this.memberForm.get('name').hasError('required')"
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>E-mail</mat-label>
              <input
                matInput
                placeholder="example@example.com"
                formControlName="mail"
                required
              />
              <mat-error *ngIf="this.memberForm.get('mail').hasError('email')"
                >Ingrese una dirección de correo válida</mat-error
              >
              <mat-error
                *ngIf="this.memberForm.get('mail').hasError('required')"
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Celular</mat-label>
              <lib-ngx-mat-tel-input
                [defaultCountry]="'PA'"
                formControlName="phone"
                required
              >
              </lib-ngx-mat-tel-input>
              <mat-error
                *ngIf="this.memberForm.get('phone').hasError('required')"
              >
                Este campo es <strong>requerido</strong>
              </mat-error>
              <mat-error
                *ngIf="this.memberForm.get('phone').hasError('format')"
              >
                El formato del número es <strong>invalido</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Nivel de Estudio</mat-label>
              <mat-select formControlName="study_level">
                <mat-option *ngFor="let nivel of studyLevels" [value]="nivel">
                  {{ nivel }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
          <div class="button-group">
            <button mat-flat-button class="reset" (click)="resetForms()">
              Reset
            </button>
            <button
              mat-flat-button
              color="accent"
              [disabled]="memberForm.invalid"
              (click)="addMember()"
            >
              Añadir
            </button>
          </div>
        </div>
        <div class="grid-members">
          <ng-container *ngFor="let item of miembrosEquipo">
            <div class="member-card main-border shadow-100 main-radius">
              <div class="body">
                <h3>{{ item.name }}</h3>
                <p>E-mail: {{ item.mail }}</p>
                <p>Cel: {{ item.phone }}</p>
                <p>Nivel de estudio: {{ item.study_level }}</p>
              </div>
              <button
                mat-icon-button
                color="accent"
                (click)="removeMember(item)"
              >
                <mat-icon class="material-icons-round">delete</mat-icon>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
    <h1>Adjunta los documentos <span>(OPCIONAL)</span></h1>
    <h3>
      Si ya tienes tu <strong>Propuesta técnica</strong> y tu
      <strong>Elevator Pitch</strong> listos, puedes adjuntarlos en este
      momento. Si aún no los tienes, puedes enviar la solicitud sin adjuntarlos
      y cargarlos más tarde.
    </h3>
    <h3>
      Una vez inscripto, tendrás tiempo hasta el
      <strong>22 de Abril</strong> para cargar tu Propuesta Técnica y Elevator
      Pitch.
    </h3>
    <mat-radio-group
      aria-label="Selecciona una opcion"
      class="differ-container"
      color="primary"
      [formControl]="filesControl"
    >
      <mat-radio-button
        [value]="false"
        (click)="onPropuestaDelete(); onVideoDelete()"
        >Los cargaré más tarde</mat-radio-button
      >
      <mat-radio-button [value]="true">Quiero cargarlos ahora</mat-radio-button>
    </mat-radio-group>
    <div class="documentos" *ngIf="filesControl.value">
      <!-- PROPUESTA TÉCNICA -->
      <div class="docHeader">
        <div class="number">1</div>
        <h2>Propuesta técnica</h2>
      </div>
      <p>
        Descarga el
        <a
          href="https://drive.google.com/uc?export=download&id=1OiNbllRJF7Nq9XCHE3ngzvo6GbOdVXoj"
          >modelo</a
        >, complétalo y cárgalo aquí
      </p>
      <div class="cargar-documento">
        <input
          type="file"
          accept=".pdf, .docx, .doc"
          (change)="onPropuestaSelected($event)"
          #propuestaUpload
          hidden
        />
        <button
          mat-flat-button
          color="accent"
          (click)="propuestaUpload.click()"
        >
          Seleccionar archivo
        </button>
        <div
          class="file-info main-border main-radius shadow-100"
          *ngIf="files.propuesta"
        >
          <div class="body">
            <h4>{{ files.propuesta.name }}</h4>
            <p>{{ formatBytes(files.propuesta.size) }}</p>
          </div>
          <button mat-icon-button color="accent" (click)="onPropuestaDelete()">
            <mat-icon class="material-icons-round">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="documentos" *ngIf="filesControl.value">
      <!-- ELEVATOR PITCH -->

      <div class="docHeader">
        <div class="number">2</div>
        <h2>Elevator Pitch</h2>
      </div>
      <p>
        Graba un video de hasta <strong>1 (UN) minuto</strong> en el que nos des
        una visión general de tu proyecto.
      </p>
      <p>
        <span>Máximo 100MB, formatos aceptados: MP4, M4V, 3GPP, MOV, AVI.</span>
      </p>
      <div class="cargar-documento">
        <input
          type="file"
          accept=".mp4, .m4v, .avi, .3gpp, .mov"
          (change)="onVideoSelected($event)"
          #videoUpload
          hidden
        />
        <button mat-flat-button color="accent" (click)="videoUpload.click()">
          Seleccionar archivo
        </button>
        <div
          class="file-info main-border main-radius shadow-100"
          *ngIf="files.video"
        >
          <div class="body">
            <h4>{{ files.video.name }}</h4>
            <p>{{ formatBytes(files.video.size) }}</p>
          </div>
          <button mat-icon-button color="accent" (click)="onVideoDelete()">
            <mat-icon class="material-icons-round">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <button
      mat-flat-button
      class="primary-button"
      [disabled]="
        mainForm.invalid ||
        (!this.mainForm.get('nombre_proyecto').value &&
          miembrosEquipo.length === 0) ||
        (filesControl.value && (!files.propuesta || !files.video))
      "
      color="primary"
      (click)="onFormSubmit()"
    >
      {{
        filesControl.value ? "Inscribirme y enviar documentos" : "Inscribirme"
      }}
      <mat-icon class="material-icons-round">send</mat-icon>
    </button>
  </div>
</div>
