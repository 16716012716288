import { IDocente, IResource } from 'src/app/shared/interfaces/interfaces';
import { Store } from '@ngrx/store';
import { PostCredits } from './../interfaces/interfaces';
import { AlertDialogComponent } from '../components/alerts/alert-dialog/alert-dialog.component';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, pipe } from 'rxjs';
import { environment } from './../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  url = environment.baseUrl;
  repoUrl = environment.repoUrl
  // url = 'http://localhost:8000/api';

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private store: Store<any>
  ) {}

  get token(): string {
    return localStorage.getItem('token') ? localStorage.getItem('token') : '';
  }

  getPayOrders(){
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.leerToken()});
    return this.http.get<any>(this.repoUrl+'/v1/repo_aden/faculty/pay_order', { headers });
  }

  // AUTORIZACION

  leerToken(): string {
    if (localStorage.getItem('token')) {
      return localStorage.getItem('token');
    } else {
      return '';
    }
  }

  private tokenExpired(token: string): boolean {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  leerLegajo(): string {
    if (localStorage.getItem('sis_id')) {
      return localStorage.getItem('sis_id');
    } else {
      return '';
    }
  }

  login(params): Observable<any> {
    return this.http
      .post(this.url + '/v2/acropolis/auth/login', params, {})
      .pipe(map((data) => data));
  }

  loginCanvas(form): Observable<any> {
    return this.http
      .post('https://fa.aden.org/oauth2/authorize', form, {})
      .pipe(map((data) => data));
  }

  clearToken(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
  }

  clearSis_id(): void {
    localStorage.removeItem('sis_id');
  }

  clear_estado_bloqueo(): void {
    localStorage.removeItem('estado_bloqueo');
    localStorage.removeItem('estadoBloqueos');
    localStorage.removeItem('exp');
  }

  isAuthorizated(): boolean {
    // const hoy = new Date();
    // console.log('Hoy :', hoy);
    // console.log('Exp :', new Date(this.leerExp()));
    if (this.leerToken()) {
      return true;
    } else {
      return false;
    }
  }

  getPartner(partnerId): Observable<any> {
    return this.http.get(
      this.url + '/v1/acropolis/users/public-contact/' + partnerId
    );
  }

  getAllCourses(): Observable<IResource[]>{
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    console.log(this.repoUrl)
    return this.http.get<IResource[]>(`${this.repoUrl}/v1/repo_aden/educat/obj_aprendizaje_all`)
  }
  getRegisterCourse(repo_id): any{
      const token = this.leerToken();
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
      return this.http.get<IResource[]>(`${this.repoUrl}/v1/repo_aden/enrollment/${repo_id}`, { headers })
  }
  registerCourse(id){
      const staticPayload = {
        type: "oa",
        assignment: id,
        project100: "true"
    }
      const token = this.leerToken();
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
      return this.http.post(`${this.repoUrl}/v1/repo_aden/enrollment/enroll/${localStorage.getItem('repo_id')}`,staticPayload, { headers })
  }
  getResourceProgress(id){
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get<IResource[]>(`${this.repoUrl}/v1/repo_aden/enrollment/${localStorage.getItem('repo_id')}?=${id}`, { headers })
  }


  changePassword(user): Observable<any> {
    return this.http.post(
      this.url + '/v1/acropolis/auth/change-password',
      user
    );
  }

  getNotas(): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get(this.url + '/educat/notas', { headers });
  }



  // ALUMNOS - USUARIO

  getPaises(): Observable<any> {
    return this.http.get(this.url + '/educat/paises');
  }

  getLaboralSector(): Observable<any> {
    return this.http.get(this.url + '/educat/sector_laboral');
  }

  getAllAlumnos(): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get(this.url + '/educat/get_alumnos', { headers });
  }

  getAlumno(): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get(this.url + '/educat/alumno', { headers });
  }

  postAlumno(data): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    const dataFormated = {
      cargo: '',
      celular: data.phone,
      direccion: '',
      email: data.mailAcademic,
      email_personal: data.mailPersonal,
      escuela: data.school,
      escuela_graduacion: data.yearEgress,
      pais_escuela: data.country,
      facebook: data.facebook,
      fecha_nacimiento: data.birthDate,
      grupo_sanguineo: data.blood,
      linkedin: data.linkedin,
      instagram: data.instagram,
      movil: data.phone,
      numero_documento: data.numberDocument,
      pais: data.nationality,
      skype: data.skype,
      telefono_principal: '',
      tipo_documento: data.typeDocument,
      vencimiento_documento: data.dateDocument,
      nombre_emergencia: data.nameRelationship,
      movil_emergencia: data.phoneRelationship,
      parentesco_emergencia: data.relationship,
      genero: data.gender,
      cargo_laboral: data.workload,
      sector_laboral: data.sectorLaboral,
    };
    return this.http
      .post(this.url + '/educat/alumno', dataFormated, { headers })
      .pipe(map((d) => d));
  }

  // DOCENTE

  getUserState() {
    return this.store.select('user');
  }

  getDocente(): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get(this.url + '/educat/faculty', { headers });
  }

  postFoto(image: File): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const formData = new FormData();

    formData.append('img', image);
    return this.http.post(this.url + '/educat/faculty/foto', formData, {
      headers,
    });
  }

  updateProfesor(profesor: IDocente) {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    const data = new FormData();

    data.append('fecha_nacimiento', profesor.fecha_nacimiento);
    data.append('grupo_sanguineo', profesor.grupo_sanguineo);
    data.append('nacionalidad_id', profesor.nacionalidad);
    data.append('tipo_documento', profesor.tipo_documento);
    data.append('nro_documento', profesor.nro_documento);
    data.append('genero', profesor.genero);
    data.append('movil', profesor.movil);
    data.append('correo_personal', profesor.correo_personal);
    data.append('linkedin', profesor.linkedin);
    data.append(
      'nombre_contacto_emergencia',
      profesor.contacto_emergencia.nombre
    );
    data.append(
      'movil_contacto_emergenciamiento',
      profesor.contacto_emergencia.movil
    );
    data.append('parentesco', profesor.contacto_emergencia.parentesco);

    return this.http
      .put(this.url + '/educat/faculty', data, { headers })
      .pipe(map((d) => d));
  }

  updateAcademicsProfesor(estado_estudios, especialidad_id) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    const data = new FormData();
    data.append('estado_estudios_docencia', estado_estudios);
    data.append('especialidad_id', especialidad_id);
    return this.http
      .put(this.url + '/educat/formacion_academica_faculty', data, { headers })
      .pipe(map((d) => d));
  }

  /* ESTUDIOS: Universidades e Idiomas */

  getEstudiosDocente(): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get(this.url + '/educat/estudios', {
      headers,
    });
  }

  addEstudios(estudio): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    const data = new FormData();
    data.append('pais_id', estudio.pais_id);
    data.append('ano_graduacion', estudio.ano_graduacion);
    data.append('titulo', estudio.titulo);
    data.append('universidad_id', estudio.universidad_id);
    return this.http
      .post(this.url + '/educat/estudios', data, { headers })
      .pipe(map((d) => d));
  }
  addCertificado(certificado): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    const data = new FormData();
    data.append('date', certificado.date);
    data.append('file', certificado.file);
    data.append('entity', certificado.entity);
    data.append('theme', certificado.theme);
    data.append('title', certificado.title);
    data.append('hours', certificado.hours);
    console.log(data)
    return this.http
      .post(this.url + '/educat/certificados', data, { headers })
      .pipe(map((d) => d));
  }

  deleteEstudio(estudio_id): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    /* const data = new FormData();
    data.append('estudio_id', estudio_id); */

    return this.http.delete(
      this.url + '/educat/estudios?estudio_id=' + estudio_id,
      {
        headers,
      }
    );
  }

  deleteCertificado(certificado_id): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    return this.http.delete(
      this.url + '/educat/certificados?certificate_id=' + certificado_id,
      {
        headers,
      }
    );
  }

  updateCertificados(certificado, fileChanged: boolean): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    const data = new FormData();
    data.append('certificate_id', certificado.id)
    data.append('date', certificado.date)
    data.append('entity', certificado.entity)
    data.append('theme', certificado.theme)
    data.append('title', certificado.title)
    data.append('file', fileChanged ? certificado.file : false)
    data.append('hours', certificado.hours)
    return this.http
      .put(this.url + '/educat/certificados', data, { headers })
      .pipe(map((d) => d));
  }

  updateEstudios(estudio) {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    var data = new FormData();
    data.append('estudio_id', estudio.estudio_id);
    data.append('pais_id', estudio.pais_id);
    data.append('ano_graduacion', estudio.ano_graduacion);
    data.append('titulo', estudio.titulo);
    data.append('universidad_id', estudio.universidad_id);

    return this.http
      .put(this.url + '/educat/estudios', data, { headers })
      .pipe(map((d) => d));
  }

  getUniversidades(): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    return this.http.get(this.url + '/educat/universidades', { headers });
  }

  getAreasEspecialidad(): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    return this.http.get(this.url + '/educat/areas_especialidades', {
      headers,
    });
  }

  getAllSpecialties(): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    return this.http.get(this.url + '/educat/faculty_specialities', { headers });
  }
  
  getUserSpecialties(): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    return this.http.get(this.url + '/educat/faculty_speciality', { headers });
  }

  addSpecialty(speciality): Observable<any> {
    const fd = new FormData();
    fd.append('speciality_id', speciality);
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    return this.http.post(this.url + '/educat/faculty_speciality', fd, { headers });
  }

  deleteSpecialty(speciality_id): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    return this.http.delete(this.url + '/educat/faculty_speciality?speciality_id=' + speciality_id, { headers });
  }

  getIdiomas(): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    return this.http.get(this.url + '/educat/idiomas', {
      headers,
    });
  }

  addIdioma(idioma_id): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });
    const data = new FormData();
    data.append('idioma_id', idioma_id);
    return this.http
      .post(this.url + '/educat/idiomas_faculty', data, { headers })
      .pipe(map((d) => d));
  }

  deleteIdioma(idioma_id): Observable<any> {
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.token });

    return this.http.delete(
      this.url + '/educat/idiomas_faculty?idioma_id=' + idioma_id,
      {
        headers,
      }
    );
  }

  /* DOCUMENTOS */

  getDocuments(): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get(this.url + '/educat/documentos_docente', { headers });
  }

  postDocument(file, type): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    const data = new FormData();
    data.append('documento', file);
    data.append('tipo_documentacion', type.toString());

    return this.http.post(this.url + '/educat/documentos_docente', data, {
      headers,
    });
  }

  postMultiDocs(files, type){
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append('documento', files[i]);
    }
    data.append('tipo_documentacion', type.toString());
    data.append('carga_multiple', 'true');
    
    data.forEach((value, key) => {
      console.log(key + ': ' + value);
    });

    return this.http.post(this.url + '/educat/documentos_docente', data, {
      headers,
    });
  }

  updateDocument(file, docId): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    const data = new FormData();
    data.append('documento', file);

    return this.http.put(
      this.url + '/educat/documentos_docente/' + docId,
      data,
      {
        headers,
      }
    );
  }

  updateMultiDocs(file, docId){
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const data = new FormData();
    data.append('documento', file);
    data.append('carga_multiple', 'true');
    return this.http.put(
      this.url + '/educat/documentos_docente/' + docId,
      data,
      {
        headers,
      }
    );
  }

  // ESTADO FINANCIERO

  getPagos(fechaInicio = '', fechaFin = ''): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.get(
      this.repoUrl + '/v1/repo_aden/faculty/purchase_order',
      {
        headers
      }
    );
  }

  reportPago(mensaje, type_id, asunto, contacto): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    const body = {
      mensaje: mensaje,
      tipo: type_id,
      asunto: asunto,
      contacto: contacto,
    };

    return this.http.post(this.url + '/contacto_oc', body, {
      headers,
    });
  }

  getStudentsSis(orderName): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.post(
      this.url + '/ordensis',
      { oc: orderName },
      {
        headers,
      }
    );
  }

  // PQRS

  getPqrsOptions(): Observable<any> {
    return this.http.get(
      this.url + '/v1/acropolis/pqrs/options?is_adenupa=true'
    );
  }

  getTicketById(id): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get(this.url + '/v1/acropolis/pqrs/' + id, { headers });
  }

  setPqrs(form): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.post(
      this.url + '/v1/acropolis/pqrs?is_adenupa=true',
      form,
      { headers }
    );
  }

  // EVENTOS
  getEventos(): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get(this.url + '/v1/acropolis/events?is_adenupa=true', {
      headers,
    });
  }

  // CARRERAS Y ASIGNATURAS - Movilidad Internacional

  getCarreras(): Observable<any> {
    return this.http.get(this.url + '/educat/carrera');
  }

  getAsignaturasByCarrera(id): Observable<any> {
    return this.http.get(this.url + `/educat/carrera/${id}/asignaturas`);
  }

  // NOTIFICACIONES

  getNotifications(): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get(this.url + '/educat/notificaciones_docente', {
      headers,
    });
  }

  setViewNotification(notification_id): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.put(
      this.url + '/educat/notificacion_leida_docente/' + notification_id,
      null,
      {
        headers,
      }
    );
  }

  getNotificationCategories(): Observable<any> {
    const token = this.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get(this.url + '/educat/categorias_notificaciones_docente', {
      headers,
    });
  }

  // ALERTS

  openAlert(
    title: string,
    description: string,
    action: string,
    onCloseFunction: void = null,
    type: string = 'default',
    icon: string = '',
    img: string = '',
    dontShow: boolean = false
  ): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: '625px',
      data: {
        title,
        description,
        action,
        onCloseFunction,
        type,
        icon,
        img,
        dontShow
      },
      panelClass: 'plain-alert',
    });
  }
}
