<div class="mt-3 margin-bottom">
  <div class="container" *ngIf="isLoading">
    <div class="center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <form [formGroup]="profileForm" *ngIf="!isLoading">
    <div class="heading"><h2>Sobre tí</h2></div>
    <div class="form-grid">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Nombre completo</mat-label>
        <input matInput formControlName="nombre" />
      </mat-form-field>
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Fecha de nacimiento</mat-label>
        <input
          type="date"
          matInput
          placeholder=""
          formControlName="fecha_nacimiento"
          required
        />
        <mat-error
          *ngIf="this.profileForm.get('fecha_nacimiento').hasError('required')"
          >Este campo es requerido</mat-error
        >
      </mat-form-field>
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Grupo sanguinio</mat-label>
        <mat-select formControlName="grupo_sanguineo" required>
          <mat-option value="A+">A+</mat-option>
          <mat-option value="B+">B+</mat-option>
          <mat-option value="O+">O+</mat-option>
          <mat-option value="AB+">AB+</mat-option>
          <mat-option value="A-">A-</mat-option>
          <mat-option value="B-">B-</mat-option>
          <mat-option value="O-">O-</mat-option>
          <mat-option value="AB-">AB-</mat-option>
        </mat-select>
        <mat-error
          *ngIf="this.profileForm.get('grupo_sanguineo').hasError('required')"
          >Este campo es requerido</mat-error
        >
      </mat-form-field>
      <mat-form-field
        *ngIf="countries !== undefined; else disabledNationality"
        class="full-width"
        appearance="fill"
      >
        <mat-label>Nacionalidad</mat-label>
        <mat-select
          required
          matNativeControl
          formControlName="nacionalidad"
          [compareWith]="compareCountry"
        >
          <mat-option
            *ngFor="let country of countries.paises"
            [value]="country.name"
          >
            {{ country.name }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="this.profileForm.get('nacionalidad').hasError('required')"
          >Este campo es requerido</mat-error
        >
      </mat-form-field>
      <ng-template #disabledNationality>
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>Nacionalidad</mat-label>
          <input matInput placeholder="Cargando" disabled />
          <div matSuffix class="sk-fading-circle">
            <div class="sk-circle1 sk-circle"></div>
            <div class="sk-circle2 sk-circle"></div>
            <div class="sk-circle3 sk-circle"></div>
            <div class="sk-circle4 sk-circle"></div>
            <div class="sk-circle5 sk-circle"></div>
            <div class="sk-circle6 sk-circle"></div>
            <div class="sk-circle7 sk-circle"></div>
            <div class="sk-circle8 sk-circle"></div>
            <div class="sk-circle9 sk-circle"></div>
            <div class="sk-circle10 sk-circle"></div>
            <div class="sk-circle11 sk-circle"></div>
            <div class="sk-circle12 sk-circle"></div>
          </div>
          <mat-hint>Cargando países</mat-hint>
        </mat-form-field>
      </ng-template>
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Tipo de documento</mat-label>
        <mat-select formControlName="tipo_documento" required>
          <mat-option value="Cédula">Cédula</mat-option>
          <mat-option value="Pasaporte">Pasaporte</mat-option>
          <mat-option value="Otro">Otro</mat-option>
        </mat-select>
        <mat-error
          *ngIf="this.profileForm.get('tipo_documento').hasError('required')"
          >Este campo es requerido</mat-error
        >
      </mat-form-field>
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>No. de Documento</mat-label>
        <input
          matInput
          placeholder=""
          formControlName="nro_documento"
          required
        />
        <mat-error
          *ngIf="this.profileForm.get('nro_documento').hasError('required')"
          >Este campo es requerido</mat-error
        >
      </mat-form-field>
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Género según documento</mat-label>
        <mat-select formControlName="genero" required>
          <mat-option value="male">Masculino</mat-option>
          <mat-option value="female">Femenino</mat-option>
        </mat-select>
        <mat-error *ngIf="this.profileForm.get('genero').hasError('required')"
          >Este campo es requerido</mat-error
        >
      </mat-form-field>
    </div>
    <div class="heading"><h2>Datos de contacto</h2></div>
    <div class="form-grid">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Móvil</mat-label>
        <lib-ngx-mat-tel-input
          [defaultCountry]="'PA'"
          [formControl]="profileForm.get('movil')"
          required
        >
        </lib-ngx-mat-tel-input>
        <mat-error *ngIf="this.profileForm.get('movil').hasError('required')">
          Este campo es <strong>requerido</strong>
        </mat-error>
        <mat-error *ngIf="this.profileForm.get('movil').hasError('format')">
          El formato del número es <strong>inválido</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Correo electrónico personal</mat-label>
        <input
          matInput
          placeholder=""
          formControlName="correo_personal"
          required
        />
        <mat-error
          *ngIf="this.profileForm.get('correo_personal').hasError('email')"
          >Ingresa una dirección de correo válida</mat-error
        >
        <mat-error
          *ngIf="this.profileForm.get('correo_personal').hasError('required')"
          >Este campo es requerido</mat-error
        >
      </mat-form-field>
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>LinkedIn</mat-label>
        <input matInput formControlName="linkedin" />
        <mat-error *ngIf="this.profileForm.get('linkedin').hasError('required')"
          >Este campo es requerido</mat-error
        >
      </mat-form-field>
    </div>
    <div class="heading"><h2>Contacto de emergencia</h2></div>
    <div class="form-grid">
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Nombre</mat-label>
        <input
          matInput
          placeholder=""
          [formControl]="profileForm.get('contacto_emergencia.nombre')"
          required
        />
        <mat-error
          *ngIf="
            this.profileForm
              .get('contacto_emergencia.nombre')
              .hasError('required')
          "
          >Este campo es requerido</mat-error
        >
      </mat-form-field>
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>Móvil</mat-label>
        <lib-ngx-mat-tel-input
          [defaultCountry]="'PA'"
          [formControl]="profileForm.get('contacto_emergencia.movil')"
          required
        >
        </lib-ngx-mat-tel-input>
        <mat-error
          *ngIf="
            this.profileForm
              .get('contacto_emergencia.movil')
              .hasError('required')
          "
        >
          Este campo es <strong>requerido</strong>
        </mat-error>
        <mat-error
          *ngIf="
            this.profileForm.get('contacto_emergencia.movil').hasError('format')
          "
        >
          El formato del número es <strong>inválido</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <button
      mat-flat-button
      color="primary"
      type="submit"
      (click)="onSubmit()"
      id="save-button"
    >
      Guardar cambios
    </button>
  </form>
</div>
