import { DataService } from './../../shared/services/data.service';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss'],
})
export class NotificacionesComponent implements OnInit {
  public searchValue = '';
  public notifications = [];
  public filteredNotifications = [];
  public categories = [];
  public activeCategory = null;
  public error = false;
  public loading = false;

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.getNotifications();
    this.getCategories();
  }

  getNotifications() {
    this.loading = true;
    this.dataService.getNotifications().subscribe({
      next: (res) => {
        this.notifications = res.notificaciones.sort(
          (a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()
        );
        this.filteredNotifications = this.notifications;
        console.log(this.filteredNotifications);
      },
      error: (err) => {
        this.error = true;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  getCategories() {
    this.dataService.getNotificationCategories().subscribe({
      next: (res) => {
        this.categories = res.categorias;
        console.log(this.categories);
      },
      error: (err) => {
        this.error = true;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  filterCategory(category) {
    this.activeCategory = category.category_id;
    this.filteredNotifications = this.notifications.filter(
      (item) => item.message_category_id === category.category_id
    );
  }

  resetNotifications() {
    this.activeCategory = null;
    this.filteredNotifications = this.notifications;
  }

  cleanSearch(): void {
    this.searchValue = '';
  }
}
