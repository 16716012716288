import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as $ from 'jquery';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import * as reduxUser from '../../redux/actions/user.action';
import { jwtDecode } from "jwt-decode";
import { DetalleBloqueo } from '../../shared/interfaces/interfaces';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginFormGroup: UntypedFormGroup;
  public hide = true;
  btnDisabled = false;

  user: string;
  password: string;
  hoy: Date;
  $: any;

  errorAlert = false;

  public queryEmail = '';

  public originUrl = localStorage.getItem('origin');

  public user$: Observable<any>;
  estadoBloqueos: DetalleBloqueo[] = [];

  constructor(
    private dataService: DataService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private _snackBar: MatSnackBar,
    private store: Store<UserState>,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.dataService.leerToken() !== '') {
      this.router.navigateByUrl('/home');
    }
    this.activeRoute.queryParams.subscribe((params) => {
      this.queryEmail = params.mail;
    });
    this.buildForm();
  }

  buildForm(): void {
    this.loginFormGroup = this.formBuilder.group({
      email: [this.queryEmail, Validators.required],
      password: ['', Validators.required],
    });
  }

  onLogIn(): void {
    const btn = document.getElementById('btn-login');
    btn.innerHTML = 'Cargando...';
    this.btnDisabled = true;
    this.hoy = new Date();
    this.hoy.setDate(this.hoy.getDate() + 30);
    const expiracion = this.hoy.toISOString();

    const user = {
      user: this.loginFormGroup.value.email,
      password: this.loginFormGroup.value.password,
    };
    // let canvas
    this.dataService.login({ ...user }).subscribe(
      (res) => {
        localStorage.setItem('token', res.response_data[`token`]);
        localStorage.setItem('sis_id', res.response_data.educat_data[`sis_id`]);
        localStorage.setItem('repo_id', res[`repo_id`]);
        localStorage.setItem('exp', expiracion);
        const decode: any = jwtDecode(res.response_data[`token`])
        localStorage.setItem('repo_id', decode.repositorio_partner_id)
        this.btnDisabled = false;
        // $('#formlogin').submit();
        this.getUser(res[`sis_id`]);
      },
      (err) => {
        btn.innerHTML = 'Ingresar';
        this.btnDisabled = false;
        this.errorAlert = true;
        /* this._snackBar.open('Usuario y/o contraseña incorrectos', 'Cerrar', {
          duration: 5000,
        }); */
      }
    );
    // console.log(canvas);
  }

  getUser(sis_id) {
    this.dataService.getDocente().subscribe(
      (res) => {
        if (
          (res.nombre === '' || res.nombre === null) &&
          (res.correo_academico === '' ||
            !res.correo_academico ||
            res.correo_academico === undefined)
        ) {
          this.dataService.openAlert(
            '¡Ups!',
            'Estamos teniendo problemas con nuestro servidor, por favor intenta más tarde',
            'Ok'
          );
          this.dataService.clearSis_id();
          this.dataService.clearToken();
          const btn = document.getElementById('btn-login');
          btn.innerHTML = 'Ingresar';
          return;
        }

        localStorage.setItem(
          'userData',
          JSON.stringify({
            ...res,
            foto64: `data:image/png;base64,${res.foto64}`,
            sis_id: sis_id,
          })
        );

        this.store.dispatch(
          new reduxUser.GetUser({
            ...res,
            foto64: `data:image/png;base64,${res.foto64}`,
            sis_id: sis_id,
          })
        );

        setTimeout(() => {
          if (this.originUrl != undefined && this.originUrl != null) {
            this.router.navigateByUrl(this.originUrl);
            if(localStorage.getItem('referralAlert') != 'true'){
              this.dataService.openAlert(
                '',
                '',
                'Entendido',
                null,
                '',
                '',
                '../../../assets/images/content/banner.png',
                true
                )
            }
          } else {
            this.router.navigateByUrl('');
          }
        }, 200);
      },
      (err) => {
        this.dataService.openAlert(
          '¡Ups!',
          'Estamos teniendo problemas con nuestro servidor, por favor intenta más tarde',
          'Ok'
        );
        this.dataService.clearSis_id();
        this.dataService.clearToken();
        const btn = document.getElementById('btn-login');
        btn.innerHTML = 'Ingresar';
      }
    );
    this.dataService.getEstudiosDocente().subscribe(
      (res) => {
        console.log(res);
      },
      (err) => {}
    );
  }

  onForgotPassword(): void {
    this.popupWindow(
      'https://fa.aden.org/password/forgot?email=' +
        this.loginFormGroup.value.email,
      'Recuperar mi contraseña',
      window,
      500,
      700
    );
  }

  popupWindow(url, windowName, win, w, h) {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  }

  closeAlert() {
    this.errorAlert = false;
  }
}
