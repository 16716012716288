<div class="card-container">
  <div class="card-header">
    <img
      class="custom-img"
      [src]="
        event.image_url
          ? event.image_url
          : '../../../../assets/images/content/event-default.jpg'
      "
      alt=""
    />
    <div class="chip-registered" *ngIf="event.is_registered">
      <mat-icon class="material-icons-round">done</mat-icon> INSCRIPTO
    </div>
    <div class="chip-type-event" *ngIf="event.category.name">
      {{ event.category.name }}
    </div>
  </div>
  <div class="card-body">
    <div class="text">
      <span>{{ event.event_type ? event.event_type + " | " : "" }}</span>
      {{ event.publication_name }}
    </div>
    <div class="dates-container">
      <div class="date-wrapper">
        <mat-icon class="material-icons-round">event</mat-icon>
        <span>
          {{ getValidDate() }}
        </span>
      </div>
      <div class="date-wrapper">
        <mat-icon class="material-icons-round">query_builder</mat-icon>
        <span>
          {{
            event.hour_begin +
              " (AR) | " +
              (getHourNumber(event.hour_begin) - 2) +
              ":" +
              getMinuteNumber(event.hour_begin) +
              " (PA) | " +
              (getHourNumber(event.hour_begin) - 3) +
              ":" +
              getMinuteNumber(event.hour_begin) +
              " (CR)"
          }}
        </span>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div>
      <div class="button-container">
        <button
          mat-icon-button
          color="primary"
          aria-label="Compartir"
          matTooltip="Compartir"
          (click)="
            isPastEvent
              ? openShare(event.webinar_url, 'pasado')
              : openShare(event.registration_url, 'proximo')
          "
        >
          <mat-icon class="material-icons-round">share</mat-icon>
        </button>
        <button
          mat-icon-button
          *ngIf="!isPastEvent"
          color="primary"
          aria-label="añadir a mi calendario"
          matTooltip="Añadir a mi calendario"
          [matMenuTriggerFor]="menu"
          (click)="getEvent()"
        >
          <mat-icon class="material-icons-round">bookmark_add</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <ng-container *ngFor="let item of links">
            <a mat-menu-item [href]="item.url" target="_blank">{{
              item.name
            }}</a>
          </ng-container>
        </mat-menu>
      </div>
    </div>
    <div [ngSwitch]="buttonValue">
      <ng-container *ngSwitchCase="'Ingresar al evento'"
        ><a
          mat-flat-button
          color="primary"
          target="_blank"
          rel="noopener noreferrer"
          [href]="event.webinar_url"
        >
          Ingresar al evento
        </a>
      </ng-container>
      <ng-container *ngSwitchCase="'Inscribirme'"
        ><a
          mat-flat-button
          color="primary"
          target="_blank"
          rel="noopener noreferrer"
          [href]="event.registration_url"
        >
          Inscribirme
        </a>
      </ng-container>
      <ng-container *ngSwitchCase="'Volver a ver'"
        ><a
          mat-flat-button
          color="primary"
          target="_blank"
          rel="noopener noreferrer"
          [href]="event.webinar_url"
        >
          Volver a ver
        </a>
      </ng-container>
      <ng-container *ngSwitchDefault
        ><a
          mat-flat-button
          color="primary"
          target="_blank"
          rel="noopener noreferrer"
          [href]="event.registration_url ? event.registration_url : '#'"
        >
          Más info
        </a>
      </ng-container>
    </div>
  </div>
</div>
