<div class="main-navigation">
  <app-sidenav
    [expandedSidenav]="expandedSidenav"
    (sidenavIsOpen)="expandedSidenav.setValue($event)"
  ></app-sidenav>
  <div
    class="main-content"
    [class.sidenav-open]="expandedSidenav.value || mobileQuery.matches"
  >
    <mat-toolbar color="primary" tourAnchor="toolbar">
      <app-menu-item
        id="toggleMobileMenu"
        [icon]="expandedSidenav.value ? 'close' : 'menu'"
        (click)="expandedSidenav.setValue(!expandedSidenav.value)"
      ></app-menu-item>
      <div class="logo" *ngIf="!expandedSidenav.value" routerLink="/">
        <a routerLink="/"><img src="assets/images/logos/ADENU-Color.png" /></a>
      </div>
      <span class="example-spacer"></span>
      <app-notifications></app-notifications>
      <div
        class="toolbar-button"
        tourAnchor="user-menu"
        [matMenuTriggerFor]="menu"
      >
        <div class="avatar">
          <img
            src="{{
              (user$ | async)?.foto64
                ? (user$ | async)?.foto64
                : 'assets/images/logos/avatar.png'
            }}"
          />
          <mat-icon class="material-icons-round arrow" [inline]="true">
            keyboard_arrow_down
          </mat-icon>
        </div>
      </div>

      <mat-menu
        #menu="matMenu"
        backdropClass="navigation-user-menu"
        class="mat-elevation-z0"
        [hasBackdrop]="true"
        xPosition="after"
      >
        <div class="user-profile" tourAnchor="user-menu-id" md-autofocus>
          <img
            [src]="
              (user$ | async)?.foto64
                ? (user$ | async)?.foto64
                : 'assets/images/logos/avatar.png'
            "
            alt="Imagen de perfil"
            class="avatar"
          />
          <div class="text">
            <div class="name">{{ (user$ | async)?.nombre }}</div>
            <div class="legajo">ID: {{ (user$ | async)?.sis_id }}</div>
          </div>
        </div>
        <hr />
        <div [tourAnchor]="'perfil'">
          <a
            tourAnchor="user-profile"
            [routerLink]="['/perfil']"
            class="quick-link"
            ><mat-icon class="material-icons-round">account_circle</mat-icon
            >Datos personales</a
          >
          <a
            tourAnchor="user-academics"
            [routerLink]="['../perfil']"
            [queryParams]="{ page: 'academics' }"
            class="quick-link"
            ><mat-icon class="material-icons-round">school</mat-icon>Formación
            académica</a
          >
          <a
            tourAnchor="user-docs"
            [routerLink]="['../perfil']"
            [queryParams]="{ page: 'docs' }"
            class="quick-link"
            ><mat-icon class="material-icons-round">folder_shared</mat-icon
            >Documentación</a
          >
        </div>

        <!-- <a
          tourAnchor="user-estado-financiero"
          [routerLink]="['../pagos']"
          class="quick-link"
          ><mat-icon class="material-icons-round">payments</mat-icon>Gestión de
          pagos</a
        > -->
        <hr />
        <div
          tourAnchor="user-start-tour"
          class="quick-link"
          (click)="startTour()"
        >
          <mat-icon class="material-icons-round">tour</mat-icon>Ver tour de
          navegación
        </div>
        <div
          tourAnchor="user-logout"
          class="quick-link"
          (click)="onSignOut()"
        >
          <mat-icon class="material-icons-round">logout</mat-icon>Cerrar sesión
        </div>
      </mat-menu>
    </mat-toolbar>
    <div class="content" id="main-content" tourAnchor="main-content">
      <ng-content></ng-content>
      <!-- <div class="spacer"></div> -->
    </div>

    <app-footer *ngIf="showFooter" class="footer-container"></app-footer>
  </div>
</div>
