<div class="card-home" (click)="goTo()">
  <!-- <div class="badge mb-3 bg-primary-aden"><span>#{{badge}}</span></div> -->
  <div class="icon absolute">
    <mat-icon class="material-icons-round">{{ icon }}</mat-icon>
  </div>

  <div class="title">{{ title }}</div>
  <div class="description">{{ description }}</div>
  <div class="icon">
    <mat-icon class="material-icons-round">arrow_forward</mat-icon>
  </div>
</div>
