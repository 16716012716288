<div id="estado-financiero">
  <app-header [title]="title"></app-header>
  <div class="container" *ngIf="estadoFinanciero">
    <div class="heading-grid">
      <app-data-display-card
        [label]="'Pagos por autorizar'"
        [value]="pagosPorAutorizar"
        [icon]="'schedule'"
        [color]="'#CEB269'"
      ></app-data-display-card>
      <app-data-display-card
        [label]="'Pagos autorizados'"
        [value]="pagosAutorizados"
        [icon]="'verified'"
        [color]="'#28A986'"
      ></app-data-display-card>
      <app-data-display-card
        [label]="'A cobrar | Dólares'"
        [value]="'USD ' + saldoDolar"
        [icon]="'attach_money'"
        [color]="'#D67539'"
      ></app-data-display-card>
      <app-data-display-card
        [label]="'A cobrar | Moneda local'"
        [value]="'$ ' + saldoLocal"
        [icon]="'attach_money'"
        [color]="'#86A0E4'"
      ></app-data-display-card>
    </div>

    <div class="main-container main-shadow main-radius">
      <mat-tab-group>
        <mat-tab label="Mis ordenes de servicio">
          <div class="first-container">
            <div class="filters-container">
              <div id="buscador" class="buscador">
                <div class="form-field">
                  <mat-icon class="material-icons-round">search</mat-icon>
                  <input
                    matInput
                    type="search"
                    placeholder="Buscar"
                    (keyup)="search($event)"
                    #input
                    [(ngModel)]="searchValue"
                  />
      
                  <div class="suffix">
                    <button
                      *ngIf="searchValue"
                      mat-icon-button
                      color="primary"
                      aria-label="Clear"
                      (click)="cleanSearch()"
                    >
                      <mat-icon class="material-icons-round">close</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="categories-filter">
                <mat-form-field>
                  <mat-label>Categorias</mat-label>
                  <mat-select (selectionChange)="optionChange($event)">
                    <mat-option *ngFor="let categorie of categories" [value]="categorie.value">{{categorie.viewValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
      
            <div class="table-container main-radius shadow-200 main-border">
              <!-- NEW TABLE -->
      
              <table
                mat-table
                [dataSource]="dataSource"
                matSort
                #pagosSort="matSort"
                class="mat-elevation-z0"
              >
                <ng-container
                  [matColumnDef]="column.columnDef"
                  [ngSwitch]="true"
                  *ngFor="let column of tableColumns"
                >
                  <ng-container *ngSwitchCase="column.columnDef === 'state'">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                      {{ column.header }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <div class="chip pagado" *ngIf="column.cell(row) === 'paid out' || column.cell(row) === 'paid_out'">
                        {{ 'Pagado' }}
                      </div>
                      <div
                        class="chip cobrar"
                        *ngIf="column.cell(row) === 'purchase'"
                      >
                        {{ 'A cobrar' }}
                      </div>
                      <div
                        class="chip cobrar"
                        *ngIf="column.cell(row) === 'draft'"
                      >
                        {{ 'A Autorizar' }}
                      </div>
                      <div
                        class="chip pagado"
                        *ngIf="column.cell(row) === 'sent'"
                      >
                        {{ 'Peticion de cotizacion enviada' }}
                      </div>
                      <div
                        class="chip cancelado"
                        *ngIf="column.cell(row) === 'cancel'"
                      >
                        {{ 'Cancelado' }}
                      </div>
                      <div
                        class="chip cancel"
                        *ngIf="column.cell(row) === 'done'"
                      >
                        {{ 'Bloqueado' }}
                      </div>
                      <div
                        class="chip pagado"
                        *ngIf="column.cell(row) === 'check'"
                      >
                        {{ 'Revisado por compras' }}
                      </div>
                      <div class="no-data" *ngIf="column.cell(row) === 'Sin datos'">
                        {{ column.cell(row) }}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                      {{ column.header }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <div *ngIf="column.cell(row) !== 'Sin datos'; else noData">
                        {{ column.cell(row) }}
                      </div>
                      <ng-template #noData>
                        <div class="no-data">
                          {{ column.cell(row) }}
                        </div>
                      </ng-template>
                    </td>
                  </ng-container>
                </ng-container>
      
                <ng-container matColumnDef="acciones">
                  <th mat-header-cell *matHeaderCellDef>Info</th>
                  <td mat-cell *matCellDef="let row">
                    <!-- Action Button -->
                    <button
                      mat-button
                      mat-icon-button
                      matTooltip="Más info"
                      (click)="openMoreInfo(row)"
                    >
                      <mat-icon class="material-icons-rounded">more_vert</mat-icon>
                    </button>
                  </td>
                </ng-container>
      
                <tr
                  mat-header-row
                  *matHeaderRowDef="columnsToDisplay.concat('acciones')"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: columnsToDisplay.concat('acciones')"
                  class="example-element-row"
                ></tr>
      
                <!-- <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="7">
                    <div class="no-result" *ngIf="value.value !== ''">
                      <mat-icon class="material-icons-round">search_off</mat-icon>
                      <div class="text">No encontramos resultados para</div>
                      <div class="searched-value">"{{ value.value }}"</div>
                    </div>
                    <div class="no-result" *ngIf="estadoFinanciero === []">
                      <mat-icon class="material-icons-round">error_outline</mat-icon>
                      <div class="text">
                        Aún no tienes notas cargadas en el sistema
                      </div>
                    </div>
                  </td>
                </tr> -->
              </table>
            </div>
      
            <div class="mat-row" *ngIf="isError && !isLoading">
              <div class="mat-cell" colspan="7">
                <div class="no-result">
                  <mat-icon class="material-icons-round">search_off</mat-icon>
                  <div class="searched-value">
                    Ups.. no encontramos tus órdenes de cobro.
                  </div>
                  <div class="text">¡Intenta de nuevo más tarde!</div>
                  <button mat-flat-button color="primary">Volver a cargar</button>
                </div>
              </div>
            </div>
      
            <div class="mat-row" *ngIf="isLoading && !isError">
              <div class="mat-cell" colspan="7">
                <div class="no-result">
                  <mat-spinner></mat-spinner>
                </div>
              </div>
            </div>
            <div class="paginator-container" id="paginator">
              <mat-paginator
                [pageSizeOptions]="[5, 10, 15, 20]"
                showFirstLastButtons
              ></mat-paginator>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Orden de pago">
          <div style="padding: 4rem; text-align: center;" class="not-data-container" *ngIf="!payOrders.length">
            NO SE ENCONTRARON ORDENES DE PAGO
          </div>
          <div class="tables-container" *ngIf="payOrders.length">
            <section *ngFor="let order of payOrders">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <table class="second-table">
                    <thead>
                      <tr>
                        <th class="head-table">Orden</th>
                        <th class="head-table">Referencia</th>
                        <th class="head-table">Fecha de creacion</th>
                        <th class="head-table">Fecha de pago</th>
                        <th class="head-table">Responsable</th>
                        <th class="head-table">Metodo de pago</th>
                        <th class="head-table">Estado</th>
                        <th class="head-table">Monto</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{order.name}}</td>
                        <td>{{order.ref ? order.ref : '-'}}</td>
                        <td>{{order.create_order}}</td>
                        <td>{{order.estimated_payment_date}}</td>
                        <td>{{order.responsible}}</td>
                        <td>{{order.payment_method_id}}</td>
                        <td>
                            <div 
                            class="chip pagado" *ngIf="order.state === 'paid_out' || order.state === 'paid out'">
                              {{ 'Pagado' }}
                            </div>
                            <div 
                            class="chip pagado" *ngIf="order.state === 'sent'">
                              {{ 'Peticion enviada' }}
                            </div>
                            <div 
                            class="chip pagado" *ngIf="order.state === 'confirmed'">
                              {{ 'Confirmado' }}
                            </div>
                            <div class="chip cancelado" *ngIf="order.state === 'done'">
                              {{ 'Bloqueado' }}
                            </div>
                            <div class="chip pagado" *ngIf="order.state === 'check'">
                              {{ 'Revisado por compras' }}
                            </div>
                            <div
                              class="chip cobrar"
                              *ngIf="order.state === 'purchase'"
                            >
                              {{ 'A cobrar' }}
                            </div>
                            <div
                              class="chip cobrar"
                              *ngIf="order.state === 'draft'"
                            >
                              {{ 'A Autorizar' }}
                            </div>
                            <div
                              class="chip cancelado"
                              *ngIf="order.state === 'cancel'"
                            >
                              {{ 'Cancelado' }}
                            </div>
                        <td>{{order.amount}}</td>
                      </tr>
                    </tbody>
                  </table>
  
                  
                </mat-expansion-panel-header>
                <table
                mat-table
                [dataSource]="order.purchase_orders"
                matSort
                #pagosSort="matSort"
                class="mat-elevation-z0 table-inside"
              >
                <ng-container
                  [matColumnDef]="column.columnDef"
                  [ngSwitch]="true"
                  *ngFor="let column of tableColumns"
                >
                  <ng-container *ngSwitchCase="column.columnDef === 'state'">
                    <th class="expand-table" mat-header-cell mat-sort-header *matHeaderCellDef>
                      {{ column.header }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <div 
                      class="chip pagado" *ngIf="order.state === 'paid_out' || order.state === 'paid out'">
                        {{ 'Pagado' }}
                      </div>
                      <div 
                      class="chip pagado" *ngIf="order.state === 'sent'">
                        {{ 'Peticion enviada' }}
                      </div>
                      <div 
                      class="chip pagado" *ngIf="order.state === 'confirmed'">
                        {{ 'Confirmado' }}
                      </div>
                      <div class="chip cancelado" *ngIf="order.state === 'done'">
                        {{ 'Bloqueado' }}
                      </div>
                      <div class="chip pagado" *ngIf="order.state === 'check'">
                        {{ 'Revisado por compras' }}
                      </div>
                      <div
                        class="chip cobrar"
                        *ngIf="order.state === 'purchase'"
                      >
                        {{ 'A cobrar' }}
                      </div>
                      <div
                        class="chip cobrar"
                        *ngIf="order.state === 'draft'"
                      >
                        {{ 'A Autorizar' }}
                      </div>
                      <div
                        class="chip cancelado"
                        *ngIf="order.state === 'cancel'"
                      >
                        {{ 'Cancelado' }}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <th class="expand-table" mat-header-cell mat-sort-header *matHeaderCellDef>
                      {{ column.header }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <div *ngIf="column.cell(row) !== 'Sin datos'; else noData">
                        {{ column.cell(row) }}
                      </div>
                      <ng-template #noData>
                        <div class="no-data">
                          {{ column.cell(row) }}
                        </div>
                      </ng-template>
                    </td>
                  </ng-container>
                </ng-container>
      
                <ng-container matColumnDef="acciones">
                  <th class="expand-table" mat-header-cell *matHeaderCellDef>Info</th>
                  <td mat-cell *matCellDef="let row">
                    <!-- Action Button -->
                    <button
                      mat-button
                      mat-icon-button
                      matTooltip="Más info"
                      (click)="openMoreInfo(row)"
                    >
                      <mat-icon class="material-icons-rounded">more_vert</mat-icon>
                    </button>
                  </td>
                </ng-container>
      
                <tr
                  mat-header-row
                  *matHeaderRowDef="columnsToDisplay.concat('acciones')"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: columnsToDisplay.concat('acciones')"
                  class="example-element-row"
                ></tr>
      
                <!-- <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="7">
                    <div class="no-result" *ngIf="value.value !== ''">
                      <mat-icon class="material-icons-round">search_off</mat-icon>
                      <div class="text">No encontramos resultados para</div>
                      <div class="searched-value">"{{ value.value }}"</div>
                    </div>
                    <div class="no-result" *ngIf="estadoFinanciero === []">
                      <mat-icon class="material-icons-round">error_outline</mat-icon>
                      <div class="text">
                        Aún no tienes notas cargadas en el sistema
                      </div>
                    </div>
                  </td>
                </tr> -->
              </table>
            </mat-expansion-panel>
            <div class="division"></div>
            </section>
          </div> 
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<!-- <div class="soonContainer" *ngIf="!this.isLoading">
  <div class="soonMessage">
    <img src="../../../assets/images/content/waiting.svg" alt="Proximamente" />
    <h2>¡Próximamente!</h2>
    <p>Aún no tenemos disponible tu estado financiero</p>
    <a mat-button class="bg-primary-aden text-white" href="/home">
      Volver al inicio
    </a>
  </div>
</div>

<div class="soonContainer" *ngIf="this.isLoading">
  <div class="soonMessage spinner">
    <mat-spinner></mat-spinner>
  </div>
</div> -->
