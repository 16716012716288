<div class="sobre-concurso">
  <div class="container right">
    <img src="../../../../assets/images/content/girl-internacional.png" />
    <div class="text">
      <div class="title primary">
        Dirección de Relaciones Internacionales ADENU
      </div>
      <div class="description">
        ADEN University te abre las puertas para que vivas una experiencia
        internacional en universidades extranjeras de gran prestigio. Con este
        fin hemos suscrito convenios para fomentar la movilidad de alumnos,
        realizar investigaciones conjuntas con nuestros docentes y varias
        acciones destinadas a potenciar nuestras la calidad de la formación
        universitaria. <br /><br />
        Creemos firmemente en la importancia de la internacionalización de la
        educación, impulsando de este modo acciones que favorezcan e incentiven
        la movilidad internacional de toda su comunidad académica.
      </div>
    </div>
  </div>
  <div class="container left" id="reverse">
    <div class="text">
      <div class="label">Misión</div>
      <div class="description">
        Fomentar la participación de alumnos, docentes e investigadores en
        programas internacionales que les permita enriquecer su formación
        académica y profesional.
      </div>
      <div class="label">Visión</div>
      <div class="description">
        Ser una universidad reconocida en la región por incentivar y colaborar
        académicamente en la internacionalización de sus docentes,
        investigadores y alumnos.
      </div>
      <div class="label">Objetivos</div>
      <div class="description">
        <ul>
          <li>
            Promover la internacionalización en todas las áreas académicas y
            administrativas de ADENU.
          </li>
          <li>
            Promover y acompañar en el desarrollo de programas de cooperación
            académica y científica de carácter internacional.
          </li>
          <li>
            Generar alianzas con universidades extranjeras de prestigio que
            permitan a docentes, investigadores y alumnos acceder a programas
            internacionales, becas, proyectos de investigación e intercambios
            académicos.
          </li>
          <li>
            Desarrollar actividades y encuentros con universidades extranjeras,
            que enriquezcan la formación profesional de toda su comunidad
            académica.
          </li>
        </ul>
      </div>
    </div>
    <img src="../../../../assets/images/content/boy-internacional.png" />
  </div>

  <div id="programa-movilidad-internacional" class="container">
    <div class="title secondary">Programa Movilidad Internacional 2022</div>
    <div class="subtitle blue-light">Alumnos ADENU</div>

    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title> ¿En qué consiste este programa? </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Realizar una Movilidad Internacional te brinda la posibilidad de
          cursar un cuatrimestre de tu carrera, con reconocimiento académico, en
          una universidad extranjera que posea convenio con ADENU.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            ¿Qué cubre este programa de movilidad?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Al tener un convenio bilateral con la universidad extranjera, estarás
          exento del pago de aranceles y matrícula de esa universidad. Solo
          deberás continuar con tu pago regular en ADENU para ser alumno
          regular. <br /><br />
          <b
            >El costo de pasajes, seguro médico, alojamiento y manutención están
            a cargo del alumno.</b
          >
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            ¿Qué requisitos debes cumplir para postularte a este programa?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>Ser alumno regular de una carrera de grado en ADENU.</li>
          <li>Contar con un promedio académico de 85% o más.</li>
          <li>
            Contar con hasta el sexto cuatrimestre cursado y aprobado o su
            equivalente, es decir tener el primero y segundo año completo
            (cursado y aprobado).
          </li>
          <li>
            Pasaporte vigente (con un mínimo 6 meses después de concluir la
            movilidad en el exterior)
          </li>
          <li>Certificado de Idioma*. Nivel B1-intermedio (mínimo).</li>
          <p>
            *La universidad de destino es la que determina el tipo de
            certificado que debes presentar para acreditar tu nivel de idioma
            extranjero. Este puede ser internacional o de ADENU.
          </p>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title> Conoce el Reglamento de Movilidad </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="button-container">
          <a
            mat-flat-button
            color="accent"
            target="_blank"
            rel="noopener noreferrer"
            href="../../../../assets/documents/Reglamento ADENU-Salientes.pdf"
          >
            Descargar reglamento <span class="material-icons"> download </span>
          </a>
        </div>
        <div class="pdf-container">
          <iframe
            src="https://drive.google.com/file/d/1HevRCosGD0EYyXmKSwoQtGYPc9a1QBc2/preview"
            frameborder="0"
            width="640"
            height="480"
          ></iframe>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<app-convenios id="convenios"></app-convenios>

<div class="cards">
  <div class="container">
    <div class="heading">
      <div class="title primary">Paso a paso</div>
    </div>
    <div class="requisitos-grid">
      <ng-container *ngFor="let paso of this.pasos">
        <app-card-paso-internacional
          [requisito]="paso"
        ></app-card-paso-internacional>
      </ng-container>
    </div>
  </div>

  <div class="container">
    <div class="heading">
      <div class="title primary">Requisitos para participar</div>
    </div>
    <div class="criterios-grid">
      <ng-container *ngFor="let requisito of this.requisitos">
        <app-card-requisito-internacional
          [requisito]="requisito"
        ></app-card-requisito-internacional>
      </ng-container>
    </div>
  </div>
</div>
