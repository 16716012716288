import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input() name: string;
  @Input() description: string;
  @Input() size: string;
  @Input() icon: string;
  @Input() iconImage: boolean;
  @Input() iconImageUrl: string;
  @Input() link: string;
  @Input() toggleMenu: boolean = false;
  @Input() showLabel: boolean = false;
  @Input() isActive: boolean = false;

  constructor(private router: Router) {}

  goTo() {
    if (this.link.includes('http') || this.link.includes('assets')) {
      window.open(this.link, '_blank');
    } else {
      this.router.navigate([this.link]);
    }
  }
}
