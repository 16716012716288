<div class="container center-flex">
  <h1 class="welcome-text">
    ¡Qué bueno verte de nuevo,
    <strong class="name-format">{{ (user$ | async)?.nombre }}</strong
    >!
  </h1>
  <!-- <div class="flex-grid-container">
  </div> -->

  <div class="flex-grid-container">
    <div id="main-body">
      <!-- <div class="even-grid">
        <app-eventos-banner
          class="banner evento"
          [chipTitle]="'¡Evalúa tu desempeño!'"
          [title]="'Autoevaluación Docente'"
          [description]="
            'Trabajemos juntos en la mejora continua de los programas de la Universidad'
          "
          [icon]="'how_to_reg'"
          [link]="
            'https://docs.google.com/forms/d/e/1FAIpQLSdy2dsg-B7j8Ii4EKwkGdwaKNpsoNBZ2oS5pQB09fvQ4bBDBA/viewform'
          "
        ></app-eventos-banner>
        <app-eventos-banner
          *ngIf="(user$ | async)?.link_encuestas"
          class="banner evento"
          [chipTitle]="'¡Observa los resultados!'"
          [title]="'Resultados Encuesta Docente'"
          [description]="'Chequea lo que opinan tus alumnos'"
          [icon]="'insights'"
          [link]="(user$ | async)?.link_encuestas"
        ></app-eventos-banner>
      </div> -->

      <div class="flex-grid">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://aden.instructure.com/login/openid_connect"
        >
          <app-cardcanvas></app-cardcanvas>
        </a>
        <ng-container *ngFor="let item of cardData">
          <app-card
            [badge]="item.badge"
            [icon]="item.icon"
            [title]="item.title"
            [description]="item.description"
            [link]="item.link"
          ></app-card>
        </ng-container>
      </div>
    </div>

    <div id="sidebar">
      <!-- <app-eventos-banner
        class="banner evento"
        [chipTitle]="'¡Reserva la fecha!'"
        [title]="'Eventos ADENU'"
        [description]="'Chequea la nueva agenda de eventos'"
        [icon]="'event'"
        [link]="'/eventos'"
      ></app-eventos-banner> -->
      <!-- <form
        ngNoForm
        id="job-form"
        action="https://aden.hiringroomcampus.com/check-access"
        target="_blank"
        method="POST"
      >
        <input hidden name="token" value="E9E69T2vyRPNZuTVHaZjK67Y" />
        <app-eventos-banner
          class="banner evento"
          [title]="'Bolsa de empleo'"
          [description]="'Ofertas de empleo exclusivas para tí'"
          [icon]="'work'"
          (click)="goToEmpleo()"
        ></app-eventos-banner>
      </form> -->

      <div class="image-banner">
        <a [routerLink]="['/adenu-internacional']">
          <img
            src="../../../assets/images/slider/promo-internacional.png"
            alt="Promo internacional"
          />
        </a>
      </div>
    </div>
  </div>
</div>
