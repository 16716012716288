import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
@Component({
  selector: 'app-course-viewer',
  templateUrl: './course-viewer.component.html',
  styleUrls: ['./course-viewer.component.scss']
})
export class CourseViewerComponent implements OnInit {

  iframeUrl: string;
  
  constructor(private route: ActivatedRoute, private dataService: DataService) { }

  ngOnInit(): void {
    const id = this.route.snapshot.params['id'];
    const encrypt = btoa(id);
    const token = this.dataService.leerToken();
    const repo_id = localStorage.getItem('repo_id');
    this.iframeUrl = `https://main-4437.acropolis-38h.pages.dev/cursado/adenupa/?origin=ADENUPA&repo_id=${repo_id}&id_oa=${encrypt}&token=${token}`;
  }

}
