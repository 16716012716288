
import { Component, Input, OnInit } from '@angular/core';
import { IResource } from 'src/app/shared/interfaces/interfaces';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-resource-card',
  templateUrl: './resource-card.component.html',
  styleUrls: ['./resource-card.component.scss']
})
export class ResourceCardComponent implements OnInit {

  @Input() resourceData: IResource;
  @Input() goToVideo;
  isChecked = false;
  @Input() onRegisterCourse;
  progress = 0;
  isRegister = false;

  public searchValue = '';
  

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.isRegister = this.resourceData.state ? true : false;
    this.progress = this.resourceData.detail?.progress ? this.resourceData.detail.progress : 0; 
  }

  clickVideo(){
    this.goToVideo(this.resourceData.detail.id)
  }

  search(evt) {
    evt.preventDefault();
  }

  courseRegister(){
    this.onRegisterCourse(this.resourceData.id);
  }

  cleanSearch(): void {
    this.searchValue = '';
  }

}
