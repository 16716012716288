import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'src/app/shared/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  isLinear = false;
  isLoading = false;
  docente;
  selectedCountry;
  selectedNationality;
  selectedSector;
  nacionalidadId;
  @Input() countries;
  laboralSector;
  idLaboralSector;
  userSisId = localStorage.getItem('sis_id');
  sending = false;

  profileForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dataService: DataService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.profileForm = this.formBuilder.group({
      id: [''],
      nombre: [{ value: '', disabled: true }, Validators.required],
      nro_documento: ['', Validators.required],
      tipo_documento: ['', Validators.required],
      fecha_nacimiento: ['', [Validators.required]],
      direccion: [''],
      correo_personal: ['', [Validators.required, Validators.email]],
      correo_academico: [''],
      genero: ['', Validators.required],
      grupo_sanguineo: ['', Validators.required],
      linkedin: ['', Validators.required],
      nacionalidad: ['', Validators.required],
      movil: new UntypedFormControl(
        {
          value: '',
          disabled: false,
        },
        [Validators.required]
      ),
      contacto_emergencia: this.formBuilder.group({
        nombre: ['', [Validators.required, Validators.email]],
        movil: new UntypedFormControl({ value: '', disabled: false }, [
          Validators.required,
        ]),
        parentesco: [''],
      }),
    });
    this.getUser();
  }

  createForm(): void {
    this.profileForm = this.formBuilder.group({
      id: [this.docente.sis_id],
      nombre: [
        { value: this.docente.nombre, disabled: true },
        Validators.required,
      ],
      nro_documento: [this.docente.nro_documento, Validators.required],
      tipo_documento: [this.docente.tipo_documento, Validators.required],
      fecha_nacimiento: [this.docente.fecha_nacimiento, [Validators.required]],
      direccion: [this.docente.direccion],
      correo_personal: [
        this.docente.correo_personal,
        [Validators.required, Validators.email],
      ],
      correo_academico: [this.docente.correo_academico],
      genero: [this.docente.genero, Validators.required],
      grupo_sanguineo: [this.docente.grupo_sanguineo, Validators.required],
      linkedin: [this.docente.linkedin, Validators.required],
      nacionalidad: [this.docente.nacionalidad, Validators.required],
      movil: new UntypedFormControl(
        {
          value: this.docente.movil,
          disabled: false,
        },
        [Validators.required]
      ),
      contacto_emergencia: this.formBuilder.group({
        nombre: [
          this.docente.contacto_emergencia.nombre,
          [Validators.required],
        ],
        movil: new UntypedFormControl(
          { value: this.docente.contacto_emergencia.movil, disabled: false },
          [Validators.required]
        ),
        parentesco: [this.docente.contacto_emergencia.parentesco],
      }),
    });
  }

  getUser(): void {
    this.isLoading = true;
    this.dataService.getDocente().subscribe(
      (data) => {
        this.docente = data;
        this.transformValueCountry(this.countries.paises, data.nacionalidad);
        this.createForm();
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  onSubmit(): void {
    const saveSubmit = document.getElementById('save-button');
    if (this.profileForm.status === 'VALID') {
      this.sending = true;
      this.transformValueCountry(
        this.countries.paises,
        this.profileForm.value.nacionalidad
      );
      saveSubmit.innerText = 'Guardando...';
      this.dataService
        .updateProfesor({
          ...this.profileForm.value,
          nacionalidad: this.selectedNationality,
        })
        .subscribe(
          (data) => {
            saveSubmit.innerText = 'Guardar cambios';
            this.sending = false;
            this.dataService.openAlert(
              '¡Enhorabuena!',
              'Los cambios se realizaron con éxito',
              'Cerrar'
            );
          },
          (err) => {
            saveSubmit.innerText = 'Guardar cambios';
            this.sending = false;
            this._snackBar.open(
              'Ha ocurrido un error al actualizar el perfil',
              'Cerrar',
              {
                duration: 7000,
              }
            );
          }
        );
    } else {
      console.log(this.profileForm);
      saveSubmit.innerText = 'Guardar cambios';
      this._snackBar.open(
        'No se han completado todos los campos obligatorios',
        'Cerrar',
        {
          duration: 7000,
        }
      );
    }
  }

  getLaboralSector(sector): void {
    this.dataService.getLaboralSector().subscribe(
      (res) => {
        this.laboralSector = res.sectores;
        this.transformValueLaboralSector(this.laboralSector, sector);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  transformValueCountry(array, nationality): void {
    // Pais alumno
    for (const country of array) {
      if (nationality.toLowerCase() === country.name.toLowerCase()) {
        this.selectedNationality = country.id;
        break;
      }
    }
  }

  transformValueLaboralSector(array, sector): void {
    for (const arraySector of array) {
      if (sector === arraySector.id) {
        this.selectedSector = arraySector.id;
        break;
      } else {
        this.idLaboralSector = 1;
      }
    }
  }

  compareCountry(o1: any, o2: any): any {
    return o1 && o2 && o1 === o2;
  }

  compareSector(o1: any, o2: any): any {
    return o1 && o2 && o1 === o2;
  }

  /* printPhone() {
    console.log(this.firstFormGroup.get('phoneNumber').value);
  } */
}
