<div id="buscador" class="buscador mb-4" *ngIf="!isLoading && !isError">
    <div class="form-field">
      <mat-icon class="material-icons-round">search</mat-icon>
      <input
        matInput
        type="search"
        placeholder="Buscar"
        (keyup)="search($event)"
        #input
        [(ngModel)]="searchValue"
      />
  
      <div class="suffix">
        <button
          *ngIf="searchValue"
          mat-icon-button
          color="primary"
          aria-label="Clear"
          (click)="cleanSearch()"
        >
          <mat-icon class="material-icons-round">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div
    class="table-container main-radius shadow-200 main-border"
    *ngIf="!isLoading && !isError"
  >
    <!-- NEW TABLE -->
  
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1">
      <ng-container
        [matColumnDef]="column.columnDef"
        [ngSwitch]="true"
        *ngFor="let column of tableColumns"
      >
        <ng-container *ngSwitchCase="column.columnDef === 'line_status'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="chip green-bg" *ngIf="column.cell(row) === 'Aprobado'">
              {{ column.cell(row) }}
            </div>
            <div class="chip red-bg" *ngIf="column.cell(row) === 'Reprobado'">
              {{ column.cell(row) }}
            </div>
            <div class="no-data" *ngIf="column.cell(row) === 'Sin datos'">
              {{ column.cell(row) }}
            </div>
          </td>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="column.cell(row) !== 'Sin datos'; else noData">
              {{ column.cell(row) }}
            </div>
            <ng-template #noData>
              <div class="no-data">
                {{ column.cell(row) }}
              </div>
            </ng-template>
          </td>
        </ng-container>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        class="example-element-row"
      ></tr>
  
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="7">
          <div class="no-result" *ngIf="value.value !== ''">
            <img
              src="../../../../assets/images/content/sin-resultados.svg"
              alt=""
            />
            <div class="text">No encontramos resultados para</div>
            <div class="searched-value">"{{ value.value }}"</div>
          </div>
          <div class="no-result" *ngIf="notes === []">
            <img
              src="../../../../assets/images/content/sin-resultados.svg"
              alt=""
            />
            <div class="text">Aún no tienes notas cargadas en el sistema</div>
          </div>
        </td>
      </tr>
    </table>
  </div>
  
  <div class="paginator-container" id="paginator">
    <mat-paginator
      [pageSizeOptions]="[10, 15, 20]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
  