<div
  class="nav-container"
  tourAnchor="sidenav"
  [class.is-mobile]="mobileQuery.matches"
  [class.is-open]="!mobileQuery.matches && openSidenav.value"
  [ngStyle]="{
    visibility:
      (mobileQuery.matches && expandedSidenav.value) || !mobileQuery.matches
        ? 'visible'
        : 'hidden',
    opacity:
      (mobileQuery.matches && expandedSidenav.value) || !mobileQuery.matches
        ? '1'
        : '0',
    left:
      (mobileQuery.matches && expandedSidenav.value) || !mobileQuery.matches
        ? '0px'
        : '-100%'
  }"
>
  <div
    class="heading"
    [ngStyle]="{
      paddingLeft: expandedSidenav.value ? '1rem' : 'unset',
      paddingRight: expandedSidenav.value ? '0.5rem' : 'unset'
    }"
  >
    <img
      src="../../../../../assets/images/logos/ADENU-Color.png"
      height="32px"
      *ngIf="openSidenav.value || expandedSidenav.value"
    />
    <app-menu-item
      [icon]="openSidenav.value || expandedSidenav.value ? 'menu_open' : 'menu'"
      (click)="toggleSidenav()"
      tourAnchor="toggleSidenav"
    ></app-menu-item>
  </div>

  <div
    class="menu"
    (mouseenter)="showLabels.setValue(true); hoverControl.setValue(true)"
    (mouseleave)="showLabels.setValue(false); hoverControl.setValue(false)"
    [ngStyle]="{
      width:
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
          ? '265px'
          : 'unset',
      padding:
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
          ? '0 0.5rem'
          : 'unset'
    }"
    tourAnchor="menuSecciones"
  >
    <app-menu-item
      [icon]="'dashboard'"
      [name]="'Dashboard'"
      [link]="'/home'"
      [showLabel]="
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
      "
      (click)="toggleDropdowns(); closeSidenav()"
    ></app-menu-item>
    <app-menu-item
      [tourAnchor]="'canvas'"
      [iconImage]="true"
      [iconImageUrl]="'canvas-icon.svg'"
      [name]="'Ingresar a CANVAS'"
      [link]="'https://aden.instructure.com'"
      [showLabel]="
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
      "
      (click)="toggleDropdowns(); closeSidenav()"
    ></app-menu-item>
    <app-menu-item
      [tourAnchor]="'pagos'"
      [icon]="'payments'"
      [name]="'Gestión de pagos'"
      [link]="'/pagos'"
      [showLabel]="
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
      "
      (click)="toggleDropdowns(); closeSidenav()"
    ></app-menu-item>
    <app-menu-item
      [tourAnchor]="'movilidad'"
      [icon]="'public'"
      [name]="'Movilidad Internacional'"
      [link]="'/adenu-internacional'"
      [showLabel]="
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
      "
      (click)="toggleDropdowns(); closeSidenav()"
    ></app-menu-item>

      <app-menu-item
        [icon]="'import_contacts'"
        [name]="'Mis cursos'"
        [link]="'/courses'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        (click)="toggleDropdowns(); closeSidenav()"
    ></app-menu-item> 
    <app-menu-item
      [tourAnchor]="'notas'"
      [icon]="'summarize'"
      [name]="'Mis notas'"
      [link]="'/notas'"
      [showLabel]="
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
      "
      (click)="toggleDropdowns(); closeSidenav()"
    ></app-menu-item>
    <app-menu-item
      [tourAnchor]="'plan-estudio'"
      [icon]="'history_edu'"
      [name]="'Mi plan de estudio'"
      [link]="'/plan-estudio'"
      [showLabel]="
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
      "
      (click)="toggleDropdowns(); closeSidenav()"
    ></app-menu-item>
    <!-- <app-menu-item
      [icon]="'event'"
      [name]="'Eventos ADENU'"
      [link]="'/eventos'"
      [showLabel]="
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
      "
      (click)="toggleDropdowns(); closeSidenav()"
    ></app-menu-item> -->

    <!-- <app-drop-down-menu
      [hoverControl]="hoverControl"
      [matchMobile]="mobileQuery.matches"
      [menuName]="'Centro de emprendimiento'"
      [menuIcon]="'lightbulb'"
      [showLabel]="
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
      "
      [isOpen]="ceadenToggle.value"
      [openSidenav]="openSidenav.value"
      (click)="ceadenToggle.setValue(!ceadenToggle.value)"
      tourAnchor="dropdownMenu"
    >
      <app-menu-item
        [icon]="'lightbulb'"
        [name]="'Sobre CEADEN'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        [size]="'small'"
        [link]="'/ceaden'"
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item>
      <app-menu-item
        [icon]="'rocket_launch'"
        [name]="'Programa Impulso'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        [size]="'small'"
        [link]="'/ceaden/programa-impulso'"
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item>
      <app-menu-item
        [icon]="'tips_and_updates'"
        [name]="'Reto Aprende y Emprende'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        [link]="'/ceaden/reto-aprende-y-emprende'"
        [size]="'small'"
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item>
    </app-drop-down-menu> -->

    <!-- <form
      ngNoForm
      id="job-form"
      action="https://aden.hiringroomcampus.com/check-access"
      target="_blank"
      method="POST"
    >
      <input hidden name="token" value="E9E69T2vyRPNZuTVHaZjK67Y" />
      <app-menu-item
        [icon]="'work'"
        [name]="'Bolsa de empleo'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        (click)="toggleDropdowns(); closeSidenav(); goToEmpleo()"
      ></app-menu-item>
    </form> -->
  </div>
</div>
